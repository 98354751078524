import produce from 'immer'
import * as actionType from 'redux/actionTypes'
import { activeBooking } from 'redux/defaultState'
import { Time } from 'helpers'

const defaultState = {
    ...activeBooking,
    hoursLeftToTripStart: 0,
    isTripWithin72Hours: false,
}

/**
 * Note we are using Immer.js for immutability
 */

export default function Booking(state = defaultState, action) {
    // eslint-disable-next-line consistent-return
    return produce(state, draft => {
        switch (action.type) {
            case actionType.UPDATE_ACTIVE_BOOKING:
                return {
                    ...draft,
                    ...action.payload,
                    hoursLeftToTripStart: Time.getNoOfHours(action.payload.startDate),
                    isTripWithin72Hours: !action.payload.tripEnded && Time.getNoOfHours(action.payload.startDate) <= 72,
                }
            default:
                break
        }
    })
}
