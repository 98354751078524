import { updateUserPersonalDetails } from 'redux/actions'
import { UserApi } from 'apis'
import { Utils } from 'helpers'

const getUserDetails = () => async dispatch => {
    const {
        App: { brandCode },
        Chatter: { showChat },
    } = Utils.getConfigs()
    let userInfo = {}
    if (showChat) {
        userInfo = await UserApi.getUserDetails({ brandCode })
    }
    dispatch(updateUserPersonalDetails({ userInfo }))
    return userInfo
}

export { getUserDetails }
