import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class Bookings {
    static getBookingComponents(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_BOOKING_COMPONENTS, { params })
            .then(response => Utils.getResponseData(response, true))
            .catch(error => Utils.throwError(error, '*** ERROR IN GET BOOKING COMPONENTS ***'))
    }

    static getBookingBrands(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.BOOKING_BRAND, { params })
            .then(response => Utils.getResponseData(response, true))
            .catch(error => Utils.throwError(error, '*** ERROR IN GET BOOKING BRANDS ***'))
    }

    static getBookingPassengers(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_BOOKING_PASSENGERS, { params })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET BOOKING PASSENGERS ***'))
    }

    static getBookingDetails(params, config) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_BOOKING_DETAILS, { params, ...config })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET BOOKING DETAILS ***'))
    }

    static updateBookingDetails(data, params) {
        return Utils.ajax().post(NodeAPIUrl.UPDATE_BOOKING_DETAILS, { data }, { params })
    }

    static addBooking(data) {
        return Utils.ajax()
            .post(NodeAPIUrl.ADD_BOOKING, { data }, { skipErrorModal: true })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN ADD BOOKING ***'))
    }

    static paymentConfirmation(data, params) {
        return Utils.ajax()
            .post(NodeAPIUrl.PAYMENT_CONFIRMATION, { data }, { params })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN ADD BOOKING ***'))
    }
}
