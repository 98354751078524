import { CREATE_CHAT_CLIENT, UNREAD_COUNT, SET_ACTIVE_BOOKING_TI_LIST, SET_CHANNELS } from 'redux/actionTypes'

export const createChatClient = payload => ({
    type: CREATE_CHAT_CLIENT,
    payload,
})

export const unReadCount = payload => ({
    type: UNREAD_COUNT,
    payload,
})

export const setActiveBookingTIList = payload => ({
    type: SET_ACTIVE_BOOKING_TI_LIST,
    payload,
})

export const setChannelsList = payload => ({
    type: SET_CHANNELS,
    payload,
})
