import React from 'react'
import PropTypes from 'prop-types'
import Callout from 'components/Callout'
import HomeButton from 'components/HomeButton'
import { Utils } from 'helpers'
import { withCard } from './CardContext'

const Header = ({
    componentName,
    className,
    themeName,
    children,
    withBorder,
    withHomeButton,
    alignCenter,
    withError,
    message,
    customButtonLabel,
    customLink,
}) => {
    const classNames = Utils.removeDuplicates(...Utils.stringToArray(className))
    const parentClassName = Utils.argsToString(
        ...Utils.addSuffixToEachString(componentName, '__header'),
        ...(alignCenter || withHomeButton ? Utils.addSuffixToEachString(componentName, '__header--centerAligned') : []),
        ...(withBorder ? Utils.addSuffixToEachString(componentName, '__header--hasBorder') : []),
        themeName,
        ...classNames,
    )
    const homeButtonClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentName, '__home'), themeName)
    const calloutClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentName, '__error'))

    return (
        <div className="cell shrink">
            {withError && <Callout type="error" className={calloutClassName} header={message} />}
            <header className={parentClassName}>
                {withHomeButton && (
                    <div className="hide-for-small-only">
                        <HomeButton className={homeButtonClassName} customButtonLabel={customButtonLabel} customLink={customLink} />
                    </div>
                )}
                {children}
            </header>
        </div>
    )
}

Header.displayName = 'CardHeader'
Header.propTypes = {
    withHomeButton: PropTypes.bool,
    withError: PropTypes.bool,
    alignCenter: PropTypes.bool,
    withBorder: PropTypes.bool,
    componentName: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    themeName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
Header.defaultProps = {
    withHomeButton: false,
    withError: false,
    alignCenter: false,
    withBorder: false,
    className: '',
    children: null,
    message: null,
}

export default withCard(Header)
