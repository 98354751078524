// appData
export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const TOGGLE_ERROR_MODAL = 'TOGGLE_ERROR_MODAL'
export const TOGGLE_WARNING_MODAL = 'TOGGLE_WARNING_MODAL'
export const SHOW_BOOKING_LIST = 'SHOW_BOOKING_LIST'
export const SET_MODAL_MESSAGE = 'SET_MODAL_MESSAGE'

// userData
export const REFRESH_BOOKINGS_LIST = 'REFRESH_BOOKINGS_LIST'
export const TOGGLE_AMB_ERROR_MODAL = 'TOGGLE_AMB_ERROR_MODAL'
export const UPDATE_SESSION_DATA = 'UPDATE_SESSION_DATA'
export const UPDATE_LOGGEDIN_USER_DETAILS = 'UPDATE_LOGGEDIN_USER_DETAILS'
export const UPDATE_USER_PERSONAL_DATA = 'UPDATE_USER_PERSONAL_DATA'

// bookings
export const UPDATE_BOOKINGS_DATA = 'UPDATE_BOOKINGS_DATA'
export const SET_BOOKING_CLOSE = 'SET_BOOKING_CLOSE'
export const SET_FLIGHT_AMOUNT = 'SET_FLIGHT_AMOUNT'
export const SHOW_INSURANCE_DIFFERENCE = 'SHOW_INSURANCE_DIFFERENCE'
export const SHOW_INSURANCE_MODAL = 'SHOW_INSURANCE_MODAL'
export const SET_INSURANCE_DATA = 'SET_INSURANCE_DATA'
export const SET_TRANSFER_AIR = 'SET_TRANSFER_AIR'

// Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'

export const SET_HOTELS = 'SET_HOTELS'

// activeBooking
export const UPDATE_ACTIVE_BOOKING = 'UPDATE_ACTIVE_BOOKING'

// activeBookingDetails
export const UPDATE_ACTIVE_BOOKING_DETAILS = 'UPDATE_ACTIVE_BOOKING_DETAILS'
export const UPDATE_BOOKING_VERSION = 'UPDATE_BOOKING_VERSION'
export const UPDATE_INSTALMENT_PLAN = 'UPDATE_INSTALMENT_PLAN'

// bookingdetailforoverview
export const SET_BOOKING_DETAILS_FOR_OVERVIEW = 'SET_BOOKING_DETAILS_FOR_OVERVIEW'
export const SET_PAYMENT_FORM_RESPONSE = 'SET_PAYMENT_FORM_RESPONSE'
export const SET_PAYMENT_FAILED_ERROR = 'SET_PAYMENT_FAILED_ERROR'
export const SET_GET_BOOKING_DETAILS_FAILED_ERROR = 'SET_GET_BOOKING_DETAILS_FAILED_ERROR'

// activeDocuments
export const UPDATE_ACTIVE_DOCUMENTS = 'UPDATE_ACTIVE_DOCUMENTS'

// Itinerary
export const UPDATE_ACTIVE_ITINERARY = 'UPDATE_ACTIVE_ITINERARY'
export const UPDATE_ACTIVE_ITINERARY_DETAILS = 'UPDATE_ACTIVE_ITINERARY_DETAILS'

// Trip Personalization
export const SET_TRIP_PERSONALISATION_PASSENGER = 'SET_TRIP_PERSONALISATION_PASSENGER'
export const UPDATE_SELECTED_PASSANGER_DATA = 'UPDATE_SELECTED_PASSANGER_DATA'
export const SET_BOOKING_VERSION = 'SET_BOOKING_VERSION'
export const VALIDATE_PERSONALISATION_INPUT_FORM = 'VALIDATE_PERSONALISATION_INPUT_FORM'
export const SET_NEW_TRIP_PERSONALISATION_PASSENGER_DATA = 'SET_NEW_TRIP_PERSONALISATION_PASSENGER_DATA'
export const SET_TRIP_PERSONALISATION_VERSION_MODAL = 'SET_TRIP_PERSONALISATION_VERSION_MODAL'
export const RESET_TRIP_PERSONALISATION_DATA = 'RESET_TRIP_PERSONALISATION_DATA'

// Trip Registration
export const SET_TRIP_REGISTRATION_PASSENGER = 'SET_TRIP_REGISTRATION_PASSENGER'
export const SET_INPUT_ERROR_MESSAGE = 'SET_INPUT_ERROR_MESSAGE'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const RESET_STATE = 'RESET_STATE'
export const UPDATE_TIMELINE = 'UPDATE_TIMELINE'
export const NEW_PASSENGER_DATA = 'NEW_PASSENGER_DATA'
export const UPDATE_VERSION_MODAL = 'UPDATE_VERSION_MODAL'
export const UPDATE_PASSENGER_MODAL = 'UPDATE_PASSENGER_MODAL'

// Chatter
export const CREATE_CHAT_CLIENT = 'CREATE_CHAT_CLIENT'
export const UNREAD_COUNT = 'UNREAD_COUNT'
export const SET_ACTIVE_BOOKING_TI_LIST = 'SET_ACTIVE_BOOKING_TI_LIST'
export const SET_CHANNELS = 'SET_CHANNELS'

// Edocs
export const UPDATE_DOCUMENT_TYPE = 'UPDATE_DOCUMENT_TYPE'
export const UPDATE_AVAILABLE_DOCUMENT_TYPES = 'UPDATE_AVAILABLE_DOCUMENT_TYPES'
