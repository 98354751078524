import * as actionType from 'redux/actionTypes'

export const setTripRegistrationActivePassenger = passenger => ({
    type: actionType.SET_TRIP_REGISTRATION_PASSENGER,
    payload: passenger,
})

export const setInputErrorMessage = (fieldName, errorMessage, isValidationError) => ({
    type: actionType.SET_INPUT_ERROR_MESSAGE,
    payload: {
        fieldName,
        errorMessage,
        isValidationError,
    },
})

export const setErrorMessage = error => ({
    type: actionType.SET_ERROR_MESSAGE,
    payload: {
        error,
    },
})

export const resetErrorMessage = () => ({
    type: actionType.RESET_STATE,
    payload: {},
})

export const updateTimeLine = payload => ({
    type: actionType.UPDATE_TIMELINE,
    payload,
})

export const setNewDataForSelectedPassenger = payload => ({
    type: actionType.NEW_PASSENGER_DATA,
    payload,
})

export const updateModalFlag = (stepIndex, isDirty, keys) => ({
    type: actionType.UPDATE_VERSION_MODAL,
    payload: {
        stepIndex,
        isDirty,
        keys,
    },
})

export const updatePassengerModalFlag = stepIndex => ({
    type: actionType.UPDATE_PASSENGER_MODAL,
    payload: {
        stepIndex,
    },
})
