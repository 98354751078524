import produce from 'immer'
import { UPDATE_DOCUMENT_TYPE, UPDATE_AVAILABLE_DOCUMENT_TYPES } from 'redux/actionTypes'

const defaultState = {
    availableDocumentTypes: [],
    selectedDocumentType: 0,
}

/**
 * Note we are using Immer.js for immutability
 */

export default function Edocs(state = defaultState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case UPDATE_AVAILABLE_DOCUMENT_TYPES:
                draft.availableDocumentTypes = action.payload
                break
            case UPDATE_DOCUMENT_TYPE:
                draft.selectedDocumentType = action.payload
                break
            default:
                break
        }
    })
}
