import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toggleWarningModal } from 'redux/actions'
import Modal from 'components/Modal'
import { Utils } from 'helpers'
import parse from 'html-react-parser'
import './styles.scss'

const {
    WarningModal: {
        message: defaultMessage,
        buttons: { continue: btnContinue, back: btnBack },
    },
} = Utils.getTranslations()

const WarningModal = ({ visible, message, callback, dispatchToggleWarningModal, noAdditionalButtonText }) => (
    <Modal
        componentName="WarningModal"
        open={visible}
        closeButtonText={btnBack}
        additionalButtonText={noAdditionalButtonText ? '' : btnContinue}
        onClose={escaped => {
            dispatchToggleWarningModal({ visible: false })
            if (typeof callback === 'function') {
                callback(escaped)
            }
        }}
    >
        <div className="WarningModal__wrapper">
            <h4 className="WarningModal__message">{parse(message || defaultMessage)}</h4>
        </div>
    </Modal>
)

WarningModal.displayName = 'WarningModal'
WarningModal.propTypes = {
    visible: PropTypes.bool,
    message: PropTypes.string,
    callback: PropTypes.func,
    noAdditionalButtonText: PropTypes.bool,
    dispatchToggleWarningModal: PropTypes.func.isRequired,
}
WarningModal.defaultProps = {
    visible: false,
    message: '',
    callback: () => true,
    noAdditionalButtonText: false,
}

export default connect(
    ({ appData: { warningModal } }) => ({
        visible: warningModal.visible,
        message: warningModal.message,
        callback: warningModal.callback,
    }),

    dispatch => ({
        dispatchToggleWarningModal: payload => dispatch(toggleWarningModal(payload)),
    }),
)(WarningModal)
