import moment from 'moment'
import { AppConstant } from 'constants'

export default class Time {
    static getTodayDate() {
        return moment().format(AppConstant.DATE_FORMAT_YYYY_MM_DD)
    }

    static toUTC(param) {
        if (moment(param, AppConstant.DATE_FORMAT_YYYY_MM_DD, true).isValid()) {
            return moment(param, AppConstant.DATE_FORMAT_YYYY_MM_DD, true)
        }
        return null
    }

    static isValidDate(param) {
        return moment(param, AppConstant.DATE_FORMAT_YYYY_MM_DD, true).isValid()
    }

    static now() {
        return moment()
    }

    static isSameOrAfter(date1, date2) {
        return this.toUTC(date1).isSameOrAfter(this.toUTC(date2))
    }

    static add(date, ...args) {
        return this.toUTC(date || this.now()).add(...args)
    }

    static diff(date1, date2, ...args) {
        return this.toUTC(date1).diff(moment(this.toUTC(date2)), ...args)
    }

    static format(date, ...args) {
        return this.toUTC(this.isValidDate(date) ? date : this.now().format(AppConstant.DATE_FORMAT_YYYY_MM_DD)).format(...args)
    }

    static daysBetween(earlier, later) {
        return moment.duration(moment(later).diff(earlier)).asDays()
    }

    static getNoOfDays(earlier, later) {
        return moment.duration(moment(later).diff(earlier)).days()
    }

    static getNoOfHours(date) {
        return moment.duration(moment(date).diff()).asHours()
    }

    static dateDiffText = (arriveDate, departDate) => {
        let text = ''
        const arrive = moment.parseZone(arriveDate)
        const depart = moment.parseZone(departDate)
        const arriveZoneless = moment({ year: arrive.year(), month: arrive.month(), day: arrive.date() })
        const departZoneless = moment({ year: depart.year(), month: depart.month(), day: depart.date() })
        const days = arriveZoneless.diff(departZoneless, 'days')
        if (days > 0) {
            text = `(+${days}d)`
        } else if (days < 0) {
            text = `(-${days}d)`
        }
        return text
    }

    static hoursBetween(earlier, later) {
        const arrive = moment(later)
        const depart = moment(earlier)
        const difference = arrive.diff(depart)
        const duration = moment.duration(difference)
        const hours = parseInt(duration.asHours(), 10)
        const minutes = parseInt(duration.asMinutes(), 10) % 60
        return `${hours}h ${minutes}m`
    }

    static minutesBetween(earlier, later) {
        const arrive = moment(later)
        const depart = moment(earlier)
        const difference = arrive.diff(depart)
        const duration = moment.duration(difference)
        return duration.asMinutes()
    }

    static daysUntil(date) {
        return Math.ceil(this.daysBetween(this.now(), this.toUTC(date)))
    }

    static daysSince(date) {
        return Math.abs(Math.floor(this.daysBetween(this.now(), this.toUTC(date))))
    }

    static todayOrFutue(date) {
        return moment(date).isSameOrAfter(this.getTodayDate())
    }

    static willOccur(date) {
        return moment(date).isAfter(this.getTodayDate())
    }

    static todayOrEarlier(date) {
        return moment(date).isSameOrBefore(this.getTodayDate())
    }

    static hasOccurred(date) {
        return moment(date).isBefore(this.getTodayDate())
    }

    static getLocaleDate(date) {
        const localDate = new Date(date)
        const localDay = `${localDate.getDate()}`.padStart(2, '0')
        const localMonth = `${localDate.getMonth() + 1}`.padStart(2, 0)
        const localYear = `${localDate.getFullYear()}`
        return `${localYear}-${localMonth}-${localDay}`
    }

    static enumerateDaysBetweenDates(startDate, endDate) {
        const now = moment(startDate).clone()
        const dates = []
        while (now.isSameOrBefore(moment(endDate))) {
            dates.push(now.format(AppConstant.DATE_FORMAT_YYYY_MM_DD))
            now.add(1, AppConstant.DAYS)
        }
        return dates
    }
}
