import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import parse from 'html-react-parser'
import { toggleErrorModal } from 'redux/actions'
import { withRouter } from 'react-router-dom'
import Modal from 'components/Modal'
import { Utils } from 'helpers'
import './styles.scss'

const {
    ErrorModal: { message, bookingLockedMessage, buttonText, apiErrorMessage, viewBooking },
    MyBrand: { mtpName },
} = Utils.getTranslations()

const ErrorModal = ({
    errorModalVisible,
    showViewBooking,
    dispatchToggleErrorModal,
    isLockedError,
    dontShowCloseButton,
    isAPIError,
    noOpenWithPropsFromState,
    customMessage,
    ...props
}) => {
    const { isErrorModalVisible, modalHeader, backButton, unableToFindBooking, ensureDetailsMatch } = errorModalVisible
    const displayMessage = isLockedError ? bookingLockedMessage : customMessage || message.replace('$brand$', mtpName)
    const { history } = props
    return (
        (typeof errorModalVisible !== 'boolean' && (
            <Modal
                componentName="ErrorModal"
                title={modalHeader}
                open={isErrorModalVisible}
                openWithPropsFromState={!noOpenWithPropsFromState}
                closeButtonText={backButton}
                onClose={() => dispatchToggleErrorModal({ errorModalVisible: false, isLockedError: false, isAPIError: false })}
            >
                <div className="ErrorModal__wrapper">
                    <div className="ErrorModal__message ErrorModal__message-noicon">
                        <p>
                            <strong> {unableToFindBooking} </strong>
                        </p>
                        <p>{parse(ensureDetailsMatch)}</p>
                    </div>
                </div>
            </Modal>
        )) || (
            <Modal
                componentName="ErrorModal"
                open={errorModalVisible}
                closeButtonText={buttonText}
                additionalButtonText={showViewBooking ? viewBooking : ''}
                openWithPropsFromState={!noOpenWithPropsFromState}
                dontShowCloseButton={dontShowCloseButton}
                additionalButtonClick={() => {
                    dispatchToggleErrorModal({ errorModalVisible: false })
                    history.push('/')
                }}
                onClose={() => {
                    dispatchToggleErrorModal({ errorModalVisible: false })
                }}
            >
                <div className="ErrorModal__wrapper">
                    <h4 className="ErrorModal__message">{isAPIError ? apiErrorMessage : displayMessage}</h4>
                </div>
            </Modal>
        )
    )
}

ErrorModal.propTypes = {
    errorModalVisible: PropTypes.oneOfType([
        PropTypes.bool.isRequired,
        PropTypes.shape({
            isErrorModalVisible: PropTypes.bool,
            backButton: PropTypes.string,
            modalHeader: PropTypes.string,
            showViewBooking: PropTypes.bool,
            unableToFindBooking: PropTypes.string,
            ensureDetailsMatch: PropTypes.string,
        }),
    ]),
    dispatchToggleErrorModal: PropTypes.func.isRequired,
    noOpenWithPropsFromState: PropTypes.bool,
}

ErrorModal.defaultProps = {
    errorModalVisible: {
        isErrorModalVisible: false,
        backButton: '',
        modalHeader: '',
        unableToFindBooking: '',
        showViewBooking: false,
        ensureDetailsMatch: '',
    },
    noOpenWithPropsFromState: false,
}

export default connect(
    ({ appData: { errorModalVisible, isLockedError, dontShowCloseButton, isAPIError, showViewBooking, customMessage } }) => ({
        errorModalVisible,
        showViewBooking,
        isLockedError,
        isAPIError,
        customMessage,
        dontShowCloseButton,
    }),

    dispatch => ({
        dispatchToggleErrorModal: payload => dispatch(toggleErrorModal(payload)),
    }),
)(withRouter(ErrorModal))
