import React from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import { withCard } from './CardContext'

const Body = ({ componentName, className, themeName, children }) => {
    const classNames = Utils.removeDuplicates(...Utils.stringToArray(className))
    const parentClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentName, '__body'), themeName, ...classNames)

    return (
        <div className="cell shrink">
            <div className={parentClassName}>{children}</div>
        </div>
    )
}

Body.displayName = 'CardBody'
Body.propTypes = {
    componentName: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    themeName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
Body.defaultProps = {
    className: '',
    children: null,
}

export default withCard(Body)
