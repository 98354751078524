import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Utils, Analytics } from 'helpers'
import './styles.scss'

const {
    CallUsButton: { defaultPhoneNumber, sellingCompanyToPhoneNumberMapping },
} = Utils.getTranslations()

const phoneNumberClick = () => {
    const params = {
        action: 'call_your_agent',
        category: 'header_phone_number',
        label: 'Call your travel agent',
    }
    Analytics.click(params)
}

const CallUsButton = ({ className, prefix, suffix, sellingCompanyShortName }) => {
    let phoneNumber = defaultPhoneNumber
    if (sellingCompanyToPhoneNumberMapping && sellingCompanyToPhoneNumberMapping[sellingCompanyShortName]) {
        phoneNumber = sellingCompanyToPhoneNumberMapping[sellingCompanyShortName]
    }
    const clickPhoneNumber = phoneNumber.replace(/ /g, '')
    let parentClassName = `CallUsButton`
    parentClassName += prefix ? ` CallUsButton--prefix ` : ''
    parentClassName += suffix ? ` CallUsButton--suffix ` : ''

    let textClassName = `CallUsButton__text`
    textClassName += prefix ? ` CallUsButton__text--prefix ` : ''
    textClassName += suffix ? ` CallUsButton__text--suffix ` : ''

    let phoneNumberClassName = `CallUsButton__phoneNumber`
    phoneNumberClassName += prefix ? ` CallUsButton__phoneNumber--prefix ` : ''
    phoneNumberClassName += suffix ? ` CallUsButton__phoneNumber--suffix ` : ''

    return (
        <>
            {sellingCompanyShortName && sellingCompanyToPhoneNumberMapping && sellingCompanyToPhoneNumberMapping[sellingCompanyShortName] && (
                <a href={`tel:${clickPhoneNumber}`} className={`${parentClassName} ${className}`} onClick={phoneNumberClick}>
                    <span className="CallUsButton__wrapper grid-y">
                        {prefix && <span className={`cell ${textClassName}`}>{prefix}</span>}
                        <span className={`cell ${phoneNumberClassName}`}>{phoneNumber}</span>
                        {suffix && <span className={`cell ${textClassName}`}>{suffix}</span>}
                    </span>
                </a>
            )}
        </>
    )
}

CallUsButton.displayName = 'CallUsButton'
CallUsButton.propTypes = {
    className: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    sellingCompanyShortName: PropTypes.string,
}
CallUsButton.defaultProps = {
    className: '',
    prefix: '',
    suffix: '',
    sellingCompanyShortName: '',
}

export default connect(({ activeBooking: { sellingCompany: { sellingCompanyShortName } = {} } }) => ({ sellingCompanyShortName }))(CallUsButton)
