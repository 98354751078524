/* eslint-disable */
export const generateUniqueId = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 || 0;
    return (c === 'x' ? r : (r && 0x3) || 0x8).toString(16);
  });

export const getDatesBetweenDates = (startDate, endDate) => {
  let dates = [];
  const theDate = new Date(startDate);
  while (theDate <= endDate) {
    dates = [...dates, new Date(theDate)];
    theDate.setUTCDate(theDate.getUTCDate() + 1);
  }
  return dates;
};

const findDay = (hotel, day) =>
  hotel.days.find(d => new Date(d.date).toDateString() === day);

const flattenArray = (array, shallow = false) => {
  const result = [];

  const flatten = (array, level = 0) => {
    for (let n = 0; n < array.length; n++) {
      const item = array[n];

      if (!(level >= 1 && shallow) && item instanceof Array) {
        flatten(item, level + 1);
      } else {
        result.push(item);
      }
    }
  };

  flatten(array, 0);
  return result;
};

const sum = (array = []) =>
  array.reduce((a, b) => {
    return a + b;
  }, 0);

const groupBy = (list, iteratee) => {
  const groups = {};

  for (let n = 0; n < list.length; n++) {
    const item = list[n];
    const key = iteratee ? iteratee(item) : item[iteratee];
    const group = groups[key];

    if (group) group.push(item);
    else groups[key] = [item];
  }

  return groups;
};

const times = (n, fn) => {
  const array = [];
  for (let i = 0; i < n; i++) {
    array.push(fn(i));
  }
  return array;
};

const multiply = (n, type) => times(n, () => ({ type }));

const expandPassengers = (adults, children) =>
  multiply(adults, 'adult').concat(multiply(children, 'child'));

const compareByPriceAndCountAscending = (a, b) => {
  if (a.price < b.price) {
    return -1;
  }
  if (a.price > b.price) {
    return 1;
  }

  const aCount = countRooms(a);
  const bCount = countRooms(b);
  if (aCount < bCount) {
    return -1;
  }
  if (aCount > bCount) {
    return 1;
  }
  return 0;
};

const getAllRoomAllocations = (adults, children) => {
  const allocations = [];
  const getUniqueAllocations = (
    adults,
    maxAdults,
    children,
    maxChildren,
    fn,
  ) => {
    if (adults < 0 || children < 0) {
      return;
    }

    for (let a = 0; a <= adults; a++) {
      for (let c = 0; c <= children; c++) {
        if (a + c >= 1) {
          const duplicate =
            a > maxAdults || (a === maxAdults && c > maxChildren) ? '*' : '';
          if (!duplicate) {
            if (a === adults && c === children) {
              fn([{ adults: a, children: c }]);
            } else {
              getUniqueAllocations(adults - a, a, children - c, c, x => {
                fn([{ adults: a, children: c }].concat(x));
              });
            }
          }
        }
      }
    }
  };

  getUniqueAllocations(adults, adults, children, children, x =>
    allocations.push(x),
  );
  return allocations;
};


const uniqueCartesianProduct = (products, id) => {
  const items = [];
  const alreadyDone = {};

  const isAlreadyDone = results => {
    const ids = id ? results.map(id) : results;

    const key = ids.sort().join(':');
    const done = alreadyDone[key];
    alreadyDone[key] = true;
    return done;
  };

  const calculate = (index, results) => {
    if (index < products.length) {
      const product = products[index];
      const limit = product.length;

      for (let i = 0; i < limit; i++) {
        const orderProperty = product[i];
        calculate(index + 1, results.concat([orderProperty]));
      }
    } else if (!isAlreadyDone(results)) {
      items.push(results);
    }
  };

  calculate(0, []);
  return items;
};

const getAllCombinations = (adults, children, rooms) => {
  const possibleAllocations = [];
  const allocations = getAllRoomAllocations(adults, children);
  allocations.map(allocation => {
    const possibleRoomAllocations = allocation
      .map(allocation => rooms[`${allocation.adults}:${allocation.children}`])
      .filter(a => a);

    if (possibleRoomAllocations.length === allocation.length) {
      const cartesianProduct = uniqueCartesianProduct(
        possibleRoomAllocations,
        room => room.roomType,
      );
      possibleAllocations.push(...cartesianProduct);
    }
    return possibleAllocations;
  });
  return possibleAllocations;
};

const getRoomsByAllocationRules = hotel => {
  const occupancyRules = [];
  !!hotel.accommodation.length &&
    hotel.accommodation?.map(
      rule =>
        !!hotel.days?.length &&
        hotel.days?.every(
          day =>
            !!day?.prices?.length &&
            day?.prices?.some(price => price.roomType === rule.name),
        ) &&
        occupancyRules.push(rule),
    );
  const rooms = flattenArray(
    occupancyRules.map(room => {
      const allocations = [];

      // eslint-disable-next-line no-plusplus
      for (let a = room.minAdults; a <= room.maxAdults; a++) {
        const minChildren = Math.max(room.minPassengers - a, 0);

        for (let c = minChildren; a + c <= room.maxPassengers; c++) {
          const allocation = {
            roomType: room.name,
            adults: a,
            children: c,
            passengers: expandPassengers(a, c),
            maxPassengers: room.maxPassengers,
          };
          if (room.share) allocation.share = true;
          allocations.push(allocation);
        }
      }
      return allocations;
    }),
  );
  return groupBy(rooms, room => `${room.adults}:${room.children}`);
};

const allocateRoomPriceByDay = (hotel, roomAllocation, days) => {
  const pricedRooms = [];
  days.forEach(day => {
    const hotelDay = findDay(hotel, day);
    if (hotelDay) {
      roomAllocation.map((room, index) => {
        pricedRooms[index] = pricedRooms[index] || {
          roomType: room.roomType,
          total: 0,
          adultPrice: 0,
          childPrice: 0,
          adults: 0,
          children: 0
        };

        const priceForRoomType = hotelDay.prices.find(
          r => r.roomType === room.roomType,
        );

        if (priceForRoomType) {
          const roomPrice = priceForRoomType.adultPrice ?? 0;
          const childRoomPrice = priceForRoomType.childPrice ?? 0;

          pricedRooms[index].total +=
            roomPrice * room.adults + childRoomPrice * room.children;
          pricedRooms[index].adultPrice += roomPrice;
          pricedRooms[index].childPrice += childRoomPrice;
          pricedRooms[index].adults = room.adults;
          pricedRooms[index].children = room.children;
        }

        return pricedRooms;
      });
      return pricedRooms;
    }
    return hotelDay;
  });
  return pricedRooms;
};

const isValidCase = (
  old,
  checked,
  roomTypeToCheck,
) => {
  old.map(
    room =>
      room.roomType &&
      room.guests?.length &&
      room.guests.every(pax => checked.includes(pax)) &&
      roomTypeToCheck.push(room),
  );
  return !!roomTypeToCheck.length;
};

const allocateRoomToPassengers = (
  roomAllocation,
  hotel,
  days,
  old,
) => {
  const rooms =  [];
  const roomPriceByDay = allocateRoomPriceByDay(
    hotel,
    roomAllocation,
    days,
  );
  if (roomPriceByDay?.length > 0) {
    old.map(allocation => {
      const room = roomAllocation.find(
        r =>
          r.adults === allocation.adults &&
          r.children === allocation.children &&
          r.roomType === allocation.roomType,
      );

      if (room) {
        const priceChart = roomPriceByDay.find(
          a => a.roomType === room.roomType,
        );

        rooms.push({
          clientIds: allocation.clientIds,
          guests: allocation.guests,
          id: generateUniqueId(),
          roomType: room.roomType,
          price: priceChart?.total ?? 0,
        });
      }
      return allocation;
    });
  }
  return {
    rooms,
    total: sum(rooms.map(r => r.price)),
  } ;
};

const allocationMapper = (
  old,
  passengers,
) => {
  const output = [];
  old.map(room => {
    if (room.roomType && room.guests?.length) {
      let adults = 0;
      let children = 0;
      const clientIds = [];
      passengers.map(pax => {
        if (room.guests?.includes(pax.passengerId)) {
          clientIds.push(pax.id);
          if (pax.type === 'child') children += 1;
          else adults += 1;
        }
        return pax;
      });
      output.push({
        adults,
        children,
        clientIds,
        guests: room.guests,
        roomType: room.roomType,
      });
    }
    return output;
  });
  return output;
};

const groupRooms = rooms => {
  const grouped = groupBy(
    rooms,
    room => `${room.roomType}:${room.adults}:${room.children}`,
  );

  const groupedRooms = Object.keys(grouped).map(key => {
    const rooms = grouped[key];
    const room = rooms[0];

    return {
      id: generateUniqueId(),
      roomType: room.roomType,
      adults: room.adults,
      children: room.children,
      share: room.share,
      count: rooms.length,
      maxPassengers: room.maxPassengers,
    };
  });

  groupedRooms.sort((left, right) => {
    const leftPeople = left.adults + left.children;
    const rightPeople = right.adults + right.children;

    if (leftPeople > rightPeople) {
      return -1;
    }
    if (leftPeople < rightPeople) {
      return 1;
    }
    if (!left.share && right.share) {
      return -1;
    }
    return 0;
  });

  return groupedRooms;
};

const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  a.sort();
  b.sort();

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

const roomsGroupedByType = (roomAllocation, hotel, days) => {
  const roomPriceByDay = allocateRoomPriceByDay(hotel, roomAllocation, days);
  if (roomPriceByDay?.length > 0) {
    const rooms = groupRooms(roomAllocation);

    rooms.forEach(room => {
      const priceChart = roomPriceByDay.find(
        a =>
          a.roomType === room.roomType &&
          a.children === room.children &&
          a.adults === room.adults,
      );
      room.adultPrice = priceChart?.adultPrice;
      room.childPrice = priceChart?.childPrice;
      room.total = priceChart?.total;
    });
    return {
      rooms,
      price: sum(roomPriceByDay.map(d => d.total)),
      id: generateUniqueId(),
    };
  }
  return {};
};

const countRooms = roomAllocation =>
  sum(roomAllocation?.rooms?.map(room => room.count));

  export default class Accomodation {
    static getRoomAllocations(dateRange, hotel, adults, children) {
        let dateList = getDatesBetweenDates(dateRange.from, new Date(dateRange.to));
        console.log('date list is ', dateList)
        if (dateList.length) {
          dateList.pop();
          dateList = dateList.map(date => date.toDateString());
      
          const allocations = getAllCombinations(
            adults,
            children,
            getRoomsByAllocationRules(hotel),
          ).map(allocation => roomsGroupedByType(allocation, hotel, dateList));
      
          const suggestedRooms = allocations.slice();
          suggestedRooms.sort(compareByPriceAndCountAscending);
      
          return suggestedRooms;
        }
        return [];
      }
      static checkIfDisabled({
        passenger,
        updatedPassengers,
        maxPassenger,
        child,
        adults,
        roomNumber,
      }) {
        let output = false;

  const roomPassengers = updatedPassengers.filter(
    pax => pax.roomNumber === roomNumber,
  );
  const isChild = passenger.type === 'child'
  const totalPaxInRoom = roomPassengers.length;
  const childrenInRoom = roomPassengers.filter(pax => pax.type === 'child').length;
  const adultInRoom = totalPaxInRoom - childrenInRoom;

  if (passenger && passenger.roomNumber !== roomNumber) {
    if (
      maxPassenger <= totalPaxInRoom ||
      (child < 1 && passenger.isChild) ||
      (adults <= adultInRoom && !isChild) ||
      (child <= childrenInRoom && isChild)
    ) {
      return true;
    }
    updatedPassengers.map(pax => {
      if (passenger.roomNumber) {
        if (
          pax.id === passenger.id ||
          (child > 0 && childrenInRoom === child && isChild) ||
          (totalPaxInRoom - childrenInRoom === adults && !isChild)
        ) {
          output = true;
        }
      }
      return pax;
    });
  }

  return output;
      }
      static getRecommendedRooms(dateRange, oldAllocation,hotel, passengers) {
        const roomTypeToCheck = [];
        let recommendations = {
          rooms: [],
          total: 0,
        };
        if (
          isValidCase(
            oldAllocation,
            passengers.map(pax => pax.passengerId),
            roomTypeToCheck,
          )
        ) {
          const children = passengers.filter(pax => pax.type === 'child').length;

          let dateList = getDatesBetweenDates(dateRange.from, new Date(dateRange.to));
          if (dateList.length) {
            dateList.pop();
            dateList = dateList.map(date => date.toDateString());

            const combinations = [];
            const oldCombinations = allocationMapper(
              roomTypeToCheck,
              passengers,
            );

            getAllCombinations(
              passengers.length - children,
              children,
              getRoomsByAllocationRules(hotel),
            ).map(
              combination =>
                !combinations.length &&
                combination.length === roomTypeToCheck.length &&
                arraysEqual(
                  oldCombinations.map(r => `${r.roomType}-${r.adults}-${r.children}`),
                  combination.map(r => `${r.roomType}-${r.adults}-${r.children}`),
                ) &&
                combinations.push(...combination),
            );

            recommendations = allocateRoomToPassengers(
              combinations,
              hotel,
              dateList,
              oldCombinations,
            );
          }
        }
        return recommendations;
            }
      static roomObj() {
        return {
          single: 'Single Room',
          twin: 'Twin Room',
          triple: 'Triple Room',
          quad: 'Quad Room',
          twinShare: 'Twin Share Room',
          tripleShare: 'Triple Share Room',
          quadShare: 'Quad Share Room'
      }
    }
  }
