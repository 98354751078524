import React, { PureComponent } from 'react'
import Button from 'components/Buttons'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Utils, Cart } from 'helpers'
import { withRouter } from 'react-router-dom'
import * as userThunk from 'redux/thunks/UserThunk'
import * as chatThunk from 'redux/thunks/ChatThunk'
import { bindActionCreators } from 'redux'
import Modal from 'components/Modal'
import Card from 'components/Card'
import Callout from 'components/Callout'
import { FileUploaderApi } from 'apis'
import { AppConstant } from 'constants'
import './styles.scss'

const {
    Header: { labelLogoutSM, myTripsSM, chat, viewBasket },
    Chatter: {
        uploadProfile,
        removeProfile,
        fileSizeError,
        fileFormatError,
        profileTitle: { removeProfileAvatar, btnDelete, btnCancel },
    },
} = Utils.getTranslations()

const {
    App: { showBasket },
} = Utils.getConfigs()

class NavigationBarItem extends PureComponent {
    static displayName = 'NavigationBarItem'

    static propTypes = {
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        email: PropTypes.string,
        onClick: PropTypes.func,
        logoutClick: PropTypes.func.isRequired,
        userThunk: PropTypes.shape({
            getUserDetails: PropTypes.func.isRequired,
        }).isRequired,
        chatThunk: PropTypes.shape({
            updateChatUser: PropTypes.func.isRequired,
        }).isRequired,
    }

    static defaultProps = {
        firstname: '',
        lastname: '',
        email: '',
        onClick: () => {},
    }

    constructor(props) {
        super(props)
        this.state = {
            fileSizeExceeded: false,
            fileTypeNotMatched: false,
            isProfileImageModalVisible: false,
        }
        this.onDrop = this.onDrop.bind(this)
        this.onRemoveIcon = this.onRemoveIcon.bind(this)
    }

    onDrop = async event => {
        const {
            target: { files = [] },
        } = event
        const {
            ssoId,
            userThunk: { getUserDetails },
            preferredName,
            firstname,
            lastname,
        } = this.props

        this.setState({ fileTypeNotMatched: false, fileSizeExceeded: false })

        const { name, type, size } = files[0] || {}
        const imageType = ['image/jpeg', 'image/png']
        if (!imageType.includes(type)) {
            return this.setState({ fileTypeNotMatched: true })
        }
        if (size > 2097152) {
            return this.setState({ fileSizeExceeded: true })
        }
        const guestName = preferredName || `${firstname} ${lastname}`
        const formData = new FormData()
        formData.append('file', files[0])
        formData.append('name', name)
        formData.append('type', type)
        formData.append('ssoId', ssoId)
        formData.append('preferredName', guestName)
        const header = {
            'Content-Type': 'multipart/form-data',
        }
        const result = await FileUploaderApi.sendFile(formData, header)
        if (result) {
            getUserDetails()
        }
        return result
    }

    onRemoveIcon = async () => {
        const {
            ssoId,
            preferredName,
            userThunk: { getUserDetails },
            chatThunk: { updateChatUser },
        } = this.props

        this.setState({ fileTypeNotMatched: false, fileSizeExceeded: false })

        const { firstname, lastname } = this.props
        const guestName = preferredName || `${firstname} ${lastname}`
        const formData = new FormData()
        formData.append('ssoId', ssoId)
        formData.append('preferredName', guestName)
        const header = {
            'Content-Type': 'multipart/form-data',
        }
        const result = await FileUploaderApi.sendFile(formData, header)
        this.setState({ isProfileImageModalVisible: false })
        if (result) {
            await getUserDetails()
            await updateChatUser()
        }
        return result
    }

    openModal = () => {
        this.setState({ isProfileImageModalVisible: true })
    }

    closeModal = () => {
        this.setState({ isProfileImageModalVisible: false })
    }

    render() {
        const {
            firstname,
            lastname,
            appData,
            email,
            onClick,
            logoutClick,
            avatar,
            activeBookingDetails,
            isfutureTravelCredit,
            cart,
            activeBookingType,
            tripStarted,
        } = this.props
        const { fileSizeExceeded, fileTypeNotMatched, isProfileImageModalVisible } = this.state
        const { isChatterEnabled } = activeBookingDetails
        const isClientDirect = activeBookingType === AppConstant.BOOKING_TYPE_CLIENT_DIRECT
        const totalPrice = Cart.getTotalPrice(cart)
        const numberOfItems = Cart.getNumberOfItems(cart)
        const currencyCode = Utils.getCurrencyCode()
        let finalAmount = totalPrice
        if (finalAmount > 0 && appData.showInsuranceDifference && appData.showInsuranceDifference > 0) {
            finalAmount = Number(finalAmount) + appData.showInsuranceDifference
        }
        return (
            <Card noShadow componentName="NavigationBarItem">
                <Card.Banner className="NavigationBarItem__banner">
                    {!avatar && <div className="NavigationBarItem__avatar" />}
                    {avatar && (
                        <img alt="avatar" src={avatar} className="NavigationBarItem__avatar NavigationBarItem__avatar-real" onChange={e => this.onDrop(e)} />
                    )}
                </Card.Banner>
                <Card.Body>
                    <div>
                        <div data-hj-suppress className="NavigationBarItem__linkHeader NavigationBarItem__linkHeader--guestName">
                            {firstname} {lastname}
                        </div>
                        <div data-hj-suppress className="NavigationBarItem__linkText">
                            {email}
                        </div>
                        <Button checkProducts noStyle confirmBeforeAction type="button" onClick={logoutClick}>
                            <span className="NavigationBarItem__linkText NavigationBarItem__linkText--logOut show-for-small-only">{labelLogoutSM}</span>
                        </Button>
                    </div>
                </Card.Body>
                <Card.Footer>
                    {isChatterEnabled && !isfutureTravelCredit && (
                        <Button
                            to="/chat"
                            type="link"
                            className="NavigationBar__topMenu__chatLink small-only-expanded tertiary show-for-small-only"
                            onClick={onClick}
                        >
                            {chat}
                        </Button>
                    )}
                    {currencyCode && isClientDirect && showBasket && !tripStarted && window.location.pathname !== '/' && (
                        <div className="NavigationBar__basketSection show-for-small-only">
                            {`${numberOfItems} item(s)`} <strong className="NavigationBar__basketSection--price">{`${currencyCode} ${finalAmount}`}</strong>
                            <Button
                                disabled={!(cart.length > 0 && window.location.pathname !== '/travelbasket')}
                                className="primary medium-down-expanded NavigationBar__basket"
                                onClick={() => {
                                    const { history } = this.props
                                    history.push('/travelbasket')
                                }}
                            >
                                {viewBasket}
                            </Button>
                        </div>
                    )}
                    <Button to="/" type="link" className="small-only-expanded tertiary show-for-small-only" onClick={e => onClick(e, false, true)}>
                        {myTripsSM}
                    </Button>
                    {isChatterEnabled && !isfutureTravelCredit && (
                        <>
                            <div className="NavigationBarItem__imageUpload NavigationBarItem__profileUpload small-only-expanded tertiary show-for-small-only">
                                <label htmlFor="file-upload" className="NavigationBarItem__mobileUpload">
                                    <span className="NavigationBarItem__custom-file-upload">{uploadProfile}</span>
                                </label>
                                <input id="file-upload" onChange={e => this.onDrop(e)} type="file" name="file-upload" accept=".jpg, .jpeg, .png" />
                            </div>
                            {avatar && (
                                <div className="NavigationBarItem__imageUpload NavigationBarItem__profileUpload small-only-expanded tertiary show-for-small-only">
                                    <label htmlFor="file-remove" className="NavigationBarItem__mobileUpload">
                                        <button type="button" id="removeIcon" className="NavigationBarItem__custom-file-upload" onClick={this.openModal}>
                                            {removeProfile}
                                        </button>
                                    </label>
                                </div>
                            )}
                            <Modal componentName="NavigationBarItem" open={isProfileImageModalVisible} onDemandClose additionalButtonText>
                                <div className="">
                                    <div className="NavigationBarItem__removeProfileAvatar">{removeProfileAvatar}</div>
                                    <div className="NavigationBarItem__removeProfile">
                                        <Button
                                            confirmBeforeAction
                                            id="removeButton"
                                            className="quit NavigationBarItem__removeProfileButton"
                                            onClick={this.onRemoveIcon}
                                        >
                                            {btnDelete}
                                        </Button>
                                        <Button
                                            confirmBeforeAction
                                            id="cancelButton"
                                            className="quit NavigationBarItem__removeProfileButton"
                                            onClick={this.closeModal}
                                        >
                                            {btnCancel}
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </>
                    )}
                    <div className="NavigationBarItem__chatError">
                        {fileSizeExceeded && <Callout type="error" description={fileSizeError} />}
                        {fileTypeNotMatched && <Callout type="error" description={fileFormatError} />}
                    </div>
                    <Button checkProducts confirmBeforeAction type="button" onClick={logoutClick} className="medium-expanded tertiary show-for-medium">
                        <span>{labelLogoutSM}</span>
                    </Button>
                </Card.Footer>
            </Card>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    userThunk: bindActionCreators(userThunk, dispatch),
    chatThunk: bindActionCreators(chatThunk, dispatch),
})

const mapStateToProps = state => ({
    firstname: state.userData.firstname,
    lastname: state.userData.lastname,
    email: state.userData.email,
    cart: state.cart,
    ssoId: state.userData.ssoId,
    preferredName: state.userData.preferredName,
    tripStarted: state.activeBooking.tripStarted,
    activeBookingType: state.activeBooking.bookingNumber.bookingType,
    avatar: state.userData.avatar,
    activeBookingDetails: state.activeBookingDetails,
    sellingCompanyShortName: state.activeBooking && state.activeBooking.sellingCompany && state.activeBooking.sellingCompany.sellingCompanyShortName,
    isfutureTravelCredit: state.activeBooking.isfutureTravelCredit,
    appData: state.appData,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationBarItem))
