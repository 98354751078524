import ReactGA from 'react-ga4'

export default class Analytics {
    static click(config) {
        const { action = 'unknown', category = 'unknown', label = 'unknown' } = config || {}
        if (typeof ReactGA !== 'undefined' && process.env.EXECGOOGLEANALYTICS) {
            ReactGA.event({
                action,
                category,
                label,
            })
        }
    }
}
