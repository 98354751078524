import querystring from 'querystring'
import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class SSORegistration {
    static inviteRegistration(token, password) {
        return Utils.ajax().post(NodeAPIUrl.SSO_REGISTRATION, { token, password })
    }

    static pairSso(mtpToken) {
        const baseURL = NodeAPIUrl.PAIR_SSO
        const token = querystring.encode({ token: mtpToken })
        const url = `${baseURL}?${token}`

        return Utils.ajax().post(url)
    }
}
