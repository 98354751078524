export default {
    MyBrand: {
        name: 'Brendanvacations',
        displayName: 'Brendan',
        fullName: 'BrendanVacations',
        mtpName: 'MyBrendanVacations',
    },
    Accordion: { collapseAll: 'Collapse all', expandAll: 'Expand all' },
    AddMyBooking: {
        Card: { addButton: 'Add My Booking', header: 'Add your booked trip' },
        Form: {
            footer: 'Note: Your booking will only be available 24 hours after it has been confirmed.',
            header: '<p>To register and personalise your upcoming trip, add your booking to your My BrendanVacations.</p><p><strong>Please enter your name exactly as it was given to My BrendanVacations to make your booking. This is usually your name as it appears on your passport.</strong></p>',
            Fieldset: {
                title: 'Add My Booking Fields',
                fields: {
                    firstName: { placeholder: 'First Name', title: 'First Name' },
                    lastName: { placeholder: 'Last Name', title: 'Last Name' },
                    email: { placeholder: 'Email Address', title: 'Email Address' },
                    reference: { placeholder: 'Booking Reference', title: 'Booking Reference (eg. A123456)' },
                },
            },
        },
        AddMyBookingErrorMessages: {
            errorMessages: {
                ensureDetailsMatch:
                    "<p><ul class='AddMyBooking__errorMessagelist'><li>Please ensure your details match what was on your booking</li><li>Your booking has been confirmed</li><li>It has been 24 Hours since the booking was made</li></ul></p>",
            },
        },
    },
    App: { months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] },
    BookingsList: {
        bookingNameLabel: 'Booking Ref CH: ',
        pastBookingsHeader: 'Your past trips',
        upcomingBookingsHeader: 'Your upcoming trips',
        upcomingBookingsSubHeader:
            'All of your upcoming trips should be listed below, if a booking is missing click “Find my booking” to find and link it to your My BrendanVacations.',
        viewButtonLabel: 'View Details',
        noBookingsFound: 'No Bookings Found! Bookings will appear 24 hours after booking has been confirmed.',
    },
    ContactUsButton: { text: 'Contact Us' },
    CallUsButton: {
        defaultPhoneNumber: '01234567890',
        sellingCompanyToPhoneNumberMapping: {
            BVSYDS: '1-800-687-1002',
            BVCANS: '1-800-687-1002',
            BVBEUS: '',
            BVAKLS: '1-800-687-1002',
            BVBAUS: '1-800-687-1002',
            BVUSAS: '1-800-687-1002',
            BVUKLS: '1-800-687-1002',
            BVEUOS: '1-800-687-1002',
            BVMUCS: '1-800-687-1002',
        },
    },
    Countdown: { day: { plural: 'Days', singular: 'Day' }, over: 'We Miss You', toGo: 'To Go', trip: 'Trip' },
    EdocsCard: {
        descriptions: [
            'Enjoy the ease of accessing all of your travel information here, or you can download your documents directly to your mobile device to view them anytime, anywhere… even when you are without WiFi.',
        ],
        dropDownLabel: 'Select a Guest',
        heading: 'Travel Documents',
        errorMessages: {
            regIncomplete: 'Please complete registration for $guestName$. We will need this information to be able to provide travel documents.',
            prefIncomplete: 'Please complete personalization for $guestName$ so that we can enhance your on-trip experience.',
            regAndPrefIncomplete:
                'Please complete registration and personalization for $guestName$. We will need this information to be able to provide travel documents.',
            docsNotReady:
                'You have completed registration and personalization for $guestName$. Travel documents will be available approximately 21 days prior to your departure.',
            docsNotFound:
                'No data is currently available for $guestName$. If you just made your reservation, wait 24 hours from the time of booking and try again. If you are still experiencing an issue, please contact our team for support.',
        },
        InformationTile: {
            personalisation: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Personalize Your trip',
                description: { incomplete: 'To Customize Your Travel Preferences', complete: 'To Customize Your Travel Preferences' },
            },
            registration: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Register Your trip',
                description: { incomplete: 'To Ensure An Appropriate Duty of Care', complete: 'To Ensure An Appropriate Duty of Care' },
            },
        },
    },
    EdocsSubscriptionBox: {
        header: 'Your Travel Documents',
        paperDocumentsChoice: 'I want to travel with paper documents and not plant a tree',
        paperLessDocumentsChoice: 'I want to travel paper free and plant a tree',
        buttons: { viewDocuments: 'View Edocuments', choosePaperless: 'Choose Paperless' },
        isDocumentAndPaperLess: { heading: 'Congratulations! You have planted a tree.' },
        isDocumentWithPaper: { heading: 'Oh no! You missed out on planting a tree.' },
    },
    EdocsList: {
        documentDescriptions: {
            allDocument: 'You can download all of your documents for this guest with a single click.',
            Cologne_Train_Transfer:
                'Directions for meeting your transfer at the Cologne Train Station. Includes meeting location, train station map, and contact information.',
            Cruise_Companion:
                'Guidelines to help you get ready for your upcoming trip; includes valuable tips on what to pack, your flight and transfer experience, on trip information and much more.',
            Cruise_Ticket: 'Your cruise eTicket and booking details plus important terms and conditions.',
            Custom_Planning_Voucher: 'Contact information for private transfers and hotels.',
            Docking_Change_Notification: 'Important notice containing the updated docking location address, map, and contact information.',
            Flight_Itinerary: 'Contains your flight times, departure and arrival airports and terminal details for you to travel effortlessly.',
            Hotel_List: "Discover where you'll be settling in every night. Share this information with friends and family in case of an emergency.",
            Important_Transfer_Notice: 'Important notice that you have not taken advantage of our complete transfer options.',
            Schedule_At_A_Glance: 'A broad overview of your itinerary.',
            Tour_Itinerary: 'Discover your day-to-day trip breakdown, where to meet your group, luggage guidelines, Optional Experiences and more.',
            Travel_Protection_Plan: 'Summary of coverages and contact information.',
            Venice_Port_Map: 'Map of the Venice port containing Uniworld Docking locations.',
            Vouchers_For_Information: 'For the best experience, please ensure you read through these before your trip.',
            Vouchers_For_Printing: 'Bring these documents with you and keep them handy at hotels and for transfers along the way. ',
            Whats_Next:
                'Where will you be going next? Let us help you plan your next trip with My Wishlist. Here you can start your wishlist, get quotes and more…',
        },
        downloadAllHeading: 'All documents',
        downloadAllBtn: 'Download All',
        downloadBtn: 'Download',
        voucherPrintMsg: 'This document must be printed.',
    },
    WarningModal: {
        submit: 'Submit',
        next: 'Next',
        message: 'If you move away from this page your changes will be lost, please click $buttonName$ to save your changes.',
        buttons: { continue: 'Continue', back: 'Go back' },
    },
    Faq: {
        header: 'Frequently Asked Questions',
        description:
            'Please read through the below pre-travel information. Not only will you be able to make well-informed travel decisions, this information will help ensure your trip is as effortless and enjoyable as possible.',
        footer: { header: 'Still have unanswered questions?', description: 'Get in touch or speak to one of our experts on the phone.' },
        CallUsButton: { prefix: 'or call us at' },
        questions: [
            {
                answer: "<p class='Faq__faqAnswerTitles'>Clothing:</p><p>Ireland and Scotland are known for their rainy days and the weather varies by region, proximity to the coast and time of year. So we suggest that you pack layers of clothing that can be added or removed as necessary, along with a light waterproof jacket and travel-sized umbrella. A comfortable pair of flat or tennis shoes for walking is essential. You may wish to bring a dressier outfit for a special night out. Smart casual clothes are acceptable attire for other evening activities.</p><p class='Faq__faqAnswerTitles'>Gadgets:</p><p>During your trip, you will be supplied with Vox Tour Guide Systems, which will let you hear your Travel Director or Local Specialist during highlight experiences while allowing you to walk at leisure at a comfortable distance. We supply one Vox in-ear earphone, but also recommend you bring your own pair of headsets with an Auxiliary Audio Cable. This will ensure you have the sound quality which suits you, all while helping us reduce our single use plastic along the way.</p>",
                id: 'pack',
                question: 'What should I pack?',
            },
            {
                answer: "<p><a href='https://packr.app/' target='_blank'>Packr</a> and <a href='https://www.packpnt.com/' target='_blank'>PackPoint</a> will give you great packing tips based on forecasted weather.</p><p><a href='https://weather.com' target='_blank'>Weather Channel</a> or <a href='https://www.accuweather.com' target='_blank'>Accuweather</a> are the most popular weather apps to keep up to date with the local weather of the places you will go.</p><p><a href='https://translate.google.com/' target='_blank'>Google Translate</a> is great for quick translations.</p>",
                id: 'apps',
                question: 'What are the best travel apps to help me prepare for my trip?',
            },
            {
                answer: '<p>Your trip information will be provided in your e-Documents available within the MyBrendan portal approximately 21 days prior to your departure. Your Travel Director will be in touch a few days before your trip to introduce themselves and re-confirm your meeting point.</p>',
                id: 'meet',
                question: 'Where do I meet on Day 1?',
            },
            {
                answer: '<p>Yes, in Ireland and Scotland it is fine to drink the water from a fresh supply tap. At Brendan we are focused on caring for our planet and so recommend that you pack a reusable water bottle for your trip. Throughout the day, there will be multiple rest stops allowing time for a bottle refill or beverage purchase.</p>',
                id: 'water',
                question: 'Can I drink the water in the countries I visit?',
            },
            {
                answer: '<p>Optional Experiences have been handcrafted by our destination experts to give you the ability to personalize your trip and discover even more of the places you’ll visit.  You can get a flavor of what is in store for you on the detailed Trip Itinerary section of the MyBrendan portal, plus your Travel Director will also share all the options with you on the first day of your trip. No need to book or prepay, you can do this with your Travel Director on trip and payment can be made by credit card or cash. Simple!</p>',
                id: 'optional',
                question: 'What are Optional Experiences and how can I book them?',
            },
            {
                answer: "<p>Your luggage allocation is one suitcase to the maximum weight of 50lbs (23kg) and the dimensions of 30” x 18” x 10” (76cm x 46cm x 25cm). You can also bring one item of hand luggage that is small and light enough to be carried onto the coach and fit underneath the seat, or in the small overhead compartment above (luggage with adjustable handles and wheels will not fit). Baggage allowances also vary from airline to airline and you should also check with your chosen carrier for details prior to travel.</p><p class='Faq__faqAnswerTitles'>Porterage:</p><p>It’s your trip and we are committed to your ease and comfort, and so porterage is included for your luggage throughout the trip (one item per person), meaning that you only have to bring your hand luggage on and off the coach each day. From the minute you arrive, you can travel hassle free. You will be given a luggage tag when you are picked up at the airport that will help with identification and security of your bag. From airport arrival to each hotel stay, your luggage will be loaded on and off the coach and delivered directly to your room leaving you more time to enjoy! We do suggest that you pack toiletries and any personal items you may want immediately on arrival at each stop in your hand baggage so they are instantly available.</p>",
                id: 'luggage',
                question: 'Are there any luggage restrictions?',
            },
            {
                answer: '<p>We’ll check you in, look after your luggage and take care of all hotel porterage, tips and gratuities – whether it is for restaurant staff, chambermaids or porters. Easy! If you do however feel that your passionate and dedicated Travel Director and Driver have helped enhance your trip – gratuities can be shared at the end of your trip directly with the team.</p>',
                id: 'tips',
                question: 'Are tips included?',
            },
            {
                answer: "<p>You are responsible for obtaining all necessary visas prior to the departure. Depending on your nationality, you may need visas to enter certain countries included on your trip. Please note that obtaining visas can take up to six weeks and so it is strongly advised that you consult your Travel Agent and the local Consulate or Embassy of the country you are visiting well in advance to determine which visas they require and how to obtain them. You can also visit the <a href='https://visacentral.com/'>VisaCentral</a> website for more information.</p>",
                id: 'visa',
                question: 'Will I need a visa?',
            },
            {
                answer: '<p>Most hotels do offer a laundry service and in some cities Laundromats are also available. However, it’s sensible to try and pack enough clothing to avoid having to do laundry, as it can sometimes be costly or inconvenient.</p>',
                id: 'laundry',
                question: 'Will I be able to get laundry done?',
            },
            {
                answer: '<p>We love caring for our planet and hope you do too. We recommend being a conscious traveler and so here are some easy tips and tricks to make a difference: </p><p>- Leave any plastic packaging from your new purchases at home to avoid needing to dispose of them on trip.</p><p>- Avoid buying plastic bottled water and instead bring a reusable bottle or cup with you and refill at our many refreshment and comfort stops.</p><p>- Carry a reusable shopping bag with you for any souvenirs you may pick up along the way.</p><p>At Brendan, we are dedicated to giving back to the places we visit. To safeguard cultures, traditions and heritages for future generations we encourage you to purchase souvenirs and trinkets from local artisans we’ll encounter. Your Travel Director can share tips on the best places to purchase memories that will in turn give back and support the communities we visit.</p><p>Reduce your use of single use plastic during your trip and refuse things such as plastic bags, straws and bathroom toiletries. Every wee bit of effort makes a big difference!</p>',
                id: 'conscious',
                question: 'How can I make a difference and be a conscious traveler?',
            },
            {
                answer: "<p>No worries. Contact our travel experts, USA Residents Call <a href='tel:1-800-687-1002' target='_blank'>1-800-687-1002</a>, Canada Residents Call <a href='tel:1-833-464-7474' target='_blank'>1-833-464-7474</a></p>",
                id: 'moreQuestions',
                question: 'Still have questions?',
            },
        ],
    },
    TravelExtras: {
        benefitsText:
            'For your benefit and clarity, the total airfare shown includes airline imposed fuel surcharges and government taxes and fees. If you need more clarification, please see our <a href="https://www.brendanvacations.com/travel-resources/booking-conditions" target="_blank">Terms and Conditions</a>',
    },
    Footer: {
        copyright: 'Copyright $year$.',
        privacyPolicy: 'Privacy Policy',
        rightsReserved: 'All rights reserved.',
        termsAndCondition: 'Terms and Conditions',
    },
    Header: {
        CallUsButton: { suffix: 'or call your Travel Agent' },
        labelBrandSite: 'Back to Site',
        labelLogo: 'Home',
        labelLogoutMD: 'Log Out of Account',
        labelLogoutSM: 'Log Out',
        viewBasket: 'view Basket',
    },
    HelpBox: {
        CallUsButton: { prefix: 'or call us at' },
        questions: [
            { id: 'optional', question: 'What are Optional Experiences and how can I book them?' },
            { id: 'luggage', question: 'Are there any luggage restrictions?' },
            { id: 'allQuestions', question: "View all FAQ's" },
        ],
        header: 'Need some help?',
    },
    Hero: {
        greeting: { guest: 'Welcome to', member: 'Welcome to My Brendan,', prefix: 'Your Celtic Story Starts Here' },
        alertMessage: '',
        healthAlertMessage: '',
    },
    HomeButton: { buttonLabel: 'Home' },
    HotelMeals: {
        hotelTitle: 'Overnight',
        mealTitle: 'Meals inc.',
        helpText: 'All hotels have been carefully selected to enhance your trip, but please note they are subject to change any time.',
    },
    Itinerary: { accordionHeading: 'Select an Itinerary Day', dayTitle: 'Day' },
    ItineraryCard: { header: 'Trip Details', mapTitle: 'Trip Map', viewTripButton: 'View Trip' },
    Loader: { message: 'Loading...' },
    LoginForm: { emailAddress: 'Email Address', password: 'Password' },
    SignUpCard: {
        login: 'Login',
        signup: 'Sign Up',
        loginHeader: 'Login to',
        signUpHeader: 'Sign Up For',
        brandText: 'My ',
        subHeader: '',
        subDescription:
            'For Self-Drive, Locally Hosted Rail, Lux Self-Drive or Private Chauffeur travel styles, please contact our Celtic Experts: USA Residents Call 1-800-687-1002, Canada Residents Call 1-833-464-7474.',
        description:
            'Register your details and personalize your guided trip for total peace of mind. All guests within a booking must create their own account. For Guided Vacation bookings, please proceed below.',
        firstname: 'First Name',
        haveAccountDescription: '',
        lastname: 'Last Name',
        emailAddress: 'Email Address',
        passWord: 'Password',
    },
    MmbRegistration: { registerText: 'Want to register for your trip without creating an account? ' },
    Modal: { closeBtn: 'close' },
    Navigation: {
        navigationList: {
            Edocs: 'Download Your Documents',
            Faq: 'Help',
            Itinerary: 'View Your Trip Itinerary',
            TripPersonalisation: 'Personalize Your Trip',
            TripRegistration: 'Register Your Details',
        },
    },
    NotFound: { subHeading: 'Page Not Found', message: "Keep on moo'ing, just head back to our home page for all your travel needs." },
    Optional: {
        heading: 'Optional Experiences',
        helpText:
            'These special experiences have been hand picked to enhance your trip. Your Travel Director will arrange booking/s and payment directly with you on your trip. Please note that all experiences are indicative only and may be withdrawn or changed at any time.',
        priceForAdult: 'Adult price:',
        priceForChild: 'Child price:',
        viewDetail: 'View Details',
    },
    TripPersonalisationWizard: {
        Buttons: { labels: { back: 'Back', complete: 'Next Guest', next: 'Next', submit: 'Submit', skip: 'Complete Later' } },
        Header: { multiPax: 'Personalize Your Trip for $guestName$', singlePax: 'Personalize Your Trip' },
        requiredHint: '*Required',
        errorMessages: {
            unableToSave: 'Unable to edit your information at this time, please try again later.',
            beddingPreference: 'You must select a bedding preference to complete personalization.',
        },
        Steps: {
            PersonaliseDetails: {
                beddingOptions: {
                    heading: 'Bedding Options',
                    description: 'Please choose your bedding configuration preference.',
                    Fieldset: {
                        description: 'You will be sharing with $guestName$. What bedding option would you prefer?',
                        fields: { doubleBed: 'Double Bed', twinBed: 'Twin Beds' },
                        title: 'Bedding Options Fields',
                    },
                },
                addtionalInformation: {
                    heading: 'Additional information',
                    Fieldset: {
                        personalRequest: {
                            description: 'We will do our best to accommodate any additional special or dietary requirements you have where possible.',
                            fields: { comment: { placeholder: '', title: 'Add Comment' } },
                            title: 'Personal Requests',
                        },
                        travelPassion: {
                            description: 'What are you most looking forward to about your trip?',
                            fields: { comment: { placeholder: '', title: 'Add Comment' } },
                            title: 'Your Travel Passion',
                        },
                    },
                },
            },
            PersonalisationSuccess: {
                heading: {
                    complete: {
                        both: 'Congratulations you have finished registration and personalization for $guestName$.',
                        self: 'You have completed personalization for $guestName$, but still need to complete registration.',
                    },
                    incomplete: {
                        both: 'You have not completed personalization section for $guestName$.',
                        self: 'You have not completed personalization section for $guestName$.',
                    },
                },
                description: {
                    complete: { both: 'Get ready to start your Celtic story!', self: 'Please click the button below to enter your details.' },
                    incomplete: { both: 'Please come back here anytime to finish it.', self: 'Please come back here anytime to finish it.' },
                },
                InformationTile: {
                    registration: {
                        button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                        heading: 'Register Your Details',
                        description: { incomplete: 'To ensure an appropriate duty of care', complete: 'To ensure an appropriate duty of care' },
                    },
                    documents: {
                        button: { incomplete: 'View Documents', complete: 'View Documents' },
                        heading: 'Travel Documents',
                        description: {
                            incomplete: 'Documents will be ready 21 days prior to your departure',
                            complete: 'Your travel documents are ready',
                        },
                    },
                },
            },
        },
        SubHeader:
            'Complete your trip preferences and we will share them with your Travel Director. Every effort will be made to accommodate all requests, however do note some options may not be available during your trip.',
    },
    TripRegistrationWizard: {
        Buttons: {
            labels: {
                back: 'Back',
                complete: 'Next Guest',
                edit: 'Make Changes',
                next: 'Next',
                submit: 'Submit',
                skip: 'Complete Later',
                view: 'View Documents',
            },
        },
        errorMessages: {
            unableToSave: 'Unable to edit your information at this time, please try again later.',
            validationErrors: 'You must provide required values to complete registration.',
            travelInsurance: 'You must select an option to complete registration.',
        },
        Header: { multiPax: 'Trip Registration for $guestName$', singlePax: 'Trip Registration' },
        requiredHint: '* Required',
        Steps: {
            ContactDetails: {
                heading: 'On Trip Contact Details',
                description: 'In the unlikely event of an incident, we will use these details to contact you.',
                Fieldset: {
                    fields: {
                        emailAddress: { placeholder: 'Email Address', title: 'Email Address' },
                        phoneNumber: { placeholder: { internationalCallingCode: 'Select', number: 'Contact Number' }, title: 'Contact Number' },
                    },
                    title: 'Trip Contact Details Fields',
                },
            },
            EmergencyContact: {
                heading: 'Emergency Contact',
                description: 'In the unlikely event of an incident, we will contact the person nominated, this person must not be on the trip with you.',
                Fieldset: {
                    fields: {
                        name: { placeholder: 'Name', title: 'Name' },
                        phoneNumber: { placeholder: { internationalCallingCode: 'Pick', number: 'Contact Number' }, title: 'Contact Number' },
                        relationship: { placeholder: 'Relationship', title: 'Relationship' },
                    },
                    title: 'Emergency Contact Fields',
                },
            },
            PassportDetails: {
                heading: 'Passport Details',
                description:
                    '<p>Please provide details so we can ensure a seamless trip from hotel check-ins to boarder security checks and more. Incomplete or inaccurate details may result in services being affected or additional charges.</p><p> Many countries require passports to be valid for a minimum of 6 months after travel. Please check with local consular services for passport validity and visa requirements for your tour.</p>',
                Callout: {
                    messageForCheckPassportExpiry:
                        'Please be advised that many countries require that passports be valid for a minimum of 6 months after the completion of your trip. Please check the local consulate for passport validity requirements for your trip.',
                },
                Fieldset: {
                    fields: {
                        countryOfIssue: { placeholder: 'Country of Issue', title: 'Country of Issue' },
                        dateOfBirth: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Date of Birth *' },
                        expiryDate: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Expiry Date *' },
                        issueDate: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Date of Issue *' },
                        nationality: { placeholder: 'Nationality', title: 'Nationality' },
                        number: { placeholder: 'Passport Number', title: 'Passport Number *' },
                    },
                    title: 'Passport Details Fields',
                },
            },
            RegistrationSuccess: {
                heading: {
                    complete: {
                        both: 'Congratulations you have finished registration and personalization for $guestName$.',
                        self: 'You have completed registration for $guestName$, but still need to complete personalization.',
                    },
                    incomplete: {
                        both: 'You have not completed registration section for $guestName$.',
                        self: 'You have not completed registration section for $guestName$.',
                    },
                },
                description: {
                    complete: { both: 'Get ready to start your Celtic story!', self: 'Please click the button below to enter your details.' },
                    incomplete: { both: 'Please come back here anytime to finish it.', self: 'Please come back here anytime to finish it.' },
                },
                InformationTile: {
                    personalisation: {
                        button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                        heading: 'Personalize Your Trip',
                        description: { incomplete: 'To customize your travel preferences', complete: 'To customize your travel preferences' },
                    },
                    documents: {
                        button: { incomplete: 'View Documents', complete: 'View Documents' },
                        heading: 'Travel Documents',
                        description: {
                            incomplete: 'Documents will be ready 21 days prior to your departure',
                            complete: 'Your travel documents are ready',
                        },
                    },
                },
            },
            TravelInsurance: {
                heading: 'Travel Insurance',
                description: 'To ensure your peace of mind while travelling, it is essential that you have travel insurance before you depart.',
                Callout: {
                    messageForTravelProtection: 'You have purchased $name$ with us, however this is not Travel Insurance.',
                    BVUSAS: "<p class='TravelInsurance__descriptionText'>All guests are required to have travel insurance in order to travel with $brandName$. You are not required to purchase travel insurance from $brandName$ and may purchase travel insurance from any provider that includes the following coverage: Trip Interruption, Trip Delay, Medical Expenses, Quarantine Expenses and Emergency Evacuation/Repatriation. The insurance must cover medical costs and a medically imposed quarantine if the insured, or someone the insured has come into contact with, tests positive for COVID-19. Please check <a href='$nonMandatoryCalloutLink$' target='_blank'>here</a> for additional details and minimum coverage requirements. If you do not currently have travel insurance, please contact $brandName$ or your travel advisor as soon as possible to discuss your options.</p><p class='TravelInsurance__descriptionText'>Should you choose to travel on $brandName$ without the required travel insurance, please check the box below to acknowledge your agreement to take full responsibility for all expenses (trip interruption, trip delay, medical expenses, quarantine expenses and emergency evacuation/repatriation) that may arise while traveling with $brandName$. Further, you agree to indemnify $brandName$ against all third-party claims, actions, damages and remedies which may be brought against $brandName$ as a result of your participation in the $brandName$ trip.",
                },
                Fieldset: {
                    insurance: { fields: { insuranceStatusNo: "No, I don't", insuranceStatusYes: 'Yes, I do' }, title: 'Do you have travel insurance?' },
                    policy: {
                        fields: {
                            companyName: { placeholder: 'Company Name', title: 'Company Name' },
                            phoneNumber: {
                                placeholder: { internationalCallingCode: 'Select', number: 'Company Contact Number' },
                                title: 'Company Contact Number',
                            },
                            policyNumber: { placeholder: 'Policy Number', title: 'Policy Number' },
                        },
                        title: 'Policy Details',
                    },
                },
                travelProtectionInfo: { heading: 'Travel protection for $guestName$', description: 'Valid dates: $startDate$ - $endDate$' },
            },
        },
        SubHeader: 'For your peace of mind, we require the following information to ensure an appropriate duty of care while you are on your trip.',
    },
    TripSchedule: { tripEndHeader: 'End', tripStartHeader: 'Start' },
    TripRegistrationCard: {
        alert: { singleItem: '1 item requires your attention', multipleItems: '2 items require your attention' },
        header: 'Trip Registration',
        subHeader: 'Your important information',
        guestTraveller: {
            header: 'Your Guest Traveler Information',
            description: '($completedGuests$ of $totalGuests$ completed)',
            detailNotComplete: 'Please complete the trip registration and personalization for all guests on your booking',
        },
        InformationTile: {
            personalisation: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Personalize Your Trip',
                description: { incomplete: 'To customize your travel preferences', complete: 'To customize your travel preferences' },
            },
            registration: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Register Your Details',
                description: { incomplete: 'To ensure an appropriate duty of care', complete: 'To ensure an appropriate duty of care' },
            },
        },
    },
    MultiGuest: {
        header: 'Guests',
        titleHeading: 'Your Information',
        edit: 'Edit',
        TripPersonalisation: {
            subHeading: '$completed$ / $total$ personalisations complete',
            progress: { incomplete: 'Personalization Progress', complete: 'Personalization Complete' },
        },
        TripRegistration: {
            subHeading: '$completed$ / $total$ registrations complete',
            progress: { incomplete: 'Registration Progress', complete: 'Registration Complete' },
        },
    },
    ValidationMessages: {
        minLength: { characters: 'Please enter minimum $length$ characters', numbers: 'Please enter minimum $length$ numbers' },
        maxLength: { characters: 'Please enter no more than $length$ characters', numbers: 'Please enter no more than $length$ numbers' },
        numberOnly: "Can't type in letters",
        alphaNumericOnly: 'Can not type in special characters',
        emailFormat: 'Please enter a valid email address',
        phoneFormat: 'Please enter a valid phone number',
        dateFormat: 'Invalid date',
        required: 'This field is required',
    },
    Sustainability: {
        title: 'Travel that matters',
        Card: {
            header: '',
            brandPledgeInfoButtonText: 'Make Travel Matter With Brendan',
            ourStoryText:
                '<p>Twelve years ago, our parent company The Travel Corporation (TTC), asked an important question: how do we make travel a force for good?</p><p>Travel is an incredible gift. It can open our eyes, our hearts and our minds to the unique cultures and spellbinding beauty of the natural world. But with this gift comes a responsibility – to protect the world as we know it.</p><p>The TreadRight Foundation is a non-profit created to ensure that the impact we have on the people and wildlife we visit, and the planet we call home, is a positive one.</p>',
        },
    },
}
