import React from 'react'
import { Utils, Time, Analytics } from 'helpers'
import './styles.scss'

const {
    Footer: { copyright, termsAndCondition, privacyPolicy, rightsReserved },
} = Utils.getTranslations()
const {
    Footer: {
        urls: { termsAndCondition: urlTermsAndCondition, privacyPolicy: urlPrivacyPolicy },
    },
} = Utils.getConfigs()

const termsConditionsLink = () => {
    const params = {
        action: 'terms_and_conditions',
        category: 'footer_terms_and_conditions',
        label: `${urlTermsAndCondition}`,
    }
    Analytics.click(params)
}

const privacyPolicyLink = () => {
    const params = {
        action: 'privacy_policy',
        category: 'footer_privacy_policy',
        label: `${urlPrivacyPolicy}`,
    }
    Analytics.click(params)
}

const Footer = () => (
    <footer className="cell Footer">
        <div className="grid-container">
            <div className="grid-x grid-margin-x Footer__wrapper">
                <div className="cell medium-6">
                    <p className="Footer__copyright">
                        {Utils.replaceParamsInString(copyright, {
                            year: Time.now().format('YYYY'),
                        })}{' '}
                        <br className="show-for-small-only" /> {rightsReserved}
                    </p>
                </div>
                <div className="cell medium-6">
                    <nav className="Footer__list">
                        <a
                            className="Footer__item Login__Footer--item"
                            href={urlTermsAndCondition}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={termsConditionsLink}
                        >
                            {termsAndCondition}
                        </a>
                        <a
                            className="Footer__item Login__Footer--item"
                            href={urlPrivacyPolicy}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={privacyPolicyLink}
                        >
                            {privacyPolicy}
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    </footer>
)

Footer.displayName = 'Footer'

export default Footer
