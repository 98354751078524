import * as actionType from 'redux/actionTypes'

export const setPersonalisationActivePassenger = passenger => ({
    type: actionType.SET_TRIP_PERSONALISATION_PASSENGER,
    payload: passenger,
})

export const setBookingVersion = bookingData => ({
    type: actionType.SET_BOOKING_VERSION,
    payload: bookingData,
})

export const updateSelectedPassanger = pax => ({
    type: actionType.UPDATE_SELECTED_PASSANGER_DATA,
    payload: pax,
})

export const validateInputForm = payload => ({
    type: actionType.VALIDATE_PERSONALISATION_INPUT_FORM,
    payload,
})

export const setNewPersonalizationPassengerData = payload => ({
    type: actionType.SET_NEW_TRIP_PERSONALISATION_PASSENGER_DATA,
    payload,
})

export const updatePersonalisationModalFlag = payload => ({
    type: actionType.SET_TRIP_PERSONALISATION_VERSION_MODAL,
    payload,
})

export const resetTripPersonalisationData = () => ({
    type: actionType.RESET_TRIP_PERSONALISATION_DATA,
    payload: {},
})
