import React, { Component } from 'react'
import { Link, withRouter, Prompt } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from 'components/Buttons'
import NavigationBar from 'components/NavigationBar'
import { Utils, Analytics } from 'helpers'
import { setBookingClose, showBookingList } from 'redux/actions'
import './styles.scss'

const {
    Header: { labelLogo, labelBrandSite },
} = Utils.getTranslations()
const {
    Header: {
        urls: { brandSite: urlBrandSite },
    },
} = Utils.getConfigs()

class Header extends Component {
    static displayName = 'Header'

    static propTypes = {
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        email: PropTypes.string,
        style: PropTypes.shape({}),
        dispatchShowBookingList: PropTypes.func.isRequired,
    }

    static defaultProps = {
        firstname: '',
        lastname: '',
        email: '',
        style: {},
    }

    getProps = () => this.props

    brandSiteLink = () => {
        const params = {
            action: 'brand_link',
            category: 'external_url',
            label: `External URL ${urlBrandSite}`,
        }
        Analytics.click(params)
    }

    onLogoClick = () => {
        const { dispatchShowBookingList, dispatchSetBookingClose } = this.props
        dispatchShowBookingList(true)
        dispatchSetBookingClose(true)
        const params = {
            action: 'logo_link',
            category: 'logo_click',
            label: 'Home Page',
        }
        Analytics.click(params)
    }

    render() {
        const { style, path } = this.props
        const { firstname, lastname, email } = this.props
        const showNavigationBar = window.location.href.indexOf('login') < 0
        const inSecureLogin = sessionStorage.getItem('insecurelogin')
        return (
            <header className="cell Header" style={style}>
                <div className="grid-container">
                    <div className={`grid-x grid-margin-x Header__wrapper ${path === '/' ? 'hideChatLink' : ''}`}>
                        {((firstname && lastname) || inSecureLogin) && showNavigationBar && (
                            <NavigationBar firstname={firstname} lastname={lastname} email={email} />
                        )}
                        <ul className="menu simple Header__menu cell small-8 large-5 medium-6 small-order-2 medium-order-1">
                            <li className="Header__linkContainer">
                                <Link to="/" className="Header__logo" onClick={this.onLogoClick}>
                                    <span className="show-for-sr">{labelLogo}</span>
                                </Link>
                            </li>
                            <li className="Header__linkContainer show-for-medium">
                                <Button noStyle type="external" to={urlBrandSite} className="Header__link" onClick={this.brandSiteLink}>
                                    <span className="Header__linkText">{labelBrandSite}</span>
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
                <Prompt when message="" />
            </header>
        )
    }
}

export default connect(
    ({ userData: { firstname, lastname, email }, cart }) => ({ firstname, lastname, email, cart }),
    dispatch => ({
        dispatchShowBookingList: payload => dispatch(showBookingList(payload)),
        dispatchSetBookingClose: payload => dispatch(setBookingClose(payload)),
    }),
)(withRouter(Header))
