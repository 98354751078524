import React from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import CardContext, { withCard } from './CardContext'
import Header from './Header'
import Banner from './Banner'
import Body from './Body'
import Footer from './Footer'
import Callout from './Callout'
import './styles.scss'

const Card = ({ componentName, className, themeName, noPadding, noShadow, noBorder, children }) => {
    const componentNames = Utils.removeDuplicates(...Utils.addToArray('Card', componentName))
    const classNames = Utils.removeDuplicates(...Utils.stringToArray(className))
    const parentClassName = Utils.argsToString(
        ...componentNames,
        ...(noPadding ? Utils.addSuffixToEachString(componentNames, '--noPadding') : []),
        ...(noShadow ? Utils.addSuffixToEachString(componentNames, '--noShadow') : []),
        ...(noBorder ? Utils.addSuffixToEachString(componentNames, '--noBorder') : []),
        themeName,
        ...classNames,
    )
    const wrapperClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentNames, '__wrapper'), 'grid-y')
    const contextData = {
        componentName: componentNames,
        themeName,
    }

    return (
        <CardContext.Provider value={contextData}>
            <div className={parentClassName}>
                <div className={wrapperClassName}>{children}</div>
            </div>
        </CardContext.Provider>
    )
}

Card.displayName = 'Card'
Card.propTypes = {
    componentName: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    className: PropTypes.string,
    themeName: PropTypes.string,
    noShadow: PropTypes.bool,
    noPadding: PropTypes.bool,
    noBorder: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
Card.defaultProps = {
    componentName: '',
    className: '',
    themeName: '',
    noPadding: false,
    noShadow: false,
    noBorder: false,
}

Card.Header = Header
Card.Banner = Banner
Card.Body = Body
Card.Footer = Footer
Card.Callout = Callout
Card.withCard = withCard

export default Card
export { withCard }
