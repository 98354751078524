import { AppConstant } from 'constants'

const cobrowseConfig = {
    cobrowse: {
        disableWebSockets: false,
        src: AppConstant.GENESYS_COBROWSE_SRC,
        url: AppConstant.GENESYS_COBROWSE_URL,
    },

    callToCobrowse: {
        name: 'CoBrowse',
        clickCommand: 'CoBrowse.open',
        clickOptions: {},
        // use your own static string or i18n query string for the below two display properties
        displayName: 'Co-browse with an agent',
        displayTitle: 'Get live help',
        icon: 'cobrowse',
    },
}

export default cobrowseConfig
