import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const ToolTip = ({ content, heading }) => (
    <div className="Tooltip">
        <span className="Tooltip__text">
            {heading && <strong className="Tooltip__heading">{heading}</strong>}
            {content}
        </span>
    </div>
)

ToolTip.displayName = 'ToolTip'

ToolTip.propTypes = {
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default ToolTip
