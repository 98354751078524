import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class Flights {
    static validateFlight(data) {
        return Utils.ajax().post(NodeAPIUrl.VALIDATE_FLIGHT, { data }).then(Utils.getResponseData).catch(Utils.getResponseData)
    }

    static addFlight(data) {
        return Utils.ajax()
            .post(NodeAPIUrl.ADD_FLIGHT, { data })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN ADD FLIGHT ***'))
    }
}
