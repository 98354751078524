import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import Button from 'components/Buttons'
import ToolTip from 'components/ToolTip'
import './style.scss'

const {
    Modal: { closeBtn },
} = Utils.getTranslations()

const ESCAPE_KEYCODE = 27

const Canvas = ({ setRef, componentName, open, ...props }) => {
    const canvasClassName = Utils.argsToString(
        ...Utils.addSuffixToEachString(componentName, '__canvas'),
        ...(open ? Utils.addSuffixToEachString(componentName, '__canvas--open') : []),
    )

    return <div ref={setRef} className={canvasClassName} {...props} />
}
Canvas.displayName = 'ModalCanvas'

const Box = ({ setRef, componentName, open, ...props }) => {
    const boxClassName = Utils.argsToString(
        ...Utils.addSuffixToEachString(componentName, '__box'),
        ...(open ? Utils.addSuffixToEachString(componentName, '__box--open') : []),
    )

    return <article ref={setRef} className={boxClassName} {...props} />
}
Box.displayName = 'ModalBox'

Canvas.propTypes = Box.propTypes = {
    open: PropTypes.bool,
    componentName: PropTypes.arrayOf(PropTypes.string).isRequired,
    setRef: PropTypes.func,
}
Canvas.defaultProps = Box.defaultProps = {
    open: false,
    setRef: () => true,
}

export default class Modal extends Component {
    static displayName = 'Modal'

    static propTypes = {
        componentName: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
        className: PropTypes.string,
        closeButtonText: PropTypes.node,
        additionalButtonText: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
        open: PropTypes.bool,
        children: PropTypes.node.isRequired,
        title: PropTypes.string,
        link: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        tooltip: PropTypes.string,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        additionalButtonClick: PropTypes.func,
        isMap: PropTypes.bool,
        extraProps: PropTypes.shape({}),
        onDemandClose: PropTypes.bool, // need to set to true if we dont want default close button of modal
        passOnDemandCloseHandlerToChild: PropTypes.bool, // need to set to true if we need to pass onDemandCLoseHandlerProps to child, it will be true in add booking
    }

    static defaultProps = {
        componentName: '',
        className: '',
        closeButtonText: '',
        additionalButtonText: '',
        additionalButtonClick: () => {},
        open: false,
        link: '',
        title: '',
        tooltip: '',
        onOpen: () => true,
        onClose: () => true,
        isMap: false,
        onDemandClose: false,
        extraProps: {},
        passOnDemandCloseHandlerToChild: false,
    }

    constructor(props) {
        super(props)
        const { open } = this.props
        this.state = { isOpen: open, escaped: false, contentStyle: {} }
        this.triggerRef = null
        this.boxRef = null
    }

    // we need this where we pass the handler to parent component, basically where we have additionalBuutonText Prop true, like registration warning pop up, we dont need it in Map modal
    static getDerivedStateFromProps(props, state) {
        if (props.open !== state.isOpen && !state.modalClosedFromParent && (props.additionalButtonText || props.openWithPropsFromState)) {
            return {
                isOpen: props.open,
            }
        }
        return null
    }

    componentDidMount() {
        const escapeHandler = event => {
            if (event.keyCode === ESCAPE_KEYCODE) {
                this.closeModal()
            }
        }
        this.mountedHandler = escapeHandler
        window.addEventListener('keyup', escapeHandler)
    }

    componentDidUpdate(prevProps, prevState) {
        const { isOpen: prevIsOpen } = prevState
        const { isOpen, escaped } = this.state
        if (isOpen !== prevIsOpen) {
            const { onOpen, onClose } = this.props

            if (isOpen) {
                onOpen()
                this.setFocusToBox()
            } else {
                this.setFocusToTrigger()
                this.setModalClosedFromParent()
                onClose(!escaped)
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.mountedHandler)
    }

    setModalClosedFromParent() {
        const { modalClosedFromParent } = this.state
        if (modalClosedFromParent) {
            this.setState({ modalClosedFromParent: false })
        }
    }

    setFocusToTrigger = () => {
        if (this.triggerRef) {
            this.triggerRef.focus()
        }
    }

    setFocusToBox = () => {
        if (this.boxRef) {
            this.boxRef.focus()
        }
    }

    setTriggerRef = ref => {
        this.triggerRef = ref
    }

    setBoxRef = ref => {
        this.boxRef = ref
    }

    openModal = () => this.setState({ isOpen: true })

    onDemandCloseHandler = () => this.setState({ isOpen: false })

    customOnLoad = src => {
        const img = new Image()
        img.src = src
        setTimeout(() => {
            if (img) {
                const imgWidth = img.width
                const imgHeight = img.height
                let modalWidth = imgWidth
                let modalHeight = imgHeight
                if (window.innerWidth - 100 <= imgWidth) {
                    modalWidth = window.innerWidth - 100
                }
                if (window.innerHeight - 100 <= imgHeight) {
                    modalHeight = window.innerHeight - 100
                }
                if (modalHeight !== imgHeight) {
                    modalWidth = modalHeight * (imgWidth / imgHeight)
                }
                if (modalWidth !== imgWidth) {
                    modalHeight = modalWidth * (imgWidth / imgHeight)
                }
                const contentStyle = {
                    width: `${modalWidth}px`,
                    height: `${modalHeight}px`,
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                }
                this.setState({ contentStyle })
            }
            if (window.innerHeight - 100 <= imgHeight) {
                modalHeight = window.innerHeight - 100
            }
            if (modalHeight !== imgHeight) {
                modalWidth = modalHeight * (imgWidth / imgHeight)
            }
            if (modalWidth !== imgWidth) {
                modalHeight = modalWidth * (imgWidth / imgHeight)
            }
            const contentStyle = {
                width: `${modalWidth}px`,
                height: `${modalHeight}px`,
                overflowX: 'hidden',
                overflowY: 'hidden',
            }
            this.setState({ contentStyle })
        },100)

    }

    closeModal = param => this.setState({ isOpen: false, escaped: typeof param !== 'boolean', modalClosedFromParent: true }) // we had to use additional state modalClosedFromParent as this gets called from parent, we dont want getDeriveStateProps to be executed after this setstate

    render() {
        const {
            componentName,
            className,
            title,
            onDemandClose,
            link,
            children,
            contentId = 'modalscroll',
            tooltip,
            closeButtonText,
            additionalButtonText,
            additionalButtonClick,
            isMap,
            extraProps,
            titleChildren,
            passOnDemandCloseHandlerToChild,
            dontShowCloseButton,
        } = this.props
        const { isOpen, contentStyle } = this.state
        const componentNames = Utils.removeDuplicates(...Utils.addToArray('Modal', componentName))
        const classNames = Utils.removeDuplicates(...Utils.stringToArray(className))
        const parentClassName = Utils.argsToString(...componentNames, ...(isOpen ? Utils.addSuffixToEachString(componentNames, '--open') : []), ...classNames)
        const titleBarClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentNames, '__title-bar'))
        const contentClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentNames, '__content'))
        const footerClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentNames, '__footer'))
        const buttonQuitClassName = Utils.argsToString(
            ...Utils.addSuffixToEachString(componentNames, '__button'),
            ...Utils.addSuffixToEachString(componentNames, '__button--quit'),
            'quit hollow small-only-expanded',
        )
        const buttonCloseClassName = Utils.argsToString(
            ...Utils.addSuffixToEachString(componentNames, '__button'),
            ...Utils.addSuffixToEachString(componentNames, '__button--close'),
            'quit',
        )
        const buttonAdditionalClassName = Utils.argsToString(
            ...Utils.addSuffixToEachString(componentNames, '__button'),
            ...Utils.addSuffixToEachString(componentNames, '__button--additional'),
            'quit hollow small-only-expanded',
        )

        let childrenWithExtraProp = children

        /* eslint-disable arrow-body-style */
        const propsObj = {}
        if (isMap) {
            propsObj.customOnLoad = this.customOnLoad
            propsObj.contentStyle = contentStyle
        }
        if (passOnDemandCloseHandlerToChild) {
            propsObj.onDemandCloseHandler = this.onDemandCloseHandler
        }
        childrenWithExtraProp = React.Children.map(children, child => {
            return React.cloneElement(child, {
                ...propsObj,
                ...extraProps,
            })
        })
        return (
            <div className={parentClassName}>
                {link && (
                    <button type="button" onClick={this.openModal} ref={this.setTriggerRef}>
                        {link}
                    </button>
                )}
                <Canvas componentName={componentNames} open={isOpen} onClick={this.closeModal} role="dialog" aria-modal="true">
                    <button label="cancel button" type="button" onFocus={this.setFocusToBox} />
                    <Box tabIndex="0" setRef={this.setBoxRef} componentName={componentNames} open={isOpen} onClick={event => event.stopPropagation()}>
                        {title && (
                            <div className={titleBarClassName}>
                                {titleChildren}
                                {!titleChildren && (
                                    <h1>
                                        {title}
                                        {tooltip && <ToolTip content={tooltip} />}
                                    </h1>
                                )}
                                <Button className={buttonCloseClassName} onClick={this.closeModal}>
                                    ⨉
                                </Button>
                            </div>
                        )}
                        <div id={contentId} style={contentStyle} className={contentClassName}>
                            {childrenWithExtraProp}
                        </div>
                        {!onDemandClose && (
                            <div className={footerClassName}>
                                {additionalButtonText && (
                                    <Button
                                        className={buttonAdditionalClassName}
                                        onClick={() => {
                                            additionalButtonClick()
                                            this.closeModal(true)
                                        }}
                                    >
                                        {additionalButtonText}
                                    </Button>
                                )}
                                {!dontShowCloseButton && (
                                    <Button className={buttonQuitClassName} onClick={this.closeModal}>
                                        {closeButtonText || closeBtn}
                                    </Button>
                                )}
                            </div>
                        )}
                    </Box>
                    <button label="cancel button" type="button" onFocus={this.setFocusToBox} />
                </Canvas>
            </div>
        )
    }
}
