import React from 'react'
import parse from 'html-react-parser'
import './styles.scss'

const AlertMessageBlock = props => {
    const { message, className } = props
    return <div className={`AlertMessageBlock__message ${className}`}>{parse(message)}</div>
}

AlertMessageBlock.displayName = 'AlertMessageBlock'

export default AlertMessageBlock
