import produce from 'immer'
import { SET_BOOKING_DETAILS_FOR_OVERVIEW, SET_PAYMENT_FORM_RESPONSE, SET_PAYMENT_FAILED_ERROR, SET_GET_BOOKING_DETAILS_FAILED_ERROR } from 'redux/actionTypes'

const defaultState = {
    bookingDetails: {
        tours: [],
        flights: { amaedusFights: [], ioFlights: [], promisedFlights: [], futureFLights: [] },
        paymentSchedule: [],
    },
    paymentFormResponse: {},
    paymentFailed: false,
    isGetBookingDetailsFailed: false,
}

/**
 * Note we are using Immer.js for immutability
 */

export default function BookingDetailForOverview(state = defaultState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case SET_BOOKING_DETAILS_FOR_OVERVIEW:
                draft.bookingDetails = action.payload
                break
            case SET_PAYMENT_FORM_RESPONSE:
                draft.paymentFormResponse = action.payload
                break
            case SET_PAYMENT_FAILED_ERROR:
                draft.paymentFailed = action.payload
                break
            case SET_GET_BOOKING_DETAILS_FAILED_ERROR:
                draft.isGetBookingDetailsFailed = action.payload
                break
            default:
                break
        }
    })
}
