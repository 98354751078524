import { REFRESH_BOOKINGS_LIST, TOGGLE_AMB_ERROR_MODAL, UPDATE_LOGGEDIN_USER_DETAILS, UPDATE_SESSION_DATA, UPDATE_USER_PERSONAL_DATA } from 'redux/actionTypes'
import { userData } from 'redux/defaultState'

const User = (state = userData, { type = '', payload }) => {
    switch (type) {
        case REFRESH_BOOKINGS_LIST: {
            return {
                ...state,
                refreshBookingsList: payload || '',
            }
        }

        case TOGGLE_AMB_ERROR_MODAL: {
            return {
                ...state,
                ambErrorModalVisible: payload || false,
            }
        }

        case UPDATE_LOGGEDIN_USER_DETAILS: {
            return {
                ...state,
                firstname: payload.firstname || '',
                lastname: payload.lastname || '',
                email: payload.email || '',
            }
        }

        case UPDATE_SESSION_DATA: {
            return {
                ...state,
                authToken: payload || false,
            }
        }

        case UPDATE_USER_PERSONAL_DATA: {
            return {
                ...state,
                preferredName: payload.userInfo.preferredName || '',
                avatar: payload.userInfo.avatar || '',
                ssoId: payload.userInfo.userId || '',
                userToken: payload.userInfo.userToken || '',
            }
        }

        default: {
            return state
        }
    }
}

export default User
