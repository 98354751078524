export default {
    MyBrand: { name: 'costsaver', displayName: 'costsaver', fullName: 'Costsaver', mtpName: 'MyCostsaver' },
    AddMyBooking: {
        Form: {
            header: '<p>To register and personalise your upcoming trip, add your booking to your MyCostsaver.</p><p><strong>Please enter your name exactly as it was given to MyCostsaver to make your booking. This is usually your name as it appears on your passport.</strong></p>',
        },
        AddMyBookingErrorMessages: {
            errorMessages: {
                ensureDetailsMatch:
                    "<p><ul class='AddMyBooking__errorMessagelist'><li>Please ensure your details match what was on your booking</li><li>Your booking has been confirmed</li><li>It has been 24 Hours since the booking was made</li></ul></p>",
            },
        },
    },
    App: { months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] },
    BookingsList: {
        bookingNameLabel: 'Booking ref CS: ',
        upcomingBookingsSubHeader:
            'All of your upcoming trips should be listed below, if a booking is missing click “Find my booking” to find and link it to your MyCostsaver.',
    },
    CallUsButton: {
        defaultPhoneNumber: '01234567890',
        sellingCompanyToPhoneNumberMapping: {
            CSSYDS: '1300 302 118',
            CSCANS: '866 587 1481',
            CSEUOS: '0808 281 1126',
            CSAKLS: '0800 484 333',
            CSSINS: '+65 6922 5965',
            CSJBGS: '+27 011 280 8440',
            CSUSAS: '866 530 6085',
            CSUKLS: '0800 533 5619',
        },
    },
    SignUpCard: {
        haveAccountDescription: 'The ultimate unpackaged tour that lets you make it your own.',
        description: 'All guests within a booking must create their own account. Register your details and personalise your trip for total peace of mind.',
        description1: 'We can’t wait for you to see the world, your way, while making a difference on every single tour.',
        description2: 'Together, WE MAKE TRAVEL MATTER®.',
    },
    insuranceInformation: {
        title: 'Insurance',
        message:
            'Unexpected events can happen while travelling, Including exposure to COVID-19. Which can bring additinal risks and cost. We strongly recommend you obtain travel insurance.',
        selectText: 'Please select your region from the list below to see the minimum requirements for travel insurance:',
        regions: [
            {
                region: 'USA',
                id: 'usa',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs.  We strongly recommend you obtain travel insurance. $brandName$ offers several insurance plans, or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Up to $25,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Up to $100,000 per person.</span></p><p>Coverages should be inclusive of COVID related illnesses or medically imposed quarantine.</p>',
            },
            {
                region: 'CAN',
                id: 'can',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when travelling, including exposure to Covid-19, which can bring additional personal risks and costs.  We strongly recommend you obtain travel insurance. $brandName$ recommends both Travel Protection and Covid Protection plans through Manulife Insurance (1.866.298.2722 Reference ID: TRAVCOR), or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Up to $50,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Up to $200,000 per person.</span></p><p>Coverages should be inclusive of COVID related illnesses or medically imposed quarantine.</p>',
            },
            {
                region: 'South Africa',
                id: 'sa',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs.  We strongly recommend you obtain travel insurance. $brandName$ offers several insurance plans, or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Up to R3000 per person per day.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Up to R1,000,000 per person.</span></p><p>Coverages should be inclusive of COVID related illnesses or medically imposed quarantine.</p>',
            },
            {
                region: 'UK',
                id: 'uk',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs. We strongly recommend you obtain travel insurance.</p>',
            },
            {
                region: 'Asia',
                id: 'asia',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs. We strongly recommend you obtain travel insurance.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Local currency equivalent of min $100,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Local currency equivalent of min $100,000 per person.</span></p><p>In case international travel insurance is not presently available in your country of residence, please investigate your options closer to the time of your departure.</p>',
            },
            {
                region: 'Australia',
                id: 'au',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs. We strongly recommend you obtain travel insurance. $brandName$ offers several insurance plans, or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>International: </strong></p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Min $150,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Min $150,000 per person.</span></p><p>Please note policies currently available in Australia have much higher limits for emergency evacuation/repatriation.</p><p><strong>Domestic travel within Australia: </strong></p><p><strong>Trip Interruption: </strong><span>Cost of trip including cost of flights</span></p><p class="KnowBeforeYouGoCard__italicText">Note: This is not required when travelling domestically or between Australia and New Zealand.</p>',
            },
            {
                region: 'New Zealand',
                id: 'nz',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs. We strongly recommend you obtain travel insurance. $brandName$ offers several insurance plans, or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>International: </strong></p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Min $150,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Min $150,000 per person.</span></p><p>Please note policies currently available in New Zealand have much higher limits for emergency evacuation/repatriation.</p><p><strong>Domestic travel within New Zealand: </strong></p><p><strong>Trip Interruption: </strong><span>Cost of trip including cost of flights</span></p><p class="KnowBeforeYouGoCard__italicText">Note: This is not required when travelling domestically or between Australia and New Zealand.</p>',
            },
        ],
    },
    Faq: {
        header: 'Frequently Asked Questions',
        description:
            'You and your trip are unique, but many pre-travel questions are the same. So, take a minute to browse our faqs to help you get going with ease.',
        footer: { header: 'Still have unanswered questions?', description: 'Get in touch or speak to one of our experts on the phone.' },
        CallUsButton: { prefix: 'or call us on' },
        questions: [
            {
                answer: "<p class='Faq__faqAnswerTitles'>Clothing:</p><p>The weather varies by region, proximity to the coast, altitude and time of year so we suggest that you pack layers of clothing that can be added or removed as necessary. A comfortable pair of flat shoes trainers for walking is essential and a light waterproof jacket may also be useful and a sun hat during the summer months. You may wish to bring a dressier outfit for a special night out. Smart casual clothes are acceptable attire for other evening activities.</p><p class='Faq__faqAnswerTitles'>Gadgets:</p><p>During your trip, you will be supplied with Vox Tour Guide Systems, which will let you hear your Travel Director or Local Specialist during highlight experiences while allowing you to walk at leisure at a comfortable distance. We supply a one Vox in-ear earphone, but also recommend you bring your own pair of headsets with an Auxiliary Audio Cable. This will ensure you have the sound quality which suits you, all while helping us reduce our single use plastic along the way.",
                id: 'pack',
                question: 'What should I pack?',
            },
            {
                answer: "<p><a href='https://packr.app/'>Packr</a> and <a href='https://www.packpnt.com/'>PackPoint</a> will give you great packing tips based on forecasted weather <a href='https://weather.com'>Weather Channel</a> or <a href='https://www.accuweather.com'>Accuweather</a> are the most popular weather apps to keep up to date with the local weather of the places you will go.</p><p><a href='https://www.duolingo.com/'>Duolingo</a> will have you covering off the basics of a local language for just 5 minutes a day <a href='https://translate.google.com/'>Google Translate</a> is great for quick translations.</p>",
                id: 'travelapps',
                question: 'What are the best travel apps to help me prepare for my trip?',
            },
            {
                answer: "<p>All your trip information will be provided in your e-Documents found in <a href='/dashboard'>MyCostsaver</a>. These are made available approximately 3 weeks prior to departure. Your Travel Director will be in touch a few days before your trip to introduce themselves and re-confirm your meeting point.</p>",
                id: 'meet',
                question: 'Where do I meet on Day 1?',
            },
            {
                answer: '<p>In Western Europe, Scandinavia, the USA, and Canada, it’s fine to drink the water from a fresh-supply tap. For other countries, your Travel Director will let you know if it’s okay. At Costsaver we’re about caring for our planet, and so recommend that you pack a reusable water bottle for your trip. Throughout the day, there will be multiple rest stops allowing time for a bottle refill or beverage purchase. In countries where the tap water is safe to drink, bringing your own reusable water bottle or asking for tap water at restaurants means you can make a difference by reducing your holiday plastic waste.</p>',
                id: 'water',
                question: 'Can I drink the water in the countries I visit?',
            },
            {
                answer: "<p>Optional experiences have been handcrafted by our destination experts to give you a choice to tailor your trip, your way, and discover even more of the places you’ll visit.  You can get a flavour of what is in store for you on the detailed Trip Itinerary section of <a href='/dashboard'>MyCostsaver</a> plus your Travel Director will also share all the options you on the first day of your trip.  No need to book or prepay, you can do this with your Travel Director on trip and payment can be made by credit card or cash. Simple!</p>",
                id: 'optional',
                question: 'What are Optional Experiences and when can I book them?',
            },
            {
                answer: "<p>Your luggage allocation is one suitcase to the maximum weight of 23kg (50lbs) and the dimensions of 76cm x 46cm x 25cm (30&quot; x 18&quot; x 10&quot;). You can also bring one item of hand luggage that is small and light enough to be carried onto the coach and fit underneath the seat, or in the small overhead compartment above (luggage with adjustable handles and wheels will not fit). Baggage allowances also vary from airline to airline and you should also check with your chosen carrier for details prior to travel.</p><p class='Faq__faqAnswerHighlight'>Porterage:</p><p>Costsaver offers unbeatable value, and to offer a wonderful experience at a great price – we cover all the essentials, while added services such as porterage are not included.</p><p>Your Travel Director and Driver will help unload and load luggage on and off the coach, but it is your responsibility to take your luggage from the coach to and from your room.</p>",
                id: 'luggage',
                question: 'Are there any luggage restrictions?',
            },
            {
                answer: "<p>We'll check you in, look after your luggage and take care of all hotel porterage, tips and gratuities – whether it is for restaurant staff, chambermaids or porters. Easy! If you do however feel that your passionate and dedicated Travel Director and Driver have helped enhance your trip – gratuities can be shared at the end of your trip directly with the team.</p>",
                id: 'included',
                question: 'Are tips included?',
            },
            {
                answer: "<p>You are responsible for obtaining all necessary visas prior to the departure. Depending on your nationality, you may need visas to enter certain countries included on your trip. Please note that obtaining visas can take up to six weeks and so it is strongly advised that you consult your Travel Agent and the local Consulate or Embassy of the country you are visiting well in advance to determine which visas they require and how to obtain them. You can also visit the <a href='https://visacentral.com/trafalgar'>VisaCentral</a> website for more information.</p>",
                id: 'visa',
                question: 'Will I need a visa?',
            },
            {
                answer: "<p>Most hotels do offer a laundry service and in some cities Laundromats are also available. However, it's sensible to try and pack enough clothing to avoid having to do laundry, as it can sometimes be costly or inconvenient.</p>",
                id: 'laundry ',
                question: 'Will I be able to get laundry done?',
            },
            {
                answer: "<p>No worries. Take a look at our extensive Know Before You Go guide <a href='https://www.costsavertour.com/en-gb/know-before-you-go'>here.</a></p>",
                id: 'moreQuestions',
                question: 'Still have more questions?',
            },
        ],
    },
    HelpBox: {
        CallUsButton: { prefix: 'or call us on' },
        questions: [
            { id: 'pack', question: 'What should I pack?' },
            { id: 'meet', question: 'Where should I meet on day 1?' },
            { id: 'optional', question: 'What are optional experiences and when can I book them?' },
            { id: 'allQuestions', question: "View all FAQ's" },
        ],
        header: 'NEED SOME HELP?',
    },
    Hero: {
        greeting: { guest: 'Welcome to', member: 'Welcome to MyCostsaver', prefix: 'hi' },
        alertMessage:
            "<strong>Travel Alert</strong> - COVID 19 - We are here and we want to help you. If you are concerned about your travel plans please <a href='https://www.costsavertour.com/coronavirus-travel-update' target='_blank'>click here</a>",
        healthAlertMessage: '',
    },
    HomeButton: { buttonLabel: 'Home' },
    HotelMeals: {
        hotelTitle: 'Overnight',
        mealTitle: 'Meals inc.',
        helpText: 'All hotels have been carefully selected to enhance your trip, but please note they are subject to change any time.',
    },
    Itinerary: { accordionHeading: 'Choose an itinerary day', dayTitle: 'Day' },
    ItineraryCard: { header: 'Journey Details', mapTitle: 'Trip Map', viewTripButton: 'VIEW YOUR JOURNEY' },
    BookingOverview: { header: 'Booking Details' },
    Sustainability: {
        Card: {
            header: 'Sustainability',
        },
    },
}
