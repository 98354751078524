import { UPDATE_ACTIVE_DOCUMENTS } from 'redux/actionTypes'
import { activeDocuments } from 'redux/defaultState'

const Documents = (state = activeDocuments, { type, payload }) => {
    switch (type) {
        case UPDATE_ACTIVE_DOCUMENTS: {
            return [...payload]
        }
        default: {
            return state
        }
    }
}

export default Documents
