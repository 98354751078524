import { AppConstant } from 'constants'
import Utils from './Utils'

const {
    TripRegistrationWizard: {
        travelProtectionPlans: { goldSeal },
    },
    TripPersonalisationWizard: { beddingOptionAvailable },
} = Utils.getConfigs()

const childPassenger = AppConstant.CHILD_PASSENGER

export default class BookingServices {
    static getErrors = err => {
        const { errors } = err || {}
        const { versionConflictError = false, confirmFlightError = false, lockedError = false, generalError = false, fieldErrors = null } = errors || {}

        return { versionConflictError, lockedError, generalError, fieldErrors, confirmFlightError }
    }

    static addProgressInfo = data => {
        const registrationFieldsMap = [
            ['phoneNumberWhileAway.internationalCallingCode', 'phoneNumberWhileAway.number', 'emailAddress'],
            [
                'emergencyContact.name',
                'emergencyContact.relationship',
                'emergencyContact.phoneNumber.internationalCallingCode',
                'emergencyContact.phoneNumber.number',
            ],
            [
                'passport.nationality',
                'dateOfBirth.year',
                'dateOfBirth.month',
                'dateOfBirth.date',
                'passport.number',
                'passport.countryOfIssue',
                'passport.issueDate.year',
                'passport.issueDate.month',
                'passport.issueDate.date',
                'passport.expiryDate.year',
                'passport.expiryDate.month',
                'passport.expiryDate.date',
            ],
            [
                'hasThirdPartyInsurance',
                'thirdPartyInsurance.policyNumber',
                'thirdPartyInsurance.companyName',
                'thirdPartyInsurance.phoneNumber.internationalCallingCode',
                'thirdPartyInsurance.phoneNumber.number',
            ],
        ]
        const calcRegistrationProgress = (paxRecord, fields, hideFields, index) => {
            const { showInsuranceWaiver } = data
            const { isDomesticTravelEnabled } = paxRecord
            if (index === 3 && !paxRecord.hasThirdPartyInsurance && showInsuranceWaiver && !paxRecord.insuranceWaiverFlag && !hideFields) {
                return 0
            }
            if (index === 2 && isDomesticTravelEnabled && paxRecord.passport.isTravellingDomestically) {
                return 25
            }
            let progress = 100 / registrationFieldsMap.length
            let checkInsuranceFields = true
            fields.forEach(field => {
                if (field === 'hasThirdPartyInsurance') {
                    if (hideFields) {
                        checkInsuranceFields = false
                    } else if (typeof paxRecord[field] !== 'boolean') {
                        progress = 0
                        checkInsuranceFields = false
                    } else if (!paxRecord[field]) {
                        checkInsuranceFields = false
                    }
                } else if (checkInsuranceFields) {
                    if (field.indexOf('.') >= 0) {
                        const [object, value, value2] = field.split('.')

                        if (typeof value2 === 'string') {
                            if (paxRecord[object] && paxRecord[object][value] && !paxRecord[object][value][value2]) {
                                progress = 0
                            }
                        } else if (paxRecord[object] && !paxRecord[object][value]) {
                            progress = 0
                        }
                    } else if (!paxRecord[field]) {
                        progress = 0
                    }
                }
            })
            return progress
        }
        const calcPreferenceProgress = (...args) => {
            let progress = 0

            const increment = 100 / args.length
            args.forEach(arg => {
                if (arg) {
                    progress += increment
                }
            })

            return Math.floor(progress)
        }

        const { passengers: paxes = [] } = data
        let paxesWithRegComplete = 0
        let paxesWithPrefComplete = 0
        let paxesWithBothRegAndPrefComplete = 0

        const passengers = paxes.map(pax => {
            const { specialRequests, travelPassion, beddingPreference, roomType, availableBeddingPreferences, additionalProducts = [] } = pax
            const travelProtection = additionalProducts.find(({ type }) => type === 'travelProtection') || {}
            const { name = '' } = travelProtection
            const hasProtection = !!name
            const isGoldSeal = hasProtection && !!travelProtection.name.match(new RegExp(goldSeal, 'ig'))
            let registrationProgress = 0
            registrationFieldsMap.forEach((fields, index) => {
                const updatedFields = fields
                if (pax.type === childPassenger && index === 0) {
                    updatedFields.pop()
                }
                registrationProgress += calcRegistrationProgress(pax, updatedFields, hasProtection && !isGoldSeal, index)
            })
            let preferenceProgress = 0
            if (roomType === 'twin' && !!availableBeddingPreferences.length && beddingOptionAvailable) {
                preferenceProgress = calcPreferenceProgress(beddingPreference, specialRequests, travelPassion)
            } else {
                preferenceProgress = calcPreferenceProgress(specialRequests, travelPassion)
            }

            const registrationComplete = registrationProgress === 100
            const preferenceComplete = preferenceProgress === 100

            paxesWithRegComplete += registrationComplete ? 1 : 0
            paxesWithPrefComplete += preferenceComplete ? 1 : 0
            paxesWithBothRegAndPrefComplete += registrationComplete && preferenceComplete ? 1 : 0

            return {
                ...pax,
                preferenceProgress,
                preferenceComplete,
                registrationProgress,
                registrationComplete,
            }
        })

        return {
            ...data,
            passengers,
            paxesWithBothRegAndPrefComplete,
            paxesWithRegComplete,
            paxesWithPrefComplete,
        }
    }
}
