export default {
    ADD_BOOKING: '/bookings/addbooking',
    GET_BOOKING_COMPONENTS: '/bookings/bookings',
    GET_BOOKING_DETAILS: '/bookings/bookingdetails',
    UPDATE_BOOKING_DETAILS: '/bookings/updatebookingdetails',
    GET_ITINERARY: '/itinerary/itineraries',
    GET_ITINERARY_FROM_TROPICS: '/itinerary/itinerariesfromtropics',
    GET_ITINERARY_DETAIL: '/itinerary/itinerarydetail',
    GET_EDOCS_DOCLIST: '/edocs/passengerdocumentlist',
    SIGNED_URL: '/edocs/signeddocumenturl',
    SSO_REGISTRATION: '/ssoregistration',
    PAIR_SSO: '/pairsso',
    BOOKING_BRAND: '/bookings/bookingbrands',
    USER_DETAIL: '/user/userdetail',
    LOGGED_IN_USER_INFO: '/user/loggedinuserinfo',
    FILE_UPLOADER: '/upload',
    GET_PRIO_ACCESS_TOKEN: '/prio/accesstoken',
    GET_DOCUMENT_TYPE: 'uniworld/documenttype',
    SET_DOCUMENT_TYPE: 'uniworld/setdocumenttype',
    GET_INVOICE: 'payments/getinvoice',
    PAYMENT_CONFIRMATION: '/payments/paymentconfirmation',
    VALIDATE_FLIGHT: '/travelextras/validateflight',
    ADD_FLIGHT: '/travelextras/addflight',
    GET_TRANSFERS: '/travelextras/gettransfer',
    GET_HOTELS: '/travelextras/gethotels',
    GET_GRATUITY: '/travelextras/getgratuity',
    ADD_PRODUCTS: '/travelextras/addproducts',
    CHECK_BOOKING_UPDATE_STATUS: '/travelextras/checkbookingupdatestatus',
    CALCULATE_INSURANCE: '/travelextras/calculateinsurance',
    SEARCH_FLIGHTS: '/travelextras/searchflights',
    UPDATE_BOOKING_IN_TROPICS: '/travelextras/updatebookingintropics',
    GET_AIRLINES: '/travelextras/getairlines',
    GET_AIRFARE_RULES: '/travelextras/airfarerules',
    GET_TRANSFER_FOR_AIR: '/travelextras/transferforair',
}
