import React from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import { withCard } from './CardContext'

const Footer = ({ componentName, className, themeName, children }) => {
    const classNames = Utils.removeDuplicates(...Utils.stringToArray(className))
    const parentClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentName, '__footer'), themeName, ...classNames)

    return (
        <div className="cell shrink">
            <footer className={parentClassName}>{children}</footer>
        </div>
    )
}

Footer.displayName = 'CardFooter'
Footer.propTypes = {
    componentName: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    themeName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
Footer.defaultProps = {
    className: '',
    children: null,
}

export default withCard(Footer)
