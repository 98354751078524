export default {
    MyBrand: {
        name: 'aatkings',
        displayName: 'AAT Kings Travel Portal',
        fullName: 'AAT Kings',
        mtpName: 'MyAATKings',
    },
    Accordion: { collapseAll: 'Collapse all', expandAll: 'Expand all' },
    AddMyBooking: {
        Card: { addButton: 'Add My Booking', header: 'Add your booked trip' },
        Form: {
            footer: 'Note: Your booking will only be available 24 hours after it has been confirmed.',
            header: '<p>To register and personalise your upcoming trip, add your booking to your My AAT Kings.</p><p><strong>Please enter your name exactly as it was given to My AAT Kings to make your booking. This is usually your name as it appears on your passport.</strong></p>',
            Fieldset: {
                title: 'Add My Booking Fields',
                fields: {
                    firstName: { placeholder: 'First Name', title: 'First Name' },
                    lastName: { placeholder: 'Last Name', title: 'Last Name' },
                    email: { placeholder: 'Email Address', title: 'Email Address' },
                    reference: { placeholder: 'Booking Reference', title: 'Booking Reference (eg. A123456)' },
                },
            },
        },
        AddMyBookingErrorMessages: {
            errorMessages: {
                ensureDetailsMatch:
                    "<p><ul class='AddMyBooking__errorMessagelist'><li>Please ensure your details match what was on your booking</li><li>Your booking has been confirmed</li><li>It has been 24 Hours since the booking was made</li></ul></p>",
            },
        },
    },
    App: { months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] },
    BookingsList: {
        bookingNameLabel: 'Booking Ref CH: ',
        pastBookingsHeader: 'Your past trips',
        upcomingBookingsHeader: 'Your upcoming trips',
        upcomingBookingsSubHeader:
            'All of your upcoming trips should be listed below, if a booking is missing click “Find my booking” to find and link it to your MyAATKings.',
        viewButtonLabel: 'View Details',
        noBookingsFound: 'No Bookings Found! Bookings will appear 24 hours after booking has been confirmed.',
    },
    ContactUsButton: { text: 'Contact Us' },
    CallUsButton: {
        defaultPhoneNumber: '01234567890',
        sellingCompanyToPhoneNumberMapping: {
            AASYDS: '1300 228 546',
            AACANS: '1-866-240-1659',
            AABEUS: '',
            AAAKLS: '0 800 456 100',
            AABAUS: '+ 44 20 8225 4220',
            AAUSAS: '1-866-240-1659',
            AAUKLS: '020 8225 4220',
            AAEUOS: '+49 (0) 89 544 1640',
            AAMUCS: '+49 (0) 89 544 1640',
        },
    },
    Countdown: { day: { plural: 'Days', singular: 'Day' }, over: 'We Miss You', toGo: 'To Go', trip: 'Trip' },
    EdocsCard: {
        descriptions: ['Enjoy the ease of seeing all of your travel information here or download these documents directly to your mobile device to view them.'],
        dropDownLabel: 'Select a guest',
        heading: 'Travel Documents',
        errorMessages: {
            regIncomplete: 'Please complete registration for $guestName$, we will need this information to be able to provide travel documents.',
            prefIncomplete: 'Please complete personalisation for $guestName$, we will need this information to be able to provide travel documents.',
            regAndPrefIncomplete:
                'Please complete personalisation and registration for $guestName$, we will need this information to be able to provide travel documents.',
            docsNotReady:
                'You have completed personalisation and registration for $guestName$, travel documents will be available 21 days prior to your departure.',
            docsNotFound: 'Your documents are not currently available, please check back later.',
        },
        InformationTile: {
            personalisation: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Personalise your trip',
                description: { incomplete: 'to customise your travel preferences', complete: 'to customise your travel preferences' },
            },
            registration: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Register your trip',
                description: { incomplete: 'to ensure an appropriate duty of care', complete: 'to ensure an appropriate duty of care' },
            },
        },
    },
    EdocsSubscriptionBox: {
        header: 'Your Travel Documents',
        paperDocumentsChoice: 'I want to travel with paper documents.',
        paperLessDocumentsChoice: 'I want to travel paper free and receive digital travel documents',
        buttons: { viewDocuments: 'View Edocuments', choosePaperless: 'Choose Paperless' },
        isDocumentAndPaperLess: { heading: 'Congratulations! You have selected e-Documents' },
        isDocumentWithPaper: { heading: "Oh no! Are you sure you don't want to save a tree and go Paperless?" },
    },
    TravelExtras: {
        benefitsText:
            'For your benefit and clarity, the total airfare shown includes airline imposed fuel surcharges and government taxes and fees. If you need more clarification, please see our <a href="https://www.aatkings.com/about-us/booking-conditions/" target="_blank">Terms and Conditions</a>',
    },
    EdocsList: {
        documentDescriptions: {
            allDocument: 'You can download all your documents in a single click.',
            Cologne_Train_Transfer:
                'Directions for meeting your AAT Kings Travel Director. Includes meeting location, Guided Holiday itinerary, and contact information.',
            Cruise_Companion:
                'Guidelines to help you get ready for your upcoming journey. It includes, valuable tips on what to pack, your flights and transfers as well as on-trip information.',
            Cruise_Ticket: 'Your cruise eTicket, booking details and important terms and conditions.',
            Custom_Planning_Voucher: 'Contact information for private transfers and hotels.',
            Docking_Change_Notification: 'Important notice containing the updated docking location address, map, and contact information.',
            Flight_Itinerary: 'If you have booked your airfares with AAT Kings you can view and download the attached PDF of your full flight information.',
            Hotel_List:
                'For peace of mind you can download a full list of your accommodation contact details and pass onto your friends and family should they need to get in contact with you.',
            Important_Transfer_Notice: 'Important notice that you have not taken advantage of our complete transfer options.',
            Schedule_At_A_Glance: 'For the best experience please view and download a PDF version of your upcoming Guided Holiday before your trip.',
            Tour_Itinerary: 'Discover your day to day trip breakdown, where to meet your group, luggage guidelines, optional experiences and more.',
            Travel_Protection_Plan: 'Summary of coverages and contact information.',
            Venice_Port_Map: 'Map of the Venice port containing Uniworld Docking locations.',
            Vouchers_For_Information: 'View your upcoming itinerary and booking details to ensure you are fully prepared for your upcoming Guided Holiday.',
            Vouchers_For_Printing:
                'To ensure you receive seamless travel please print the attached vouchers and bring them along with you. You will need to hand these over for airport transfers, optional experience and/or pre or post accommodation.',
            Whats_Next:
                "Where will you be going next? Get some travel inspiration with our AAT Kings blog for the best destinations and must see locations around Australia and New Zealand. <a href='https://www.aatkings.com/blog' target='_blank'>View our blog</a>",
        },
        downloadAllHeading: 'All documents',
        downloadAllBtn: 'Download All',
        downloadBtn: 'Download',
        voucherPrintMsg: 'This document must be printed',
    },
    Faq: {
        header: 'Frequently Asked Questions',
        description:
            'Please read the below pre-travel information. Not only will these insights help you to make well-informed travel decisions, but also ensure your trip is as effortless and enjoyable as possible.',
        covid19QuestionsTitle: '',
        covid19QuestionsDescription: '',
        footer: { header: 'Still have unanswered questions?', description: 'Get in touch or speak to one of our experts on the phone.' },
        CallUsButton: { prefix: 'or call us on' },
        questions: [
            {
                answer: "<p>The weather in Australia and New Zealand can be different depending on your destination, from the Tropical Top End to the far south of the South Island of New Zealand. We would suggest that you pack layers of clothing.</p><p>A light waterproof jacket may also be useful and we recommend a sun hat during the summer months, as the Australian sun can be very strong. A light down jacket can also be useful in Tasmania during the summer months as the weather can be extremely changeable.</p><p>Bear in mind that some excursions may have some walking on rocky terrain, for which we recommend appropriate footwear.</p><p>A comfortable pair of flat shoes /trainers for walking is essential as you may be visiting cities and sites with cobbled streets and uneven terrain.</p><p>Useful items that you may wish to pack can include: </p><ul class='Faq__faqAnswerlist'><li>Phone charger</li><li>Medication</li><li>Adaptor plug</li><li>Carry bag</li><li>Travel pillow</li><li>A photocopy of your hotel contact list & travel documents</li><li>A photograph of your luggage contents for reference</li></ul>",
                id: 'pack',
                question: 'What should I pack?',
            },
            {
                answer: '<p>We pride ourselves on having the finest fleet of fuel-efficient, fully air-conditioned coaches, all with reclining seats, seatbelts and restrooms. The high vantage point from your coach’s window means you can take in all the glorious scenery, whether it’s a vast rural landscape or an urban city centre. We also have onboard Wi-Fi.</p>',
                id: 'coach',
                question: 'What are the coaches like?',
            },
            {
                answer: '<p>All the information you need to join your trip, such as your first hotel and what time to get there, will be provided in your trip documents. These will be available approximately 3 weeks prior to your departure here in the MyAATKings Portal.</p>',
                id: 'meet',
                question: 'What time should I arrive on Day 1 on my Guided Holiday?',
            },
            {
                answer: '<p>AAT Kings and Inspiring Journeys itineraries include plenty of free time for you to explore destinations in your own way. Optional Experiences are a great way to make the most of your time and tailor your trip to your interests.</p><p>Available Optional Experiences are listed on the trip itinerary. No need to book or prepay, you can do this with your Travel Director on trip and payment can be made by credit card or cash.</p><p>The majority of Optional Experiences will be offered to you by your Travel Director at the beginning of your trip. Our optional experiences cannot be pre-booked. Some may be subject to change, depending on the time you are travelling or local circumstances including weather and days of the week. Further information will be provided by your Travel Director.</p>',
                id: 'optional',
                question: 'What are Optional Experiences and when can I book them?',
            },
            {
                answer: '<p><strong>Guided Holidays:</strong></p><p>You can bring one suitcase per person with the approximate dimensions of 76cm x 46cm x 25cm (30" x 18" x 10") and weight not exceeding 23 kg (50 lbs) due to the limited space on the coach. We suggest you have wheels on your suitcase, as there are certain places where assistance is not available (e.g. airports, railway stations and cruise terminals). You may also bring a backpack or day pack to take on the coach.</p><p>Passengers booked on a small group tour operated by Heritage Tours are only permitted to carry a small soft carry bag (30x30x70cm) with a maximum weight of 10kg, plus a small day pack.</p>',
                id: 'luggage',
                question: 'How much luggage can I bring?',
            },
            {
                answer: '<p>No, pre-paying gratuities is not required.</p><p>If you have been pleased with the services of your Journey Director and Driver, you may wish to express your satisfaction with a gratuity. As this is a matter of a private and individual nature your gratuity should be sealed in an envelope and given to your Journey Director and Driver at the end of your trip. A guideline of $6-$8 per person per day is suggested to tip your Journey Director and your Driver.</p>',
                id: 'gratuities',
                question: 'Do I have to purchase gratuities prior to the vacation?',
            },
            {
                answer: '<p><strong>AAT Kings and Inspiring Journeys Guided Holidays:</strong></p><p>Once registered on the MyAATKings registration portal, you’ll be able to unlock your travel documents approximately 3 weeks prior to departure. If you’ve booked through a travel agent, your documents will be emailed directly to your agent.</p><p><strong>AAT Kings Short Breaks and Day Tours:</strong></p><p>E-documents will be emailed to you within 24 hours of full payment being made.</p>',
                id: 'documents',
                question: 'How and when will I receive my travel documents?',
            },
            {
                answer: "<p>Yes. Flights are not included in the trip price but you can add flights during the booking process. Ask your travel agent to book flights as part of your booking. Or simply <a href='https://www.aatkings.com/about-us/contact-us' target='_blank'>Contact Us</a> with your preferred departure dates to request a flight quote.</p>",
                id: 'airfares',
                question: 'Can I book flights through AAT Kings or Inspiring Journeys?',
            },
            {
                answer: "<p>According to the UNWTO, 'sustainable tourism should make optimal use of environmental resources that constitute a key element in tourism development, maintaining essential ecological processes and helping to conserve natural heritage and biodiversity.'</p><p>With AAT Kings and Inspiring Journeys, you can be sure you'll be contributing to local sustainability through our initiatives that safeguard natural heritage, preserve biodiversity, and protect sites of cultural significance.</p>",
                id: 'conscious',
                question: 'How can I travel sustainably with AAT Kings and Inspiring Journeys?',
            },
            {
                answer: '<p>To allow everyone the opportunity to enjoy seats at the front of the coach, AAT Kings and Inspiring Journeys has a seat rotation policy on all <strong>Guided Holidays</strong>. This means that everyone changes seats daily during the tour and means that you can meet new people throughout your journey.</p><p>It is not possible to reserve seats on AAT Kings <strong>Short Breaks</strong> and <strong>Day Tours</strong>.</p>',
                id: 'reserve',
                question: 'Can I reserve a seat at the front of the coach?',
            },
            {
                answer: '<p>AAT Kings does not offer airport transfers for our <strong>Day Tours</strong>, however a public courtesy transfer is available upon arrival at Uluru Airport. For guests travelling on an <strong>AAT Kings Best Buys Guided Holiday</strong>, we recommend taking an Uber or Taxi upon arrival at the airport to your starting hotel. Airport Transfers are included on AAT Kings and Inspiring Journeys <strong>First Choice Guided Holidays</strong>. Please advise us of your flight details at least 6 weeks prior to departure.</p>',
                id: 'transfers',
                question: 'How can I add transfers to my booking?',
            },
            {
                answer: "<p>Please view our AAT Kings Frequently Asked Questions page on our website. <a href='https://www.aatkings.com/about-us/frequently-asked-questions' target='_blank'>Frequently Asked Questions</a> for Inspiring Journeys can be found <a href='https://inspiringjourneys.com/faq' target='_blank'>here</a>.</p>",
                id: 'moreQuestions',
                question: 'Still have more questions?',
            },
        ],
    },
    Footer: {
        copyright: 'Copyright $year$.',
        privacyPolicy: 'Privacy Policy',
        rightsReserved: 'All rights reserved.',
        termsAndCondition: 'Terms and Conditions',
    },
    Header: {
        CallUsButton: { suffix: 'or call your travel agent' },
        labelBrandSite: 'Back to Site',
        labelLogo: 'Home',
        labelLogoutMD: 'Log out of account',
        labelLogoutSM: 'Log Out',
        viewBasket: 'view Basket',
    },
    HelpBox: {
        CallUsButton: { prefix: 'or call us on' },
        questions: [
            { id: 'pack', question: 'What should I pack?' },
            { id: 'coach', question: 'What are the coaches like?' },
            { id: 'meet', question: 'What time should I arrive on Day 1 on my Guided Holiday?' },
            { id: 'allQuestions', question: "View all FAQ's" },
        ],
        header: 'Need some help?',
    },
    Hero: { greeting: { guest: 'Welcome to', member: 'Welcome to My AAT Kings', prefix: 'Hey' }, alertMessage: '', healthAlertMessage: '' },
    HomeButton: { buttonLabel: 'Home' },
    HotelMeals: {
        hotelTitle: 'Overnight',
        mealTitle: 'Meals inc.',
        helpText: 'All hotels have been carefully selected to enhance your trip, but please note they are subject to change any time.',
    },
    Itinerary: { accordionHeading: 'Select an Itinerary Day', dayTitle: 'Day' },
    ItineraryCard: { header: 'Trip Details', mapTitle: 'Trip Map', viewTripButton: 'View Trip' },
    Loader: { message: 'Loading...' },
    LoginForm: { emailAddress: 'Email Address', password: 'Password' },
    SignUpCard: {
        login: 'Login',
        signup: 'Sign Up',
        loginHeader: 'Login to',
        signUpHeader: 'Login to',
        brandText: '',
        haveAccountDescription: 'Register your details and personalise your AAT Kings or Inspiring Journeys Guided Holiday for total piece of mind.',
        description:
            'For AAT Kings Day Tours and Short Breaks, please call our <a target="_blank" href="//www.aatkings.com/about-us/contact-us/" >Contact Centre</a>',
        firstname: 'First Name',
        lastname: 'Last Name',
        emailAddress: 'Email Address',
        passWord: 'Password',
    },
    MmbRegistration: { registerText: 'To access Manage My Booking version 1.0 ', clickHere: 'Click Here' },
    Modal: { closeBtn: 'close' },
    Navigation: {
        navigationList: {
            Edocs: 'Download your documents',
            Faq: 'Help',
            Itinerary: 'View your trip itinerary',
            TripPersonalisation: 'Personalise your trip',
            TripRegistration: 'Register your details',
        },
    },
    NotFound: { subHeading: "Oh no! You've hopped onto a page that doesn't exist.", message: 'Bounce back to our homepage by clicking the link below:' },
    Optional: {
        heading: 'Optional Experiences',
        helpText:
            'These special experiences have been hand picked to enhance your trip. Your Travel Director will arrange booking/s and payment directly with you on your trip. Please note that all experiences are indicative only and may be withdrawn or changed at any time.',
        priceForAdult: 'Adult price:',
        priceForChild: 'Child price:',
        viewDetail: 'View Details',
    },
    TripPersonalisationWizard: {
        Buttons: { labels: { back: 'Back', complete: 'Next Guest', next: 'Next', submit: 'Submit', skip: 'Complete Later' } },
        Header: { multiPax: 'Personalise Your Trip for $guestName$', singlePax: 'Personalise Your Trip' },
        requiredHint: '* Required',
        errorMessages: {
            unableToSave: 'Unable to edit your information at this time, please try again later.',
            beddingPreference: 'You must select a bedding preference to complete personalisation.',
        },
        Steps: {
            PersonaliseDetails: {
                beddingOptions: {
                    heading: 'Bedding Options',
                    description: 'Please choose your bedding configuration preference',
                    Fieldset: {
                        description: 'You will be sharing with $guestName$. What bedding option would you prefer?',
                        fields: { doubleBed: 'Double Bed', twinBed: 'Twin Beds' },
                        title: 'Bedding Options Fields',
                    },
                },
                addtionalInformation: {
                    heading: 'Additional information',
                    Fieldset: {
                        personalRequest: {
                            description: 'Are you celebrating a special occasion?',
                            fields: { comment: { placeholder: '', title: 'Add comment' } },
                            title: 'Personal requests',
                        },
                        travelPassion: {
                            description: "What's one thing you're looking forward to on your upcoming Guided Holiday?",
                            fields: { comment: { placeholder: '', title: 'Add comment' } },
                            title: 'Your travel passion',
                        },
                    },
                },
            },
            PersonalisationSuccess: {
                heading: {
                    complete: {
                        both: 'Congratulations you have finished registration and personalisation for $guestName$.',
                        self: 'You have completed personalisation for $guestName$, but still need to complete registration.',
                    },
                    incomplete: {
                        both: 'You have not completed personalisation section for $guestName$.',
                        self: 'You have not completed personalisation section for $guestName$.',
                    },
                },
                description: {
                    complete: { both: 'Get ready to enjoy the trip of a life time!', self: 'Please click the button below to enter your details.' },
                    incomplete: { both: 'Please come back here anytime to finish it.', self: 'Please come back here anytime to finish it.' },
                },
                InformationTile: {
                    registration: {
                        button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                        heading: 'Register your details',
                        description: { incomplete: 'to ensure an appropriate duty of care', complete: 'to ensure an appropriate duty of care' },
                    },
                    documents: {
                        button: { incomplete: 'View Documents', complete: 'View Documents' },
                        heading: 'Travel Documents',
                        description: {
                            incomplete: 'Documents will be ready 21 days prior to your departure',
                            complete: 'Your travel documents are ready',
                        },
                    },
                },
            },
        },
        SubHeader:
            'Complete your trip preferences and we will share them with your Travel Director. Every effort will be made to accommodate all requests, however do note some options may not be available during your trip.',
    },
    TripRegistrationWizard: {
        Buttons: {
            labels: {
                back: 'Back',
                complete: 'Next Guest',
                edit: 'Make Changes',
                next: 'Next',
                submit: 'Submit',
                skip: 'Complete Later',
                view: 'View Documents',
            },
        },
        Header: { multiPax: 'Trip Registration for $guestName$', singlePax: 'Trip Registration' },
        requiredHint: '* Required',
        Steps: {
            ContactDetails: {
                heading: 'On Trip Contact Details',
                description: 'In the unlikely event of an incident, we will use these details to contact you.',
                Fieldset: {
                    fields: {
                        emailAddress: { placeholder: 'Email address', title: 'Email address' },
                        phoneNumber: { placeholder: { internationalCallingCode: 'Select', number: 'Contact number' }, title: 'Contact number' },
                    },
                    title: 'Trip Contact Details Fields',
                },
            },
            EmergencyContact: {
                heading: 'Emergency Contact',
                description: 'In the unlikely event of an incident, we will contact the person nominated, this person must not be on the trip with you.',
                Fieldset: {
                    fields: {
                        name: { placeholder: 'Name', title: 'Name' },
                        phoneNumber: { placeholder: { internationalCallingCode: 'Pick', number: 'Contact number' }, title: 'Contact number' },
                        relationship: { placeholder: 'Relationship', title: 'Relationship' },
                    },
                    title: 'Emergency Contact Fields',
                },
            },
            PassportDetails: {
                Callout: {
                    messageForCheckPassportExpiry:
                        'For our international travellers, please be advised that Australia and New Zealand require that passports be valid for a minimum of 6 months after the completion of your trip. Please check the local consulate for passport validity requirements for your trip.',
                },
                Fieldset: {
                    fields: {
                        countryOfIssue: { placeholder: 'Country of Issue', title: 'Country of Issue' },
                        dateOfBirth: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Date of Birth *' },
                        expiryDate: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Expiry Date *' },
                        issueDate: { placeholder: { date: 'DD', month: 'MM', year: 'YYYY' }, title: 'Date of issue *' },
                        nationality: { placeholder: 'Nationality', title: 'Nationality' },
                        number: { placeholder: 'Passport number', title: 'Passport number *' },
                    },
                    title: 'Passport Details Fields',
                },
            },
            RegistrationSuccess: {
                heading: {
                    complete: {
                        both: 'Congratulations you have finished registration and personalisation for $guestName$.',
                        self: 'You have completed registration for $guestName$, but still need to complete personalisation.',
                    },
                    incomplete: {
                        both: 'You have not completed registration section for $guestName$.',
                        self: 'You have not completed registration section for $guestName$.',
                    },
                },
                description: {
                    complete: { both: 'Get ready to enjoy the trip of a life time!', self: 'Please click the button below to enter your details.' },
                    incomplete: { both: 'Please come back here anytime to finish it.', self: 'Please come back here anytime to finish it.' },
                },
                InformationTile: {
                    personalisation: {
                        button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                        heading: 'Personalise your trip',
                        description: { incomplete: 'to customise your travel preferences', complete: 'to customise your travel preferences' },
                    },
                    documents: {
                        button: { incomplete: 'View Documents', complete: 'View Documents' },
                        heading: 'Travel Documents',
                        description: {
                            incomplete: 'E-Documents will be ready to download 21 days prior to your departure',
                            complete: 'Your travel documents are ready',
                        },
                    },
                },
            },
            TravelInsurance: {
                heading: 'Travel Insurance',
                description: 'To ensure your peace of mind while travelling, it is essential that you have travel insurance before you depart.',
                Callout: { messageForTravelProtection: 'You have purchased $name$ with us, however this is not Travel Insurance.' },
                Fieldset: {
                    insurance: { fields: { insuranceStatusNo: "No I don't", insuranceStatusYes: 'Yes I do' }, title: 'Do you have travel insurance?' },
                    policy: {
                        fields: {
                            companyName: { placeholder: 'Company name', title: 'Company name' },
                            phoneNumber: {
                                placeholder: { internationalCallingCode: 'Select', number: 'Company contact number' },
                                title: 'Company contact number',
                            },
                            policyNumber: { placeholder: 'Policy number', title: 'Policy number' },
                        },
                        title: 'Policy Details',
                    },
                },
                travelProtectionInfo: { heading: 'Travel protection for $guestName$', description: 'Valid dates: $startDate$ - $endDate$' },
            },
        },
        SubHeader: 'For your peace of mind, we require the following information to ensure an appropriate duty of care while you are on your trip.',
    },
    TripSchedule: { tripEndHeader: 'End', tripStartHeader: 'Start' },
    TripRegistrationCard: {
        alert: { singleItem: '1 item requires your attention', multipleItems: '2 items require your attention' },
        header: 'Trip Registration',
        subHeader: 'Your important information',
        guestTraveller: {
            header: 'Your Guest Traveller Information',
            description: '($completedGuests$ of $totalGuests$ completed)',
            detailNotComplete: 'Please complete the trip registration and personalisation for all guests on your booking',
        },
        InformationTile: {
            personalisation: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Personalise your trip',
                description: { incomplete: 'to customise your travel preferences', complete: 'to customise your travel preferences' },
            },
            registration: {
                button: { incomplete: 'Complete Now', complete: 'Make Changes' },
                heading: 'Register your details',
                description: { incomplete: 'to ensure your trip runs smoothly', complete: 'to ensure your trip runs smoothly' },
            },
        },
    },
    MultiGuest: {
        header: 'Guests',
        titleHeading: 'Your Information',
        edit: 'Edit',
        TripPersonalisation: {
            subHeading: '$completed$ / $total$ personalisations complete',
            progress: { incomplete: 'Personalisation Progress', complete: 'Personalisation Complete' },
        },
        TripRegistration: {
            subHeading: '$completed$ / $total$ registrations complete',
            progress: { incomplete: 'Registration Progress', complete: 'Registration Complete' },
        },
    },
    ValidationMessages: {
        minLength: { characters: 'Please enter minimum $length$ characters', numbers: 'Please enter minimum $length$ numbers' },
        maxLength: { characters: 'Please enter no more than $length$ characters', numbers: 'Please enter no more than $length$ numbers' },
        numberOnly: "Can't type in letters",
        alphaNumericOnly: 'Can not type in special characters',
        emailFormat: 'Please enter a valid email address',
        phoneFormat: 'Please enter a valid phone number',
        dateFormat: 'Invalid date',
        required: 'This field is required',
    },
    Sustainability: {
        Card: {
            header: 'Join AAT Kings',
            brandPledgeInfoButtonText: 'Find out more about AAT Kings Cares',
            ourStoryText:
                '<p>Twelve years ago, our parent company The Travel Corporation (TTC), asked an important question: how do we make travel a force for good?</p><p>Travel is an incredible gift. It can open our eyes, our hearts and our minds to the unique cultures and spellbinding beauty of the natural world. But with this gift comes a responsibility – to protect the world as we know it.</p><p>The TreadRight Foundation is a non-profit created to ensure that the impact we have on the people and wildlife we visit, and the planet we call home, is a positive one.</p>',
        },
    },
}
