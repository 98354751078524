export default {
    Accordion: {
        collapseAll: 'Collapse all',
        expandAll: 'Expand all',
    },
    Amb: {
        heading: 'Can’t find your booking?',
        description: 'New bookings are only visible 24 hours after a booking has been confirmed.',
        buttonText: 'Find My Booking',
        modalTitle: 'Find your booking',
    },
    StandAloneHotels: {
        heading: 'Extra Nights',
        startDate: 'Start Date:',
        endDate: 'End Date:',
        nights: 'Nights:',
        roomType: 'Room Type:',
        hotelName: 'Hotel Name:',
        noOfRooms: 'Number of Rooms:',
        guests: 'Guest(s):',
        status: 'Status:',
    },
    Gratuity: {
        title: 'Prepaid Gratuities',
        desc: 'Pay Service in advance',
        add: 'ADD',
        remove: 'REMOVE',
        paxText: 'Guest(s)',
        addGratuity: 'Add Prepaid Gratuities',
        helpText: 'These include gratuities for your Travel Director and Driver',
        passText: 'Guest(s):',
        gratuityDesc: "Don't miss out on showing your appreciation for exceptional service, add prepaid gratuities to your trip today.",
    },
    AddMyBooking: {
        Card: {
            addButton: 'Find my booking',
            header: 'Add your booked trip',
        },
        Form: {
            footer: 'Note: Your booking will only be available 24 hours after it has been confirmed.',
            header: '<p>To register and personalise your upcoming trip, add your booking to your MyTrafalgar.</p><p><strong>Please enter your name exactly as it was given to $brand$ to make your booking.  This is usually your name as it appears on your passport.</strong></p>',
            Fieldset: {
                title: 'Add My Booking Fields',
                fields: {
                    firstName: {
                        placeholder: 'First Name',
                        title: 'First Name',
                    },
                    lastName: {
                        placeholder: 'Last Name',
                        title: 'Last Name',
                    },
                    email: {
                        placeholder: 'Email Address',
                        title: 'Email Address',
                    },
                    reference: {
                        placeholder: 'Booking Reference',
                        title: 'Booking Reference (eg. A123456)',
                    },
                },
            },
        },
        AddMyBookingErrorMessages: {
            backButton: 'Back',
            modalHeader: "Can't Find Your Booking?",
            errorMessages: {
                unableToFindBooking: 'Sorry, we were unable to find your booking',
                ensureDetailsMatch:
                    "<p><ul class='AddMyBooking__errorMessagelist'><li>Please note you cannot add a suspended trip booking, please <a href='https://www.trafalgar.com/en-gb/forms/contact-us' target='_blank'>contact us</a> to discuss your options</li><li>Please ensure your details match what was on your booking</li><li>Your booking has been confirmed</li><li>It has been 24 Hours since the booking was made</li></ul></p>",
            },
        },
    },
    AmaedusFlightDetail: {
        departureLabelText: 'Departure Airport',
        arrivalLabelText: 'Arrival Airport',
        placeholderText: 'Enter code or name',
        departureTimeText: 'DEPARTURE TIME*',
        outBoundDateErrorMessage: 'Please select a date before tour starts',
        inBoundDateErrorMessage: 'Please select a date after the tour ends',
        tourstartDateOverlappingHeading: 'Selected date is the same as the tour start date.',
        tourendDateOverlappingHeading: 'Selected date is the same as the tour end date.',
        tourstartDateOverlappingDescriptionArrival: 'Please check the <a href="javascript:onTripNotesClick()">trip notes</a> for any flight restrictions.',
        tourstartDateOverlappingDescriptionDeparture:
            'Please read the <a href="javascript:onTripNotesClick()">trip notes</a> to ensure you do not arrive after your tour starts.',
    },
    AvailableProducts: {
        date: 'Date:',
        passengers: 'Guest(s):',
        total: 'Total',
        add: 'Add',
        remove: 'Remove',
        nightHeading: 'Extra Nights',
        transfer: 'transfer',
        flightsText: 'flights',
        flightsBooked: 'Flights',
        hotel: 'hotel',
        before: 'before',
        after: 'after',
        transferText: 'Add airport transfers to your flights',
        transferBookedText: 'Transfers already booked',
        removeHeading: {
            transfer: 'Are you sure you want to remove this transfer?',
            hotel: 'Are you sure you want to remove this accommodation?',
            gratuity: 'Are you sure you want to remove gratuities?',
            flightsWithOutTransfer: 'Are you sure you want to remove this flight?',
            flights: 'Are you sure you want to remove this flight? This will also remove any transfers.',
        },
        yes: 'YES',
        no: 'NO',
        priceText: 'The travel protection price might be adjusted upon check out due to increased value of booking.',
    },
    RoomCombinationSelector: {
        notFoundMessage:
            'We were not able to find available room combinations for your selection. Please update the selection of guests or nights, or contact the contact center.',
    },
    Payment: {
        trips: 'My Trips',
        home: 'Home',
        pageTitle: 'Payment',
        day: 'days',
        night: 'nights',
        roomText: 'room',
        amountDue: 'The remaining amount of $amount$ is due on $date$',
        dates: 'Dates:',
        accomodation: 'Accommodation:',
        paxText: 'Guest(s):',
        payDepositHeader: 'Pay only a deposit',
        payChoosenAmount: 'Pay chosen amount',
        payInsuranceAmount: 'Pay insurance difference',
        payFlightAmount: 'Pay flight difference',
        payFullHeader: 'Pay in full',
        changeAmountLinkText: 'Change amount',
        modal: {
            title: 'Change payment amount',
            description:
                'Your booking will only be available 24 hours after it has been confirmed. The name you enter on this form must match the name used in your booking details.',
            remainingBalance: 'Remaining Balance',
            updateAmountBtnTxt: 'update payment amount',
            placeHolder: 'Enter Amount',
            requiredFieldMessage: 'Required',
            validAmountMessage: 'Please enter a valid amount',
            minimumPaymentAmountMessage: 'Minimum payment is 10',
            maxAmountExceedMessage: 'You have entered over the maximum amount',
        },
    },
    PaymentConfirmation: {
        boookingOverview: 'Booking Overview',
        pageTitle: 'Confirmation',
        cardTitle: 'Success!',
        home: 'Home',
        successMessage: 'Your payment has been processed successfully!',
        successMessageForDirectTransfer: 'Thank you. We have received notification of your payment advice.',
        paymentReceived: 'Payment Received',
        paymentReceivedForDirectTransfer: 'Payment Advice Received',
        nextDueAmountHeading: 'Next Due Payment AMount',
        balanceDue: 'Balance Remaining',
        fullyPaid: 'Fully Paid',
        callOutHeader: 'PAYMENT FAILED! Please try again.',
        dueBy: 'Due by',
        finalPaymentDueBy: 'Final payment due by',
        viewBookingBtnText: 'View My Booking',
        cardText: 'xxxx-$card$',
        paymentMethodsForGatewayText: {
            paypal: 'PAYPAL',
            paywithgoogle: 'GOOGLE PAY',
            ach: 'ACH DIRECT DEBIT',
            applepay: 'APPLE PAY',
            banktransfer: 'DIRECT DEPOSIT',
        },
        toolTipText: 'Credit card provider fees are not included',
    },
    NoTransfersAvailable: {
        noTransfersAvailable: "There aren't any transfers available for your selected flights.",
        heading: 'NO TRANSFERS AVAILABLE',
    },
    IOFlightConfirmation: {
        pageTitle: 'IO Flights Confirmation',
        cardTitle: 'Success!',
        successMessage: 'Flight details added to your booking',
        viewBookingBtnText: 'View My Booking',
        transferText: 'Add airport transfers to your flights',
        travelUpgradeText: 'GO TO TRAVEL EXTRAS',
        travelBasketText: 'Go To Travel Basket',
        basketSummaryText: 'Basket Summary',
        addFlightDetails: 'Add flight details',
        addAnotherFlightDetails: 'Add Another Flights Details',
        flightText: 'Now you can add flights to other Guests.',
        noAddTransferText:
            'If you move away from this page your changes will be lost, you will not be able to add transfers again. Please add to the basket or leave without saving.',
    },
    ExtraNightConfirmation: {
        addExtraNights: 'ADD EXTRA NIGHTS',
        cardTitle: 'EXTRA NIGHTS ADDED TO BASKET',
        successMessage: 'Your Extra Nights was added to Your Basket. Now you can add Flight Details.',
        searchAndBuyFlights: 'Search And Buy Flights',
        travelUpgradeText: 'GO TO TRAVEL EXTRAS',
        travelBasketText: 'Go To Travel Basket',
    },
    AirConfirmation: {
        addAir: 'SEARCH AND BUY FLIGHTS',
        cardTitle: 'FLIGHTS ADDED TO BASKET',
        successMessage: 'Your Flights were successfully added to Your Basket. Now you can add flights to other guests.',
        addAirForOtherPax: 'Search and Buy flights For Other Guests',
        travelUpgradeText: 'Go To Travel Extras',
        travelBasketText: 'Go To Travel Basket',
        flightsText: 'flights',
    },
    AirProduct: {
        airProductName: 'AirProduct',
    },
    GratuityConfirmation: {
        addPrepaidGratuity: 'ADD PREPAID GRATUTIES',
        cardTitle: 'PREPAID GRATUITIES ADDED TO BASKET',
        successMessage: 'Your Prepaid Gratuities were successfully added to Your Basket. Now you can add gratuities to other guests.',
        AddGratuityForOtherPax: 'Add Prepaid Gratuities For Other Guests',
        travelUpgradeText: 'GO TO TRAVEL EXTRAS',
        travelBasketText: 'Go To Travel Basket',
    },
    AirfareCart: {
        heading: 'Flight Details',
        airfare: 'Airfare',
        taxes: 'Taxes and Surcharges',
        promotions: 'Promotion',
        total: 'Total Including Promotion',
        exempted: 'Exempt',
    },
    Cart: {
        basketSummaryText: 'Basket Summary',
        cartHeading: 'Here you will find a summary of the extra items you have selected for your travel.',
        cartSubHeading: 'You can go to the Travel Basket and add the items to your booking, or remove them if you have changed your mind.',
        transferHeading: 'Transfers',
        total: 'Total',
        travelBasketText: 'Go To Travel Basket',
        transfer: 'transfer',
        flights: 'flights',
        hotel: 'hotel',
        before: 'before',
        after: 'after',
        gratuities: 'gratuities',
        beforeTrip: 'Before the Trip:',
        afterTrip: 'After the Trip:',
        prepaidGratuities: 'Prepaid Gratuities',
    },
    IOFlightSection: {
        showDetails: 'Show Flight Details',
        hideDetails: 'Hide Flight Details',
    },
    App: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    },
    DataError: {
        message:
            '$brand$ is experiencing an outage at this time which is preventing guests from accessing their bookings.  Please try again later. We are sorry for the inconvenience.',
    },
    BookingsList: {
        bookingNameLabel: 'Booking Ref: ',
        pastBookingsHeader: 'Your completed trips',
        upcomingBookingsHeader: 'Your upcoming trips',
        upcomingBookingsSubHeader:
            'All of your upcoming trips should be listed below, if a booking is missing click “Find my booking” to find and link it to your My Trafalgar.',
        viewButtonLabel: 'View Trip Details',
        noBookingsFound: 'No Bookings Found! Your bookings will appear 24 hours after it has been confirmed.',
        uniworldFutureTripNotAvailable: 'This trip is not yet available to view.',
        uniworldPastTripNotAvailable: 'Details for this trip are no longer available.',
    },
    CallUsButton: {
        defaultPhoneNumber: '01234567890',
    },
    ContactUsButton: {
        text: 'Contact Us',
    },
    Countdown: {
        day: {
            plural: 'Days',
            singular: 'Day',
        },
        over: 'We Miss You',
        toGo: 'To Go',
        trip: 'Trip',
        expires: 'Expires',
    },
    EdocsCard: {
        descriptions: [
            'Enjoy the ease of seeing all of your travel information here or download these documents directly to your mobile device to view them any time & any place even when you are without wifi. Easy!',
        ],
        dropDownLabel: 'Select a guest',
        heading: 'Travel Documents',
        errorMessages: {
            regIncomplete: 'Please complete registration for $guestName$ and documents will be available 21 days prior to your departure.',
            prefIncomplete: 'Please complete personalisation for $guestName$ and documents will be available 21 days prior to your departure.',
            regAndPrefIncomplete:
                'Please complete personalisation and registration for $guestName$ and documents will be available 21 days prior to your departure.',
            docsNotReady:
                'You have completed personalisation and registration for $guestName$, travel documents will be available 21 days prior to your departure.',
            docsNotFound: 'Your documents are not currently available, please check back later.',
        },
        InformationTile: {
            personalisation: {
                button: {
                    incomplete: 'Complete Now',
                    complete: 'Make Changes',
                },
                heading: 'Personalise your trip',
                description: {
                    incomplete: 'to customise your travel preferences',
                    complete: 'to customise your travel preferences',
                },
            },
            registration: {
                button: {
                    incomplete: 'Complete Now',
                    complete: 'Make Changes',
                },
                heading: 'Register your details',
                description: {
                    incomplete: 'to ensure an appropriate duty of care',
                    complete: 'to ensure an appropriate duty of care',
                },
            },
        },
    },
    EdocsList: {
        documentDescriptions: {
            allDocument: 'You can download all of your documents for this guest in a single click.',
            Cologne_Train_Transfer:
                'Directions for meeting your transfer at the Cologne Train Station. Includes meeting location, train station map, and contact information.',
            Cruise_Companion:
                'Guidelines to help you get ready for you upcoming trip. It contains, valuable tips on what to pack, your flight and transfer experience, onboard information and much more.',
            Cruise_Ticket: 'Your cruise eTicket and booking details plus important terms and conditions.',
            Custom_Planning_Voucher: 'Contact information for private transfers and hotels.',
            Docking_Change_Notification: 'Important notice containing the updated docking location address, map, and contact information.',
            Flight_Itinerary: 'Contains your flight times, departure and arrival airports and terminal details for you to travel effortlessly.',
            Hotel_List: "Discover where you'll be settling in every night. Share this information with friends and family in case of emergencies.",
            Important_Transfer_Notice: 'Important notice that you have not taken advantage of our complete transfer options.',
            Other: 'Please download this document for your records.',
            Schedule_At_A_Glance: 'A broad overview of your daily cruise itinerary.',
            Tour_Itinerary: 'Discover your day to day trip breakdown, where to meet your group, luggage guidelines, optional experiences and more.',
            Travel_Protection_Plan: 'Summary of coverages and contact information.',
            Venice_Port_Map: 'Map of the Venice port containing Uniworld Docking locations.',
            Vouchers_For_Information: 'For the best experience please ensure you read over these before your trip.',
            Vouchers_For_Printing: 'Bring these documents along and keep them handy at hotels and for transfers along the way.',
            Whats_Next: 'Where will you be going next? Let us help you plan your next trip with My Wishlist: start your wish list, get quotes and more…',
        },
        downloadAllHeading: 'All documents',
        downloadAllBtn: 'Download All',
        downloadBtn: 'Download',
        voucherPrintMsg: 'This document must be printed',
    },
    WarningModal: {
        submit: 'Submit',
        next: 'Next',
        message: 'If you move away from this page your changes will be lost, please click $buttonName$ to save your changes.',
        insuranceIncompleteMessage:
            "Please select go back to provide us with this guest's insurance details. Guests without insurance will need to register and accept an insurance waiver on their own behalf to be able to join their trip.",
        insuranceIncompleteMessageLead:
            'You have not completed your insurance registration information. You will need to sign an insurance waiver to join your trip if you do not have insurance.',
        insuranceIncompleteMessageChild:
            'You have not completed Insurance registration information for this guest. If they do not have insurance, you will need to sign an insurance waiver on their behalf for them to be able to join their trip.',
        buttons: {
            continue: 'Continue',
            back: 'Go back',
        },
    },
    ErrorModal: {
        message:
            '$brand$ is experiencing an outage at this time which is preventing guests from accessing their bookings.  Please try again later. We are sorry for the inconvenience.',
        bookingLockedMessage: 'Your booking is currently being worked on by one of our team, please check back shortly.',
        buttonText: 'Close',
        viewBooking: 'View Bookings',
    },
    insuranceInformation: {
        title: 'Insurance',
        message:
            'Unexpected events can happen while travelling, Includind exposure to COVID-19. Which can bring additinal risks and cost. We strongly recommend you obtain travel insurance.',
        selectText: 'Please select your region from the list below to see the minimum requirements for travel insurance:',
        regions: [
            {
                region: 'USA',
                id: 'usa',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs.  We strongly recommend you obtain travel insurance. $brandName$ offers several insurance plans, or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Up to $25,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Up to $100,000 per person.</span></p><p>Coverages should be inclusive of COVID related illnesses or medically imposed quarantine.</p>',
            },
            {
                region: 'CAN',
                id: 'can',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when travelling, including exposure to Covid-19, which can bring additional personal risks and costs.  We strongly recommend you obtain travel insurance. $brandName$ recommends both Travel Protection and Covid Protection plans through Manulife Insurance (1.866.298.2722 Reference ID: TRAVCOR), or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Up to $50,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Up to $200,000 per person.</span></p><p>Coverages should be inclusive of COVID related illnesses or medically imposed quarantine.</p>',
            },
            {
                region: 'South Africa',
                id: 'sa',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs.  We strongly recommend you obtain travel insurance. $brandName$ offers several insurance plans, or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Up to R3000 per person per day.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Up to R1,000,000 per person.</span></p><p>Coverages should be inclusive of COVID related illnesses or medically imposed quarantine.</p>',
            },
            {
                region: 'UK',
                id: 'uk',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs. We strongly recommend you obtain travel insurance.</p>',
            },
            {
                region: 'Asia',
                id: 'asia',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs. We strongly recommend you obtain travel insurance.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Local currency equivalent of min $100,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Local currency equivalent of min $100,000 per person.</span></p><p>In case international travel insurance is not presently available in your country of residence, please investigate your options closer to the time of your departure.</p>',
            },
            {
                region: 'Australia',
                id: 'au',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs. We strongly recommend you obtain travel insurance. $brandName$ offers several insurance plans, or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>International: </strong></p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Min $150,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Min $150,000 per person.</span></p><p>Please note policies currently available in Australia have much higher limits for emergency evacuation/repatriation.</p><p><strong>Domestic travel within Australia: </strong></p><p><strong>Trip Interruption: </strong><span>Cost of trip including cost of flights</span></p><p class="KnowBeforeYouGoCard__italicText">Note: This is not required when travelling domestically or between Australia and New Zealand.</p>',
            },
            {
                region: 'New Zealand',
                id: 'nz',
                description:
                    '<p class="KnowBeforeYouGoCard__acknowledgeText">I acknowledge having been strongly advised to get travel insurance:</p><p>Unexpected events can happen when traveling, including exposure to Covid-19, which can bring additional personal risks and costs. We strongly recommend you obtain travel insurance. $brandName$ offers several insurance plans, or you can choose to get travel insurance from another provider.</p><p>It is recommended that the plan cover at least the following categories and minimum levels of coverage:</p><p><strong>International: </strong></p><p><strong>Trip Interruption: </strong><span> Cost of trip including cost of flights.</span></p><p><strong>Medical Expense: </strong><span> Min $150,000 per person.</span></p><p><strong>Emergency Evacuation/Repatriation: </strong><span> Min $150,000 per person.</span></p><p>Please note policies currently available in New Zealand have much higher limits for emergency evacuation/repatriation.</p><p><strong>Domestic travel within New Zealand: </strong></p><p><strong>Trip Interruption: </strong><span>Cost of trip including cost of flights</span></p><p class="KnowBeforeYouGoCard__italicText">Note: This is not required when travelling domestically or between Australia and New Zealand.</p>',
            },
        ],
    },
    LoginFaq: {
        header: 'Login/Sign Up FAQs',
        description:
            'Please have a read of the below pre-travel information. Not only with these insights help you to make well-informed travel decisions, but also ensure your trip is as effortless and enjoyable as possible.',
        footer: {
            header: 'Still have unanswered questions?',
            description: 'Get in touch or speak to one of our experts on the phone.',
        },
        questions: [
            {
                answer: "<p>Sign up for an account. If your email has already been used to set up an account, you will get a message stating 'Email already used'.  If so, navigate back to Sign In page and choose 'Reset My Password'</p>",
                id: 'unsureHaveAccount',
                question: 'I am unsure whether I already have an account.',
            },
            {
                answer: "<p>Click on 'Forgot Your Password' under Login. Enter the email address you used to set up your account.  An email will be sent to you with a link to reset your password. Please check your junk/spam folders.</p>",
                id: 'forgotPassword',
                question: 'I forgot my password.',
            },
            {
                answer: '<p>If you are still having trouble, please reach out to us for additional help.  We are available on Chat 24/7.</p>',
                id: 'cantResetPassword',
                question: 'I tried to reset my password, and did not receive the reset email. I have checked my junk/spam folders and nothing is there.',
            },
            {
                answer: '<p>Clear your cache/cookies or try a different browser. If you are still having trouble, please reach out to us for additional help.  We are available on Chat 24/7.</p>',
                id: 'newPasswordNotRecognised',
                question: 'I recently changed my password and its not being recognized.',
            },
            {
                answer: '<p>Your account should be used across all TTC Touring Brands and Uniworld.</p>',
                id: 'canUseSameTTCAccount',
                question: 'If I booked with multiple TTC Touring Brands, can I use the same account?',
            },
            {
                answer: '<p>Guests must register in order to receive their Travel Documentation. </p>',
                id: 'needAccountForDocs',
                question: 'Do I need to have an account to receive documents?',
            },
            {
                answer: '<p>Your username is the email address you used to sign up.</p>',
                id: 'username',
                question: "I don't know my username.",
            },
            {
                answer: '<p>Please create a new account if you would like to use a different email address as your username.</p>',
                id: 'changeEmail',
                question: 'Can I change my email/username?',
            },
            {
                answer: '<p>If you are still having trouble, please reach out to us for additional help.  We are available on Chat 24/7.</p>',
                id: 'unresolvedIssue',
                question: 'I have still not been able to resolve my issue?',
            },
        ],
    },
    Faq: {
        header: 'Frequently Asked Questions',
        description:
            'Please have a read of the below pre-travel information. Not only with these insights help you to make well-informed travel decisions, but also ensure your trip is as effortless and enjoyable as possible.',
        covid19QuestionsTitle: '',
        covid19QuestionsDescription: '',
        footer: {
            header: 'Still have unanswered questions?',
            description: 'Get in touch or speak to one of our experts on the phone.',
        },
        CallUsButton: {
            prefix: 'or call us on',
        },
        questions: [
            {
                answer: "<p class='Faq__faqAnswerTitles'>Clothing:</p><p>The weather varies by region, proximity to the coast, altitude and time of year so we suggest that you pack layers of clothing that can be added or removed as necessary. A comfortable pair of flat shoes trainers for walking is essential and a light waterproof jacket may also be useful and a sun hat during the summer months. You may wish to bring a dressier outfit for a special night out. Smart casual clothes are acceptable attire for other evening activities.</p><p class='Faq__faqAnswerTitles'>Gadgets:</p><p>During your trip, you will be supplied with Vox Tour Guide Systems, which will let you hear your Travel Director or Local Specialist during highlight experiences while allowing you to walk at leisure at a comfortable distance. We supply a one Vox in-ear earphone, but also recommend you bring your own pair of headsets with an Auxiliary Audio Cable. This will ensure you have the sound quality which suits you, all while helping us reduce our single use plastic along the way.</p>",
                id: 'pack',
                question: 'What should I pack?',
            },
            {
                answer: "<p><a href='https://packr.app/'>Packr</a> and <a href='https://www.packpnt.com/'>PackPoint</a> will give you great packing tips based on forecasted weather <a href='https://weather.com'>Weather Channel</a> or <a href='https://www.accuweather.com'>Accuweather</a> are the most popular weather apps to keep up to date with the local weather of the places you will go.</p><p><a href='https://www.duolingo.com/'>Duolingo</a> will have you covering off the basics of a local language for just 5 minutes a day <a href='https://translate.google.com/'>Google Translate</a> is great for quick translations.</p>",
                id: 'travelapps',
                question: 'What are the best travel apps to help me prepare for my trip?',
            },
            {
                answer: "<p>All your trip information will be provided in your e-Documents found on your <a href='/dashboard'>MyTrafalgar</a> which are made available approximately 3 weeks prior to departure.  Your Travel Director will be in touch a few days before your trip to introduce themselves and re-confirm your meeting point.</p>",
                id: 'meet',
                question: 'Where do I meet on Day 1?',
            },
            {
                answer: '<p>At Trafalgar we’re about caring for our planet, and with that in mind we recommend that you pack a reusable water bottle for your trip. Bringing your own reusable water bottle or asking for tap water at restaurants means you can make a difference by reducing your holiday plastic waste.  In Western Europe, Scandinavia, the USA, Canada, Australia and New Zealand, it’s fine to drink the water from a fresh-supply tap. For other countries, your Travel Director will let you know if it’s okay. Throughout the day, there will be multiple rest stops allowing time for a bottle refill or beverage purchase. We also recommend you download the app &quot;Tap – Find Water Anywhere&quot; on your phone, which allows its users to find the closest nearby Refill Stations where you can refill your water bottle. Thanks for helping us do our part for the planet when you travel, every little bit makes a difference.</p>',
                id: 'water',
                question: 'Can I drink the water in the countries I visit?',
            },
            {
                answer: "<p>Optional experiences have been handcrafted by our destination experts to give you a choice to further enhance your trip and discover even more of the places you'll visit.  You can get a flavour of what is in store for you on the detailed Trip Itinerary section of your <a href='/dashboard'>MyTrafalgar</a> plus your Travel Director will also share all the options you on the first day of your trip.  No need to book or prepay, you can do this with your Travel Director on trip and payment can be made by credit card or cash. Simple!</p>",
                id: 'optional',
                question: 'What are Optional Experiences and when can I book them?',
            },
            {
                answer: "<p>Your luggage allocation is one suitcase to the maximum weight of 23kg (50lbs) and the dimensions of 76cm x 46cm x 25cm (30&quot; x 18&quot; x 10&quot;). You can also bring one item of hand luggage that is small and light enough to be carried onto the coach and fit underneath the seat, or in the small overhead compartment above (luggage with adjustable handles and wheels will not fit). Baggage allowances also vary from airline to airline and you should also check with your chosen carrier for details prior to travel.</p><p class='Faq__faqAnswerTitles'>Porterage:</p><p>It's your trip and we are committed to your ease and comfort, and so porterage is included for your luggage throughout the trip (<span class='Faq__faqAnswerHighlight'>one item per person</span>), meaning that you only have to bring your hand luggage on and off the coach each day. From the minute you arrive, you can travel hassle free. You will be given a red luggage tag on pick up that will help with identification and security of your bag. From airport arrival to each hotel stay, your luggage will be loaded on and off the coach and delivered directly to your room leaving you more time to enjoy! We do suggest that you pack toiletries and any personal items you may want immediately on arrival at each stop in your hand baggage so they are instantly available.</p>",
                id: 'luggage',
                question: 'Are there any luggage restrictions?',
            },
            {
                answer: "<p>We'll check you in, look after your luggage and take care of all hotel porterage, tips and gratuities – whether it is for restaurant staff, chambermaids or porters. Easy! If you do however feel that your passionate and dedicated Travel Director and Driver have helped enhance your trip – gratuities can be shared at the end of your trip directly with the team.</p>",
                id: 'included',
                question: 'Are tips included?',
            },
            {
                answer: "<p>You are responsible for obtaining all necessary visas prior to the departure. Depending on your nationality , you may need visas to enter certain countries included on your trip. Please note that obtaining visas can take up to six weeks and so it is strongly advised that you consult your Travel Agent and the local Consulate or Embassy of the country you are visiting well in advance to determine which visas they require and how to obtain them. You can also visit the <a href='https://visacentral.com/trafalgar'>VisaCentral</a> website for more information.</p>",
                id: 'visa',
                question: 'Will I need a visa?',
            },
            {
                answer: "<p>Most hotels do offer a laundry service and in some cities Laundromats are also available. However, it's sensible to try and pack enough clothing to avoid having to do laundry, as it can sometimes be costly or inconvenient.</p>",
                id: 'laundry ',
                question: 'Will I be able to get laundry done?',
            },
            {
                answer: "<p>We love caring for the planet and hope you do to. We recommend being a conscious traveller and so here are some easy tips and tricks to make a difference:<ul class='Faq__faqAnswerlist'><li>Leave any plastic packaging from your new purchases at home to avoid needing to dispose of them on trip.</li><li>Avoid buying plastic bottled water. Instead we suggest you bring a reusable bottle or cup with you and refill at our many refreshment and comfort stops, a convenient option that is actively helping reduce plastic waste and you always have a bottle on hand when you need it.</li><li>Reduce your use of single use plastic during your trip and refuse things such as plastic bags, straws and bathroom toiletries.</li><li>Carry a reusable shopping bag with you for any souvenirs you may pick up along the way.</li></ul><p>Every small effort makes a big difference!</p><p>At Trafalgar we are dedicated to give back to the places we visit. To safeguard cultures, traditions and heritages for future generations we encourage you to purchase souvenirs and trinkets from local artisans we’ll encounter. Your Travel Director can share tips on the best places to purchase memories that will in turn give back and support the communities we visit.</p>",
                id: 'conscious',
                question: 'How can I make a difference and be a conscious traveller?',
            },
            {
                answer: "<p>No worries. Take a look at our extensive Know Before You Go guide <a href='https://www.trafalgar.com/en-au/know-before-you-go'>here.</a></p>",
                id: 'moreQuestions',
                question: 'Still have more questions?',
            },
        ],
    },
    TravelExtraLandingCard: {
        addedFlight: 'Added Flights',
        addedTransferFlight: 'Added Flights and Transfers',
        extendYourStay: 'Extend Your Stay',
        onRequestHeading: 'Your booking is Awaiting Confirmation',
        onRequestDesc1: 'Your booking for specific dates may need confirmation. During this process, the status will be marked as "On Request"',
        onRequestDesc2:
            'Please note that it is not recommended to book any extras as your booking is on request. If you have any questions, please contact our Contact Centre.',
        onRequestDesc3: 'Confirmations normally take between 24-48 hours, One of our team will notify you when we have had confirmation.',
        tooltipText:
            'Your booking is Awaiting Confirmation.Your booking for specific dates may need confirmation. During this process, the status will be marked as "On Request".Please note that it is not recommended to book any extras as your booking is on request. If you have any questions, please contact our Contact Centre.Confirmations normally take between 24-48 hours, One of our team will notify you when we have had confirmation.',
        hotelText: 'Give yourself more time for enjoyment. Add extra nights before or after your trip and extend your stay at our hotel.',
        allPaxInBasketMessage: 'If you want to change your accommodation, please remove the extra nights from the basket and check again here.',
        addNight: 'ADD EXTRA NIGHTS',
        searchTTCFlightText: 'You can search for the best possible flights to connect you with your trip.',
        addFlightText: 'If you have purchased your own flights, please tell us about your flight information.',
        ioFlightText: 'You have added flight details for all the guests. If you need to update any information, please contact our call centre.',
        ttcFlightText: 'You have purchased flights from us for all the guests. If you need to update any information, please contact our call centre.',
        searchFlights: 'SEARCH AND BUY FLIGHTS',
        addFlightsText: 'ADD FLIGHT DETAILS',
        Flights: 'Flights',
        gratuities: 'gratuities',
        flightsText: 'flights',
        consentText: 'You have been given permission to update Travel Extras and Flights on behalf of other guests traveling with you',
        consentTextForHotel: 'You have been given permission to update Travel Extras and Hotels on behalf of other guests traveling with you',
        consentTextForTransfer: 'You have been given permission to update Travel Extras and Transfers on behalf of other guests traveling with you',
        consentTextForGratuity: 'You have been given permission to update Travel Extras and Gratuities on behalf of other guests traveling with you',
        consentTextForAir: 'You have been given permission to update Travel Extras and Flights on behalf of other guests traveling with you',
        yes: 'YES',
        no: 'NO',
        addGratuityText: 'Add PREPAID GRATUITIES',
        gratuityText: "Don't miss out on showing your appreciation for exceptional service, add prepaid gratuities to your trip today.",
        gratuityAddedInBasket: 'Gratuities added in basket, to update the gratuities, please remove it from basket and come again here.',
        accomodationTitle: 'ACCOMMODATIONS',
        noHotelText: 'Extra nights are not available to be booked, please contact the call centre',
        allPaxHaveAccomodationText: 'You have added accommodations for all the guests. If you need to update any information, please contact our call centre.',
    },
    ExtraNights: {
        beforeTrip: 'Before the Trip',
        afterTrip: 'After the Trip',
        nights: 'Nights:',
        date: 'Date:',
        remove: 'REMOVE',
    },
    AdjustPassenger: {
        passengersText: 'Guest(s)',
        roomType: 'Room type',
    },
    TravelBasket: {
        transferText: 'Airport Transfers',
        total: 'Total to be added to your Booking:',
        title: 'TRAVEL BASKET',
        instalmentPlanHeading:
            ' Please note: your direct debit can not be automatically recalculated to reflect recent changes to your booking. Any extras you purchase after setting up a plan could result in your instalment plan overpaying.',
        instalmentPlanSubHeading: 'You can either:',
        instalmentPlanSubDesc1: 'Cancel your plan and set up a new one (to match your updated outstanding balance)',
        instalmentPlanSubDesc2: 'Continue with your current plan and pay the outstanding balance manually',
        notFoundMessage: 'There are no products in the basket.',
        addBookingText: 'Add To Your Booking',
        hotelText: 'hotel',
        flightsText: 'flights',
        gratuitiesText: 'gratuities',
        transfer: 'transfer',
        transferAdded: 'transfer added to booking',
        gratuityAdded: 'Gratuity added to booking',
        extraNightBeforeText: 'extra night before added to booking',
        extraNightAfterText: 'extra night after added to booking',
        transferToBasketText: 'transfers added to basket',
    },
    TravelBasketSuccess: {
        cardTitle: 'All travel items has been added to your booking',
        heading: 'Travel Basket',
        bookingDetails: 'See Your Booking Details',
        travelExtras: 'Go TO Travel Extras',
        payment: 'Make Payment',
        payInsAmount: 'Pay the insurance now',
        payFlightsAmount: 'Pay for flights now',
        infoMessage:
            'The Travel Protection has been adjusted due to an increased value of the booking. The difference in plans has been included in your payment schedule.You will need to pay for this insurance difference immediately-',
        flightsInfoMessage:
            'Flights have been added to your booking and payment is required, See payment section for details. If payment is not received by the due date, flights will be automatically cancelled without notice.',
    },
    HotelsCard: {
        addYourHotel: 'ADD EXTRA NIGHTS',
        continueBtnText: 'Continue',
        goBackBtnText: 'Go back',
        submitYourHotelsBtnText: 'Add Nights to Basket',
        overlapModalTitle: 'PRODUCTS DATE MISMATCH',
        overlapModalDesc: 'There is a date mismatch with the selected product(s). Would you like to continue?',
        overlapModalSuggestion:
            'For amendements to your flight(s) please remove the products from the basket or for previously booked products please contact the Contact Centre.',
        updateExtraNights: 'Update Extra Nights',
        goToTravelBasket: 'Go To Travel Basket',
        warningWhenFlightInBasket: 'You have added flights to your basket that do not align with the extra nights. Please update the flights or extra nights.',
        warningWhenFlightInBooking: 'You are trying to book extra nights that do not align with the booked flight. Please update the extra nights.',
        passengersHeadingText: 'Guest(s)',
        allGuest: 'all guest(s)',
        specialFlightMessage: 'There is a specialty air product for {paxnames}.Please ensure your flight dates align with the extra night(s) being added.',
        allocateManually: 'Allocate room manually',
        paxDescriptionText: 'Please select the travelers who are on the same hotel as you.',
        headerText:
            'You can extend your stay in our selected hotels before and after your trip. You will be allocated into the same room type as the tour booked.',
    },
    MiddleNameSection: {
        canNotBeUpdatedIfInternalAir:
            'Middle name can not be updated due to flights included as part of your tour. Please contact the Contact Centre to update your middle name.',
        canNotBeUpdatedIfWithinWindow:
            'Middle name can not be updated online within 50 days of departure, please contact the Contact Centre to update your middle name.',
        canNotBeUpdatedIfFlight: 'Middle name can not be updated due to flights on the booking. Please contact the Contact Centre to update your middle name.',
    },
    TravelExtras: {
        title: 'Travel Extras',
        extendStay: 'Extend Your Stay',
        versioningError:
            'Another user has updated the booking since you started it (this could be someone travelling with you), Please click on view bookings button and reload the booking',
        adultNotSelected: 'Please update selection to include at least one adult to book Air.',
        paxNotSelected: 'Please select at least one passenger.',
        addYourFlight: 'Add Your Flight',
        searchFlightErrorMessage: 'There was a problem with searching for the flights. Please try after sometime.',
        confirmFlightMessage:
            'There was a problem adding the flights to your booking. Please try searching again or contact us if you continue to experience problems.',
        flightOverlapWithNightText: 'There is a date mismatch with the selected product(s) for $paxnames. Please review and adjust as needed.',
        flightOverlapWithNightHeading: 'Flight overlap with nights already added',
        differentNightText: 'Please note guest have different extra nights booked or added in basket, please check dates before booking flights.',
        starts: 'Starts',
        allPaxText: 'all the selected guest(s)',
        ends: 'Ends',
        searchAndBuyFlight: 'Search and Buy Flights',
        roundTrip: 'Round-trip Flights',
        proceedPassportText: 'Proceed for passport verification',
        selectedFlightText:
            'Adding flights requires an additional payment. If payment is not received by the due date, flights will be automatically cancelled without notice.',
        warningTextForFlight: 'These dates are inclusive of any extra nights that you have chosen to book, before and after your trip.',
        showMoreSearchOptionText: 'show more search options',
        hideMoreSearchOptionText: 'hide more search options',
        searchForNonStopFlightsText: 'Search for Non-stop Flights',
        cabinClass: 'PREFERRED CABIN CLASS',
        flightDeviationDefaultValueMessageOutbound:
            'You are deviating from the trip start airport. You will be responsible for getting to your trip on your own. Please read the important <a href="javascript:onTripNotesClick()">trip notes</a> for start/end location information.',
        flightDeviationDefaultValueMessageReturn:
            'You are deviating from the trip end airport. You will be responsible for getting to your trip on your own. Please read the important <a href="javascript:onTripNotesClick()">trip notes</a> for start/end location information.',
        flightTitle: 'For your convenience, you can purchase flights for your trip from us.',
        airline: 'PREFERRED AIRLINE',
        tripNotesText: 'I have read the',
        searchFlights: 'Search For Flights',
        benefitsText:
            'For your benefit and clarity, the total airfare shown includes airline imposed fuel surcharges and government taxes and fees. If you need more clarification, please see our <a target="_blank" href="//www.trafalgar.com/terms-and-conditions/website-terms-of-use">Terms and Conditions</a>',
        baggageText:
            '<strong>Baggage Information</strong> <div>For more information regarding the baggage allowance and/or the applicable fee for a carry on bag and first and second checking bag, please check the requirements with your individual airline.',
        tripNotesLink: 'Trip Notes',
        multiCity: 'Multi-city Flights',
        preTripTitle: 'Pre Trip Search',
        postTripTitle: 'Post Trip Search',
        addProductErrorMessage:
            'We were unable to add the items in your basket to your booking. Please try again or contact us if you continue to experience problems.',
        unsavedChanges: 'If you move away from this page your changes will be lost, please click go back to stay on this page.',
        flightAlreadyExistMessage:
            'We’re sorry. Your changes have not been applied. Guests (placeholder) already have flight information booked and can not be updated again, please contact the Contact Centre if you want to make amendments to your flights. Please uncheck these guest and submit again for the other guests',
        flightAddError: 'We’re sorry. Your changes have not been applied.We are not able to update your booking right now, please try again later',
        headerText: "Please make sure to add all your flights as you won't be able to add new ones after you confirm unless you call our call centre.",
        passengers: 'Passengers',
        flightMessageIfAlreadyIoFlight: 'You have already provided your flight details, please contact the Contact Centre to amend your flights',
        flightMessage:
            'Travel extras cannot be booked online for bookings departing in $daysBefore$ days. Please contact the Contact Centre for more information or to add additional products.',
        selectTravelerText: 'Please select the traveler who will stay in this accommodation.',
        search: 'Search',
        unBookableLabelText: '= Unbookable, Trip Dates',
        bookingRangeLabelText: '= Selected range',
        checkInDatePlaceHolderText: 'Check-in',
        checkOutDatePlaceHolderText: 'Check-out',
        ioFlightsDescriptionMsgText:
            'If you have already purchased your flights, please enter your flight details. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque egestas arcu mi sit venenatis, lacinia velit justo ligula vitae tellus.',
        ioFlightPassengersHeadingText: 'Guest(s)',
        ioFlightPaxDescriptionText: 'Please select the travelers who are on the same flights as you.',
        ioFlightSubHeadingText: 'Flight #',
        addYourOwnFlightBtnText: 'Add Your Own Flights',
        removeBtnText: 'Remove',
        flightNumberInputTitle: 'Flight Number',
        departureDateInputTitle: 'Departure Date',
        departureAirportInputTitle: 'Departure Airport',
        arrivalAirportInputTitle: 'Arrival Airport',
        multicityCountryErrorText: 'The arrival airport must be in the same country as the original departure airport.',
        addAnotherFlightBtnText: ' Add Another Flight',
        collapseBtnText: 'Collapse',
        backButtonText: 'back to travel extras',
        submitYourFlightsBtnText: ' Submit Your Flights',
        allPassengersLableText: 'All Guests',
        airlineMaxLengthMessage: 'Please enter no more than 8 characters.',
        multicityDateErrorMessage: 'Please select a date before/after the tour starts/ends',
        multicityDateOrderErrorMessage: 'Please select a date on or after the previous flights',
        airlineNumberNotEntered: 'Please enter a valid flight number.',
        airlineCodeMissing: "Please prefix your flight number with your airline's 2 character airline code.",
        airportCodeErrorMessage: 'Arrival and Departure airports can not be same.',
        airlineErrorMessage: 'Please include the flight number after the airline prefix—this number is 1-4 digits long.',
        ioFlightDateOverlapErrorText:
            "It looks like your flight is scheduled during your trip. Please check the details are correct--we don't want you to miss a moment! If you need further assistance, please call one of the team on {phonenumber}",
        ioFlightDateOverlapErrorTextContactUs:
            "It looks like your flight is scheduled during your trip. Please check the details are correct--we don't want you to miss a moment! If you need further assistance, please conatct us <a href='https://www.trafalgar.com/en-gb/forms/contact-us' target='_blank'>here</a>.",
        ioFlightValidationErrorText:
            "We're sorry, we could not find your flight. Please check the details are correct and try again. If you are still unable to add your flight, please email the flight's details to {email}. To continue with your booking, please remove this flight before submitting further flight details.",
        ioFlightValidationErrorTextContactUs:
            "We're sorry, we could not find your flight. Please check the details are correct and try again. If you are still unable to add your flight, please contact us <a href='https://www.trafalgar.com/en-gb/forms/contact-us' target='_blank'>here</a>. To continue with your booking, please remove this flight before submitting further flight details.",
        airportPlaceholderText: 'Enter code or name',
    },
    ExtraNightOverlapModal: {
        heading: 'There is a date mismatch with the selected product(s). Would you like to continue?',
        description:
            'For amendments to your extra accommodation(s) please remove the products from the Basket or for previously booked products please contact the Contact Centre.',
    },
    FlightPaymentInfoModal: {
        flightPaymentTitle: 'FLIGHT PAYMENT',
        flightPaymentText1: 'Flights have been added to your basket and require additional payment.',
        flightPaymentText2: 'If payment is not recieved by $paymentDueDate$, flights will be automatically cancelled without notice.',
    },
    Footer: {
        chatPolicy: 'Chat Policy',
        copyright: 'Copyright $year$.',
        privacyPolicy: 'Privacy Policy',
        rightsReserved: 'All rights reserved.',
        termsAndCondition: 'Terms and Conditions',
    },
    Header: {
        CallUsButton: {
            suffix: 'or call your travel agent',
        },
        labelBrandSite: 'Back to Site',
        labelLogo: 'Home',
        labelLogoutSM: 'Log out',
        myTripsSM: 'View my trips',
        myTripsMD: 'My trips',
        callNow: 'Call now',
        chat: 'Chat',
        viewBasket: 'view Basket',
    },
    HelpBox: {
        CallUsButton: {
            prefix: 'or call us on',
        },
        questions: [
            {
                id: 'meet',
                question: 'Where do I meet on Day 1?',
            },
            {
                id: 'optional',
                question: 'What are Optional Experiences and when can I book them?',
            },
            {
                id: 'luggage',
                question: 'Are there any luggage restrictions?',
            },
            {
                id: 'conscious',
                question: 'How can I make a difference and be a conscious traveller?',
            },
            {
                id: 'allQuestions',
                question: 'View more FAQs',
            },
        ],
        header: 'Need some help?',
    },
    BookingInformationMenu: {
        title: 'Booking Information',
        bookingOverview: 'Booking Overview',
        financials: 'Financials',
        travelExtras: 'Travel Extras',
    },
    BookingOverviewCard: {
        title: 'Booking Overview',
        bookingNumber: 'Booking Number',
        dateBooked: 'Date Booked',
        amountDue: 'Amount Due',
        balance: 'Balance',
        nextPaymentDue: 'Next Payment Due',
        viewDetail: 'View Details',
        fullyPaid: 'fully paid',
    },
    InstalmentPlan: {
        title: 'Calculate Your Automatic Payments',
        heading: 'What is an instalment plan?',
        desc: 'An instalment plan allows you to pay for your booking through weekly or monthly payments, automatically processed based on your booking payment plan. There are no additional charges to set-up the plan, but card fees may apply depending on your region.',
        subHeading: 'How do I create an instalment plan?',
        subDesc:
            'To create an instalment plan, ensure you have a valid credit card with an expiry date that extends beyond the duration of the plan. To get started,  an initial payment is made to secure your instalment plan. After this, the plan will commence, and subsequent payments will be automatically processed.',
        cancelledText:
            'Instalment plans can be cancelled at any time, but do not actively recalculate the payments if the amount or departure date of your booking changes.',
    },
    EditInstalmentPlan: {
        title: 'Your Automatic Payments Plan',
    },
    InstalmentPlanCancelModal: {
        keepPlan: 'KEEP PLAN',
        cancelPlan: 'CANCEL PLAN',
        title: 'Cancel Instalment Plan',
        description: 'Are you sure you want to cancel your instalment plan? You will need to pay for your trip <strong>in full</strong> no later than $date$.',
    },
    InstalmentPlanSuccess: {
        title: 'Calculate your Automatic Payments',
        viewFAQText: '<p><a href="#instalment-plan-faq">View Frequently asked Questions</a></p>',
        questions: [
            {
                answer: '<p>An instalment plan allows you to pay for your booking through weekly or monthly payments, automatically processed based on your booking payment plan. There are no additional charges to set-up the plan, but card fees may apply depending on your region.</p>',
                id: 'whatIsInstalmentPlan',
                question: 'What is an instalment plan?',
            },
            {
                answer: '<p>To create an instalment plan, ensure you have a valid credit card with an expiry date that extends beyond the duration of the plan. To get started,  an initial payment is made to secure your instalment plan. After this, the plan will commence, and subsequent payments will be automatically processed.</p><p>Instalment plans can be cancelled at any time, but do not actively recalculate the payments if the amount or departure date of your booking changes.</p>',
                id: 'howToCreateInstalmentPlan',
                question: 'How do I create an instalment plan?',
            },
        ],
    },
    InstalmentPlanSuccessTile: {
        title: 'Success! Your direct debit is officially up and running.',
        information: 'As you now have an active instalment plan, the instalment dates will override any existing payment dates on your invoice.',
        backToHomePage: 'BACK TO HOMEPAGE',
    },
    InstalmentPlanDetailsTile: {
        title: 'Instalment Plan Details',
        weeklyPaymentText: 'Your monthly payment',
        biWeeklyPaymentText: 'Your bi-weekly payment',
        firstPaymentText: 'First payment date',
        planCreatedText: 'Plan created date',
        instalmentText: 'Number of instalments',
        finalPaymentText: 'Final payment date',
    },
    InstalmentPlanFAQTile: {
        header: 'Frequently asked Questions',
        questions: [
            {
                answer: '<p>An Instalment plan is a convenient payment option where you can divide the total cost of your trip into smaller payments – made over time instead of paying the full amount upfront. In most cases, the total amount is split into equal instalments, and you can choose to pay monthly or 2-weekly. You’ll need to make the final payment no later than 60 days before your trip departure date.</p><p>Note: the Instalment plan does not automatically guarantee full payment of the booking. As the traveller, it is your responsibility to make sure you’ve paid in full on/before the date provided.</p>',
                id: 'whatIsInstalment',
                question: 'What is an Instalment plan?',
            },
            {
                answer: '<p>To get started, make sure you have a valid credit/debit card that expires after the final day of your trip. You’ll need to make an initial payment to set up the plan but after that, the monthly/bi-weekly payments will be processed automatically – so you don’t need to worry.</p><p>You can cancel your payments at any time.</p><p>If there’s a change to your booking, we won’t be able to recalculate your Instalment payments automatically. You can either: </p><p>- Cancel your plan and set up a new one which reflects your new outstanding balance</p><p>- Continue with your current plan and pay the outstanding balance manually</p>',
                id: 'howToSetUpInstalmentPlan',
                question: 'How do I set-up my Instalment plan?',
            },
            {
                answer: '<p>You won’t be able to alter your Instalment plan once it’s set up.</p><p>If you want to change the instalment amount/frequency of payments, you can cancel your current plan and then set up a new one. If you choose to do this, your total outstanding balance will be updated to reflect any/all payments you’ve already made.</p>',
                id: 'changePlanLater',
                question: 'Can I change my payment plan later?',
            },
            {
                answer: '<p>In most cases, card fees will only apply to travellers based in Australia & New Zealand. In these cases, there’ll be a fee added to your initial payment and all payments made after that too. The fee itself will depend on your card provider, and will be added to your overall outstanding balance.</p>',
                id: 'regoinCardFees',
                question: 'Which region card fees may apply and will I be charged any other fees?',
            },
            {
                answer: "<p>If your payment date falls on a non-working day, it'll be collected on the next working day.</p>",
                id: 'paymentNonWorkingDay',
                question: 'What if the payment falls on a non-working day?',
            },
            {
                answer: '<p>In most cases, yes. Although some trips may be exempt if there’s a promotion running, or if you’ve purchased a last-minute deal. Got a specific trip/departure in mind? You can chat to our friendly customer service teams 24/7, online or over the phone.</p>',
                id: 'allTripsEligiblePlan',
                question: 'Can I pay for all trips using the Instalment plan?',
            },
            {
                answer: '<p>Sadly, you won’t be able to alter your Instalment plan once it’s set up.</p><p>If you want to change it, you can cancel your current plan and then set up a new one that reflects your new outstanding balance.</p>',
                id: 'addExtrasInstalment',
                question: 'My Instalment plan is active already but I want to add extras to my booking, will my plan be recalculated automatically?',
            },
            {
                answer: '<p>Your payments will be taken automatically, at your chosen frequency (monthly or bi-weekly), from the account provided. We’ll remind you ahead of each payment date too!</p>',
                id: 'howAreInstalmentsProcessed',
                question: 'How are Instalment payments processed?',
            },
            {
                answer: '<p>Yes, you can cancel your plan at any point. Just note that you’ll need to have paid for your trip in full on/before the due date supplied with your booking.</p><p>If you’re cancelling your trip entirely, you’ll be reimbursed for all the payments you’ve already made. For more info, check the Refunds & Cancellation policy in our T&Cs.</p>',
                id: 'cancelPlanInstalment',
                question: 'What happens if I want to cancel my plan or my trip?',
            },
            {
                answer: '<p>If your payments fail to process 5 times in a row, we’ll place your plan on hold indefinitely. If this happens, you can either set up a new Instalment plan, pay the remaining balance up front or pay manually in as many instalments as you like by heading to Manage My Trip and making the payment(s) there.</p><p>Note: you must pay for your trip in full on/before the due date supplied with your booking.</p>',
                id: 'missedPayment',
                question: 'What happens if I miss a payment?',
            },
            {
                answer: '<p>Your total balance will be due 60 days before your departure date.</p>',
                id: 'payInstalmentInFull',
                question: 'When do I need to have paid for my trip in full?',
            },
            {
                answer: '<p>Yes, the minimum plan length is two weeks prior to your ‘Pay in Full’ date, which gives you time to make one bi-weekly payment. If your trip starts less than 2 weeks after you make your booking, you won’t be able to set up an Instalment plan.</p>',
                id: 'minimumInstalmentLength',
                question: 'Is there a minimum length of time the plan can run for?',
            },
            {
                answer: '<p>You can do this by logging into My Travel Portal (MTP) and then selecting ‘Manage Plan’. You can view your upcoming payments, remaining balance and other relevant details too.</p>',
                id: 'checkInstalmentStatus',
                question: 'How can I check the status of my Instalment plan?',
            },
            {
                answer: '<p>If a payment is rejected, you’ll get an automated email – sent to the address with which you registered on My Travel Portal (MTP). After that, a reservation agent will be in touch to let you know your next steps.</p><p>Note: make sure your email address on MTP is up to date so we can contact you with any important info about your booking.</p>',
                id: 'paymentFailedInstalment',
                question: 'How will I know if a payment fails?',
            },
            {
                answer: '<p>Don’t worry. Your next payment will simply be taken on the closest corresponding date. I.e. if your plan commences on the 31st of one month and you’ve chosen to make monthly payments, your next payment will come out on the last day of the following month, and so on.</p>',
                id: 'paymentFallsOnIrregularDate',
                question: "What if my payment falls on a day which doesn't occur every month?",
            },
            {
                answer: '<p>We recommend avoiding this by setting up your plan with a card that expires after your final payment date. However, if your card does expire mid-plan, it’s not the end of the world. You’ll just need to cancel your existing plan and set up a new one with your updated card details.</p>',
                id: 'cardExpiresMidPlan',
                question: 'What if my card expires mid-plan?',
            },
        ],
    },
    Hero: {
        greeting: {
            guest: 'Welcome to TTC',
            member: 'Welcome to my TTC',
            prefix: 'Hey',
        },
        passportMessage: 'We need your passport detail, please click <a>here</a> and then go in passport tab for adding your passport details.',
    },
    HomeButton: {
        buttonLabel: 'Home',
    },
    HotelMeals: {
        hotelTitle: 'Overnight',
        mealTitle: 'Meals inc.',
        helpText: 'All hotels have been carefully selected to enhance your trip, but please note they are subject to change any time.',
    },
    Itinerary: {
        accordionHeading: 'Select an Itinerary Day',
        dayTitle: 'Day',
    },
    advisoryMessage: '',
    ItineraryCard: {
        header: 'Trip Details',
        mapTitle: 'Trip Map',
        viewTripButton: 'View Trip',
    },
    Loader: {
        message: 'Loading...',
    },
    LoginForm: {
        emailAddress: 'Email Address',
        password: 'Password',
    },
    SignUpCard: {
        login: 'Login',
        signup: 'Sign Up',
        loginHeader: 'Login to',
        signUpHeader: 'Sign Up For',
        brandText: 'My ',
        haveAccountDescription: 'All guests within a booking must create their own account.',
        description: 'Register your details and personalise your trip for total peace of mind.',
        firstname: 'First Name',
        lastname: 'Last Name',
        emailAddress: 'Email Address',
        passWord: 'Password',
    },
    MmbRegistration: {
        registerText: 'Want to register for your trip without joining My Trafalgar. ',
        clickHere: 'Click here',
    },
    Modal: {
        closeBtn: 'close',
    },
    Navigation: {
        navigationList: {
            Edocs: 'Download your documents',
            Faq: 'Help',
            Itinerary: 'View your trip itinerary',
            TripPersonalisation: 'Personalise your trip',
            InstalmentPlan: 'Create Plan',
            EditInstalmentPlan: 'View Plan',
            TripRegistration: 'Register your details',
            bookingOverview: 'Booking Overview',
            Sustainability: 'Sustainability',
            BookingDetails: 'Booking Overview',
            Dashboard: 'Trip Home',
            Payment: 'Make a Payment',
            KnowBeforeYouGo: 'Know Before You Go',
            TravelExtraLanding: 'Travel Extras',
            IOFlightConfirmation: 'IO flight added',
            ExtraNightConfirmation: 'Extra nights added to basket',
            AirConfirmation: 'Air added to basket',
            PassportVerification: 'User land on passport verification page',
            AirProduct: 'User land on Air page',
            TravelBasket: 'User lands on basket page',
            TravelBasketSuccess: 'Products successfully added in booking',
            Flights: 'User lands on IO flight page',
            Hotels: 'User lands on Hotel page',
        },
    },
    NotFound: {
        action: 'Go To Homepage',
        heading: 'Error 404',
        subHeading: 'Page Not Found',
        message: 'No prob-llama! Just head back to our home page for all your travel needs.',
        subMessage: '',
    },
    Optional: {
        heading: 'Optional Experiences',
        helpText:
            'These special experiences have been hand picked to enhance your trip. Your Travel Director will arrange booking/s and payment directly with you on your trip. Please note that all experiences are indicative only and may be withdrawn or changed at any time.',
        priceForAdult: 'Adult price: ',
        priceForChild: 'Child price: ',
        viewDetail: 'View Details',
    },
    ItineraryExcursions: {
        choiceOfText: 'Choice of ',
        orText: ' or ',
        featuredExcursionsHeading: 'Featured Excursions:',
        generationThemesTitle: 'Generations Excursions:',
        generationFooterText: 'This excursion is only available on sailings that belong to the Generations program.',
        jewishThemesTitle: 'Jewish Heritage Excursions:',
        connoisseurThemesTitle: 'Connoisseur Excursions:',
        otherThemesTitle: 'Other Excursions:',
        connoisseurFooterText: 'This excursion is only available on sailings that belong to the Connoisseur Collection program.',
        masterpieceExcursionsHeading: 'Masterpiece Collection:',
        masterPieceActivityLevel: 'Activity Level:',
        masterPieceTransportation: 'Transportation:',
        masterPiecePrePurchase: 'pre-purchase',
        masterPieceOnBoard: 'onboard',
        masterPieceDuration: 'Duration:',
        masterPieceHours: 'hours',
        masterPiecePrice: 'Price:',
        excursionsTooltip:
            'Providing the most all-inclusive luxury to our guests is the heart and soul of our brand and that will never change. Yet sometimes our guests want something that goes even &quot;above and beyond&quot; our included experiences. In light of this, we now offer guests a curated selection of optional excursions, available for an additional charge.',
    },
    LocalDetails: {
        heading: 'Local Details',
        language: 'Language(s):',
        currency: 'Currencies:',
    },
    TripPersonalisationWizard: {
        Buttons: {
            labels: {
                back: 'Back',
                complete: 'Next Guest',
                next: 'Next',
                submit: 'Submit',
                skip: 'Complete Later',
            },
        },
        Header: {
            multiPax: 'Personalise Your Trip for $guestName$',
            singlePax: 'Personalise Your Trip',
        },
        requiredHint: '* Required',
        errorMessages: {
            unableToSave: 'Unable to edit your information at this time, please try again later.',
            beddingPreference: 'You must select a bedding preference to complete personalisation.',
        },
        Steps: {
            PersonaliseDetails: {
                beddingOptions: {
                    heading: 'Bedding Options',
                    description: 'Please choose your bedding configuration preference.',
                    Fieldset: {
                        description: 'You will be sharing with $guestName$. What bedding option would you prefer?',
                        fields: {
                            doubleBed: 'Double Bed',
                            twinBed: 'Twin Beds',
                        },
                        title: 'Bedding Options Fields',
                    },
                },
                addtionalInformation: {
                    heading: 'Additional information',
                    Fieldset: {
                        personalRequest: {
                            description:
                                'Celebrating a special occasion? Have a dietary preference? Wherever possible we will try to accommodate any personal requirements, please share your requests here.',
                            fields: {
                                comment: {
                                    placeholder: '',
                                    title: 'Add comment',
                                },
                            },
                            title: 'Personal requests',
                        },
                        travelPassion: {
                            description: 'We want to get to know you. What are you most looking forward to about your trip?',
                            fields: {
                                comment: {
                                    placeholder: '',
                                    title: 'Add comment',
                                },
                            },
                            title: 'Your travel passion',
                        },
                    },
                },
            },
            PersonalisationSuccess: {
                heading: {
                    complete: {
                        both: 'Congratulations you have finished registration and personalisation for $guestName$.',
                        self: 'You have completed personalisation for $guestName$, but still need to complete registration.',
                    },
                    incomplete: {
                        both: 'You have not completed personalisation section for $guestName$.',
                        self: 'You have not completed personalisation section for $guestName$.',
                    },
                },
                description: {
                    complete: {
                        both: 'Get ready to enjoy the trip of a life time!',
                        self: 'Please click the button below to enter your details.',
                    },
                    incomplete: {
                        both: 'Please come back here anytime to finish it.',
                        self: 'Please come back here anytime to finish it.',
                    },
                },
                InformationTile: {
                    registration: {
                        button: {
                            incomplete: 'Complete Now',
                            complete: 'Make Changes',
                        },
                        heading: 'Register your details',
                        description: {
                            incomplete: 'to ensure an appropriate duty of care',
                            complete: 'to ensure an appropriate duty of care',
                        },
                    },
                    documents: {
                        button: {
                            incomplete: 'View Documents',
                            complete: 'View Documents',
                        },
                        heading: 'Travel Documents',
                        description: {
                            incomplete: 'Your e-documents will be ready 21 days prior to your departure',
                            complete: 'Your e-documents are ready',
                        },
                    },
                },
            },
        },
        SubHeader:
            'Complete your trip preferences and we will share them with your Travel Director. Every effort will be made to accommodate all requests, however some options may not be available during your trip.',
    },
    TravelProtection: {
        title: 'TRAVEL PROTECTION',
        newTravelProtection: 'Travel Protection Plan 4',
        oldTravelProtection: 'Travel Protection Plan 3',
        newPrice: '$150',
        oldPrice: '$100',
        passengersText: 'Guests:',
        passengerName: 'Jan Fjortoft',
        passengersNote: 'for all Guests',
        infoMessageTPDecrease:
            'Your Travel insurance has decreased. Your Travel Protection plan will be changed to a different plan if you continue with this transaction.',
        infoMessage:
            'The price of your booking has changes and for this reason your Travel Protection has been upgraded to a different plan. The difference in plans has been included in the total price.',
    },
    InsuranceUpgradeModal: {
        title: 'TRAVEL PROTECTION UPGRADE',
        message1: 'The value of items caused change of the Protection Plan.',
        message2: 'Please check the Travel Protection in your basket for further details.',
        message3: 'The difference in plans has been included in the total price.',
        closeButton: 'close',
    },
    TripRegistrationWizard: {
        Buttons: {
            labels: {
                back: 'Back',
                complete: 'Next Guest',
                next: 'Next',
                submit: 'Submit',
                skip: 'Complete Later',
            },
        },
        errorMessages: {
            unableToSave: 'Unable to edit your information at this time, please try again later.',
            validationErrors: 'You must provide required values to complete registration.',
            passportValidationError: 'You must provide required values to complete registration.',
            travelInsurance: 'You must select an option to complete registration.',
        },
        Header: {
            multiPax: 'Trip Registration for $guestName$',
            singlePax: 'Trip Registration',
        },
        requiredHint: '* Required',
        Steps: {
            FitToTravel: {
                fttHeading: 'Vaccination Status',
                fttSubHeading: 'Vaccination Status Declaration COVID-19',
                fttDescription:
                    '<p>The wellbeing of our guests is our top priority. As part of this commitment, all guests traveling with $brandName$ through December 31, 2022 are required to be <strong>fully vaccinated</strong> against Covid-19, which may include a booster shot to keep the vaccination status current and valid, depending on the travel destination.</p><p>Children not eligible for vaccination and/or booster at the time of travel must provide a verifiable and negative Covid-19 test result, taken within 72 hours prior to the start of the tour (taking time zones into consideration) for all tours departing until December 31, 2022.</p><p>For guests traveling with $brandName$ from 1 January 2023, Covid-19 vaccinations and boosters are highly recommended, but no longer required.</p><p>Please note that regardless of time of travel guests will need to comply with specific vaccine and/or booster requirements imposed by airlines and/or authorities in the destination visited, as well as requirements for accessing restaurants and venues, which may be more restrictive than above.</p><p>It is guests’ responsibility to ensure that they comply at all times with these requirements. No refunds will be given if guests are unable to undertake some or all of their vacation due to their failure to comply with these requirements.</p><p>This policy is subject to change at any time, without notice.</p>',
                fttInputCheckBoxText: 'Fully Vaccinated',
                fttDateLabelText: 'Date of last vaccination',
                vaccineDateInput: {
                    placeholder: {
                        date: 'DD',
                        month: 'MM',
                        year: 'YYYY',
                    },
                },
                disclaimerTextLead:
                    'As a lead guest in a group booking, you may provide this information on behalf of the group if you have been authorised to do so.',
                disclaimerTextNonLead:
                    'Please be advised that the Lead Guest who manages your booking will be able to see the information you provide in relation to this booking.',
                fttChildPassengerDescription:
                    '<p>Until further notice, we are not collecting the vaccination status of children. If your child has been vaccinated:</p><p>In case of travel  through December 31, 2022, please present their authentic COVID-19 certificate (electronic or paper showing date and time of vaccination(s)) with final dose or booster given at least 15 days before departing) upon arrival on the trip. For children ages 5-15, who are unable to be vaccinated, a verifiable negative test result for COVID-19 (electronic or paper showing date and time of test) taken within 72 hours of the trip departure is required.</p><p>For travels from 1 January 2023, Covid-19 vaccinations and negative COVID-19 certificates are highly recommended, but no longer required.</p>',
            },
            ContactDetails: {
                heading: 'On Trip Contact Details',
                childInfo: 'This information is not required for children',
                description: 'In the unlikely event of an incident, we will use these details to contact you.',
                Fieldset: {
                    fields: {
                        emailAddress: {
                            placeholder: 'Email address',
                            title: 'Email address',
                        },
                        phoneNumber: {
                            placeholder: {
                                internationalCallingCode: 'Select',
                                number: 'Your phone number while travelling',
                            },
                            title: 'Your phone number while travelling',
                        },
                    },
                    title: 'Trip Contact Details Fields',
                },
            },
            EmergencyContact: {
                heading: 'Emergency Contact',
                description: 'Please nominate a person not travelling with you, who we can contact in the unlikely event of an incident.',
                Fieldset: {
                    fields: {
                        name: {
                            placeholder: 'Name',
                            title: 'Name',
                        },
                        phoneNumber: {
                            placeholder: {
                                internationalCallingCode: 'Select',
                                number: 'Contact number',
                            },
                            title: 'Contact number',
                        },
                        relationship: {
                            placeholder: 'Relationship',
                            title: 'Relationship',
                        },
                    },
                    title: 'Emergency Contact Fields',
                },
            },
            PassportDetails: {
                heading: 'Passport Details',
                domesticTravelPassportHeading: 'Please complete your passport details below:',
                requiredHint: '* Required',
                cruisesBorderAsInternational: '(Trips including cruises and border crossings are considered international travel)',
                description:
                    '<p>Please provide details so we can ensure a seamless trip from hotel check-ins to boarder security checks and more. Incomplete or inaccurate details may result in services being affected or additional charges.</p><p> Many countries require passports to be valid for a minimum of 6 months after travel. Please check with local consular services for passport validity and visa requirements for your tour.</p>',
                domesticTravelDescription: 'Please select the travel option that is correct to your upcoming trip.',
                Callout: {
                    messageForCheckPassportExpiry:
                        'Please be advised that many countries require that passports be valid for a minimum of 6 months after the completion of your trip. Please check the local consulate for passport validity requirements for your trip.',
                },
                Fieldset: {
                    fields: {
                        countryOfIssue: {
                            placeholder: 'Country of Issue',
                            title: 'Country of Issue',
                        },
                        dateOfBirth: {
                            placeholder: {
                                date: 'DD',
                                month: 'MM',
                                year: 'YYYY',
                            },
                            title: 'Date of Birth *',
                        },
                        expiryDate: {
                            placeholder: {
                                date: 'DD',
                                month: 'MM',
                                year: 'YYYY',
                            },
                            title: 'Expiry Date *',
                        },
                        issueDate: {
                            placeholder: {
                                date: 'DD',
                                month: 'MM',
                                year: 'YYYY',
                            },
                            title: 'Date of issue *',
                        },
                        nationality: {
                            placeholder: 'Nationality',
                            title: 'Nationality',
                        },
                        number: {
                            placeholder: 'Passport number',
                            title: 'Passport number *',
                        },
                        middleName: {
                            placeholder: 'Middle Name',
                            title: 'Middle Name',
                        },
                    },
                    title: 'Passport Details Fields',
                    domesticTravelTitle: 'Are you travelling domestically?',
                    yesTravellingDomesticText: 'Yes I am',
                    noTravellingDomesticText: 'No I am not',
                },
            },
            RegistrationSuccess: {
                heading: {
                    complete: {
                        both: 'Congratulations you have finished registration and personalisation for $guestName$.',
                        self: 'You have completed registration for $guestName$, but trip personalisation remains incomplete.',
                    },
                    incomplete: {
                        both: 'You have not completed registration for $guestName$.',
                        self: 'You have not completed registration for $guestName$.',
                    },
                },
                description: {
                    complete: {
                        both: 'You are ready to enjoy a trip of a lifetime.',
                        self: 'Please complete all details below.',
                    },
                    incomplete: {
                        both: 'Please come back here anytime to finish it.',
                        self: 'Please come back here anytime to finish it.',
                    },
                },
                InformationTile: {
                    personalisation: {
                        button: {
                            incomplete: 'Complete Now',
                            complete: 'Make Changes',
                        },
                        heading: 'Personalise your trip',
                        description: {
                            incomplete: 'to customise your travel preferences',
                            complete: 'to customise your travel preferences',
                        },
                    },
                    documents: {
                        button: {
                            incomplete: 'View Documents',
                            complete: 'View Documents',
                        },
                        heading: 'Travel Documents',
                        description: {
                            incomplete: 'Your e-documents will be ready 21 days prior to your departure',
                            complete: 'Your e-documents are ready',
                        },
                    },
                },
            },
            TravelInsurance: {
                heading: 'Travel Insurance',
                description: 'It is essential that you have travel insurance before you depart.',
                Callout: {
                    advisorymessageForTravelProtectionLead:
                        'You have not completed your insurance registration information. You will need to sign an insurance waiver to join your trip if you do not have insurance.',
                    advisorymessageForTravelProtectionNonLeadAdult:
                        'You have not completed Insurance registration information for this guest. Guests who do not provide us with insurance details will need to register and accept an insurance waiver on their own behalf to be able to join their trip.',
                    advisorymessageForTravelProtectionChild:
                        'You have not completed Insurance registration information for this guest. If they do not have insurance, you will need to sign an insurance waiver on their behalf for them to be able to join their trip.',
                    messageForCallAgent:
                        '<p>It is strongly recommended that you take out comprehensive insurance cover for cancellation, personal injury, death, medical expenses, repatriation expenses, evacuation expenses, personal liability & loss of personal property before you travel on a $brandName$ holiday. Please check that the insurance covers all of the activities that you are going to be participating in as some policies exclude certain adventure activities. Your insurance policy should be arranged at the time of payment of the deposit & will, in certain circumstances, cover you against loss of deposit or cancellation fees from the date of confirmation of your booking, as shown in the insurance policy.</p><p>$brandName$ cannot be held responsible for your failure to take out appropriate insurance. Should you choose to travel on a $brandName$ holiday without appropriate insurance, you also agree to indemnify us against all third-party claims, actions, damages & remedies which may be brought against us in respect of your participation in the holiday.</p>',
                    messageForTravelProtection: 'You have purchased $name$ with us, however this is not Travel Insurance.',
                    messageForInsuranceWaiver: '',
                    TTUSAS: "<p class='TravelInsurance__descriptionText'>All guests are required to have travel insurance in order to travel with $brandName$. You are not required to purchase travel insurance from $brandName$ and may purchase travel insurance from any provider that includes the following coverage: Trip Interruption, Trip Delay, Medical Expenses, Quarantine Expenses and Emergency Evacuation/Repatriation. The insurance must cover medical costs and a medically imposed quarantine if the insured, or someone the insured has come into contact with, tests positive for COVID-19. Please check <a href='$nonMandatoryCalloutLink$' target='_blank'>here</a> for additional details and minimum coverage requirements. If you do not currently have travel insurance, please contact $brandName$ or your travel advisor as soon as possible to discuss your options.</p><p class='TravelInsurance__descriptionText'>Should you choose to travel on $brandName$ without the required travel insurance, please check the box below to acknowledge your agreement to take full responsibility for all expenses (trip interruption, trip delay, medical expenses, quarantine expenses and emergency evacuation/repatriation) that may arise while traveling with $brandName$. Further, you agree to indemnify $brandName$ against all third-party claims, actions, damages and remedies which may be brought against $brandName$ as a result of your participation in the $brandName$ trip.",
                },
                Fieldset: {
                    insurance: {
                        fields: {
                            insuranceStatusNo: "No I don't",
                            insuranceStatusYes: 'Yes I do',
                            insuranceWaiverYes: 'I agree',
                        },
                        nonLeadInsuranceWaiverBefore48Text: {
                            descriptionline1:
                                'All adult guests who do not provide us with their insurance details will have to accept the Insurance waiver on their own behalf, prior to travel. ',
                            linkText: '',
                            descriptionline2:
                                'Adult guests on your booking must register separately to accept an insurance waiver if they do not have insurance.',
                        },
                        nonLeadInsuranceWaiverAfter48Text: {
                            descriptionline1:
                                'All adult guests who do not provide us with their insurance details will have to accept the Insurance waiver on their own behalf, prior to travel. ',
                            linkText: '',
                            descriptionline2:
                                'Adult guests on your booking must register separately to accept an insurance waiver if they do not have insurance.',
                        },
                        title: 'Do you have travel insurance?',
                    },
                    policy: {
                        fields: {
                            companyName: {
                                placeholder: 'Company name',
                                title: 'Company name',
                            },
                            phoneNumber: {
                                placeholder: {
                                    internationalCallingCode: 'Select',
                                    number: 'Company contact number',
                                },
                                title: 'Company contact number',
                            },
                            policyNumber: {
                                placeholder: 'Policy number',
                                title: 'Policy number',
                            },
                        },
                        title: 'Policy Details',
                    },
                },
                travelProtectionInfo: {
                    heading: 'Travel protection for $guestName$',
                    description: 'Valid dates: $startDate$ - $endDate$',
                },
            },
        },
        SubHeader: 'For your peace of mind, we require the following information to ensure an appropriate duty of care while you are on your trip.',
        Timeline: {
            headers: [
                {
                    content: 'Trip Contact Details',
                },
                {
                    content: 'Emergency Contact',
                },
                {
                    content: 'Passport details',
                },
                {
                    content: 'Travel insurance',
                },
            ],
            heading: 'Steps',
        },
    },
    EdocsSubscriptionBox: {
        header: 'Your Travel Documents',
        paperDocumentsChoice: 'I want to travel with paper documents & not plant a tree',
        paperLessDocumentsChoice: 'I want to travel with eDocuments & plant a tree',
        buttons: {
            viewDocuments: 'View Edocuments',
            choosePaperless: 'Choose Paperless',
        },
        noDocsForGroupSpecialGroup:
            'We use e-docs for a greener future. Thanks to travelling paper free, you’re supporting our sustainability efforts to reduce our environmental impact wherever possible.',
        isPreDocumentAndPaperLess: {
            description:
                'We use e-docs for a greener future. Your documents will be delivered electronically in PDF format 21 days prior to your departure. Thanks to travelling paper free, you’re supporting our sustainability efforts to reduce our environmental impact wherever possible.',
        },
        isPreDocumentAndWithPaper: {
            description:
                'We use e-docs for a greener future. Your documents will be delivered electronically in PDF format 21 days prior to your departure. Thanks to travelling paper free, you’re supporting our sustainability efforts to reduce our environmental impact wherever possible.',
        },
        paperOptionNotAvailable: {
            description:
                'We use e-docs for a greener future. Your documents will be delivered electronically in PDF format 21 days prior to your departure. Thanks to travelling paper free, you’re supporting our sustainability efforts to reduce our environmental impact wherever possible.',
        },
        isDocumentAndPaperLess: {
            heading: 'Congratulations! You have planted a tree.',
            description:
                'We use e-docs for a greener future. Thanks to travelling paper free, you’re supporting our sustainability efforts to reduce our environmental impact wherever possible.',
        },
        isDocumentWithPaper: {
            heading: 'Oh no! You missed out on planting a tree.',
            description:
                'We use e-docs for a greener future. Thanks to travelling paper free, you’re supporting our sustainability efforts to reduce our environmental impact wherever possible.',
        },
    },
    TripSchedule: {
        tripEndHeader: 'End',
        tripStartHeader: 'Start',
    },
    MultiGuest: {
        header: 'Guest(s)',
        titleHeading: 'Your Information',
        edit: 'Edit',
        TripPersonalisation: {
            subHeading: '$completed$ / $total$ personalisations complete',
            progress: {
                incomplete: 'Personalisation Progress',
                complete: 'Personalisation Complete',
            },
        },
        TripRegistration: {
            subHeading: '$completed$ / $total$ registrations complete',
            progress: {
                incomplete: 'Registration Progress',
                complete: 'Registration Complete',
            },
        },
    },
    TripRegistrationCard: {
        alert: {
            singleItem: '1 item requires your attention',
            multipleItems: '2 items require your attention',
        },
        header: 'Trip Registration',
        subHeader: 'Your important information',
        guestTraveller: {
            header: 'Other Travelers Information ',
            description: '($completedGuests$ of $totalGuests$ completed)',
            detailNotComplete: 'Please complete the trip registration and personalisation for all guests on your booking',
        },
        InformationTile: {
            personalisation: {
                button: {
                    incomplete: 'Complete Now',
                    complete: 'Make Changes',
                },
                heading: 'Personalise your trip',
                description: {
                    incomplete: 'to customise your travel preferences',
                    complete: 'to customise your travel preferences',
                },
            },
            registration: {
                button: {
                    incomplete: 'Complete Now',
                    complete: 'Make Changes',
                },
                heading: 'Register your details',
                description: {
                    incomplete: 'to ensure an appropriate duty of care',
                    complete: 'to ensure an appropriate duty of care',
                },
            },
            travelExtras: {
                heading: 'Add Travel Extras',
                description: 'see travel extra details',
                button: 'See Extras',
            },
            paymentInstallment: {
                heading: 'Instalment Plan',
                editHeading: 'Manage your instalment plan',
                description: 'Create an instalment plan to manage your payments',
                button: 'Create Plan',
                editButton: 'View Plan',
            },
        },
    },
    ValidationMessages: {
        minLength: {
            characters: 'Please enter minimum $length$ characters',
            numbers: 'Please enter minimum $length$ numbers',
        },
        maxLength: {
            characters: 'Please enter no more than $length$ characters',
            numbers: 'Please enter no more than $length$ numbers',
        },
        numberOnly: "Can't type in letters",
        alphaNumericOnly: "Can't type in special characters",
        validName: 'Please enter a valid name',
        validPassportNumber: 'Please enter a valid passport number',
        emailFormat: 'Please enter a valid email address',
        phoneFormat: 'Please enter a valid phone number',
        dateFormat: 'Invalid date',
        nameFormat: 'Please enter a valid name',
        required: 'This field is required',
        bookingnumberFormatForUniworldBrands: 'Booking Reference should have 7 digits only  (example 1234567)',
        bookingnumberFormatForTropicsBrands: 'Booking Reference should have 1 letter and 6 digits only (example A123456)',
    },
    BookingOverview: {
        header: 'Booking Details',
        bookingNumber: 'Booking number:',
        dateBooked: 'Date booked:',
        bookingTotal: 'Booking total:',
        flightsText: 'Flights',
    },
    BookingCard: {
        gratuity: 'Gratuities for the Travel Director and Driver have been pre-paid for',
        day: 'days',
        singleDay: 'day',
        guests: 'Guest(s):',
        night: 'nights',
        roomText: 'room',
        and: 'and',
        dates: 'Dates:',
        accomodation: 'Accommodation:',
        paxText: 'Guest(s):',
        travelProtection: 'Travel protection',
        validDates: 'Valid dates:',
        extraNightBefore: 'extra nights accommodation before',
        extraNightAfter: 'extra nights accommodation after',
        flightPurchased: 'Flights purchased from us',
        flightPurchasedFromOthers: "Flights you've informed us about",
        airline: 'Airline:',
        gratuities: 'Gratuities',
        airportTransfer: 'Airport transfers',
    },
    FlightDetails: {
        localTimesMessage: 'Times shown are in local time',
        tobeConfirmed: 'Airline to be confirmed',
        availableSoon: 'Flight details will be available soon',
        direct: 'Direct',
        stopOver: 'Stop Over',
        cabin: 'Cabin: ',
        guests: 'Guest(s): ',
        operatedBy: 'Operated by',
        future: 'future',
        promised: 'promised',
    },
    PassportNameSection: {
        title: 'Title *',
        firstName: 'First Name *',
        lastName: 'Last Name *',
        middleName: 'Middle Name(s)/initial(s)',
        gender: 'Gender *',
        dob: 'Date of Birth',
    },
    FlightRow: {
        hideFlight: 'Hide Flight Details',
        viewFlight: 'View Flight Details',
        remove: 'Remove',
        guests: 'Guest(s):',
        selectFlightText: 'Select Flight',
        airfareRules: 'View Airfare Rules',
        fuelTaxText: 'Fuel and tax included',
    },
    FlightRowDetailView: {
        airline: 'Airline:',
        flightNumber: 'Flight No:',
        operatedBy: 'Operated by:',
        duration: 'Duration:',
        overnight: 'Overnight:',
        yes: 'Yes',
        cabin: 'Cabin:',
        equipment: 'Equipment:',
        wifiTV: 'WiFi, TV',
        meals: 'Meals',
        breakfastDinner: 'Breakfast, Dinner',
    },
    FlightSearchModal: {
        flightSearchResults: 'FLIGHT SEARCH RESULTS',
        headerText: 'We are sorry but we are unable to offer flights online at this time that match your criteria.',
        description:
            'However, one of our experts can give you a call to arrange flights to help connect you with this trip. Would you like to be contacted after you complete adding other Travel Extras?',
        contactText: 'Yes, I would like to be contacted.',
        successText: 'One of our team will contact you and help to arrange flights.',
        travelExtraButtonText: 'Go To Travel Extras',
        searchAgainText: 'Search Again',
        amaedusFlightsText:
            'Adding flights requires an additional payment. If payment is not received by the due date, flights will be automatically cancelled without notice.',
        noSearchResultsText: 'There are no flights matching with your search criteria, please close your screen and change your search criteria',
        includedFlightsText: 'Flights are included in the total price shown below.',
        flightsOrderText:
            "Flights are not displayed in neutral order. Certain airlines'  fares, schedules or availability information are given preferential treatment in how they are displayed.",
    },
    PassportVerification: {
        heading: 'Passport Information Verification',
        backButton: 'Go Back To Flights Search',
        confirmButton: 'Confirm Passport Information',
        contactCenterText: 'If any of the above information is incorrect, please reach out to our Contact Center before proceeding with your booking.',
        flightBookedWarning: 'Once flights are booked, any changes or cancellations must be made directly through the Contact Center.',
        tripContactText: 'Airline require passenger contact information. Please update your contact information.',
        title: 'Please ensure your name and date of birth are <strong>exactly</strong> as they appear on your passport, including any middle name(s) and/or initials. <strong>Airlines may deny boarding for missing or incorrect information.</strong> ',
        contactInfoTitle: "Airlines require passenger contact information. Please update your clients' details.",
        incorrectInfoTitle: 'If any of the above information is incorrect, please reach out to our Contact Center before proceeding with your booking.',
        flightChangesTitle: 'Once flights are booked, any changes or cancellations must be made directly through the Contact Center.',
    },
    TripContactInfo: {
        fullName: 'Client',
        phoneNumberTitle: 'Mobile Phone Number *',
        phoneNumberTitleNotRequired: 'Mobile Phone Number',
        phoneNumberPlaceholder: 'Phone number...',
        email: 'Email Address *',
        emailNotRequired: 'Email Address',
        emailPlaceholder: 'Enter Email Address...',
    },
    UniWorldTripRegistrationCard: {
        header: 'Trip Registration',
        subHeader: 'Have you completed your registration?',
        description:
            'Before you travel we ask that guests provide some information to make your cruise run smoothly and makes sure we are meeting any requirements that you may have.',
        incomplete: 'Complete Now',
        makeChanges: 'Make Changes',
    },
    Chatter: {
        chatrooms: 'Chatrooms',
        pNameValidationError: '* Required',
        flagText: 'Flag',
        flagSuccessText: 'Message has been successfully flagged',
        flagErrorText: 'Error adding flag: Either the flag already exist or there is issue with network connection ...',
        pNameSaveBtnText: 'Save',
        introMessage: {
            heading: 'Welcome to the $channelName$ group chat!',
            description:
                "Here you'll get to chat with other people on your trip. Where are you from? What destination are you most excited about? This is your opportunity to get to know each other better. And remember - good vibes only!",
        },
        profileTitle: {
            title: 'Your Profile',
            removeProfileAvatar: 'Are you sure you wish to delete your profile picture?',
            btnDelete: 'Delete',
            btnCancel: 'Cancel',
        },
        chatRooms: 'Chatrooms',
        uploadProfile: 'Upload a profile pic',
        removeProfile: 'Remove profile pic',
        fileSizeError: 'There was a problem uploading your image, please make sure the file is smaller than 2MB and try again.',
        fileFormatError: 'Incorrect File Format. Files should be in jpeg/png.',
        chatMessageTitleBeforeTrip: 'The group chat for this Trip opens in ',
        day: 'day',
        days: 'days',
        moduleTripMsg:
            'This trip is made up of “modules”. Fellow travellers will be joining & / or departing the trip at specific changeover cities along the way.',
        tourPackageTripMsg:
            'Please note your $productName$ trip is made up of $tourInstanceCount$ Contiki group(s). You can join the group chat for both parts of your trip now using the two chat tabs. You will be meeting up with some of the people in your chat group a bit later in your trip.',
        chatMessagedetailBeforeTrip:
            "You'll be able to chat to your new travel besties $daysBeforeTripChatIsEnabled$ days before the trip starts and $daysAfterTripChatIsEnabled$ days after the trip ends",
        chatMessageAfterTrip: 'The trip group chat is now closed. Make sure you stay in touch on social and start planning the next adventure!',
        sevenDaysChatCloseWarning:
            'The group chat for this trip will be closing in $daysBetweenEndnCurrent$ $days$! If want to keep in touch with your $brand$ crew, make sure you grab their contact details',
    },
    Sustainability: {
        title: 'Join us and make travel matter',
        Card: {
            header: 'JoinTrafalgar',
            ourStoryTitle: 'Our Story',
            ourStorySubTitle: 'Making travel a force for good',
            ourStoryText:
                '<p>Twelve years ago, our parent company The Travel Corporation (TTC), asked an important question: how do we make travel a force for good?</p><p>Travel is an incredible gift. It can open our eyes, our hearts and our minds to the unique cultures and spellbinding beauty of the natural world. But with this gift comes a responsibility – to protect the world as we know it.</p><p>The TreadRight Foundation is a not-for-profit organisation created to ensure that the impact we have on the people and wildlife we visit, and the planet we call home, is a positive one.</p>',
            whatWeDoTitle: 'What we do',
            whatWeDoSubTitle: 'Over a decade of positive impact',
            whatWeDoText:
                '<p>With more than 55 projects in 26 countries and counting, our shared mission is to tread right upon this earth. Our mission is to support the areas in which travel impacts the most. That’s why our sustainable travel projects fall under the 3 pillars of TreadRight: People, Wildlife, Planet.</p>',
            travelPledgeTitle: 'Make Travel Matter Pledge',
            travelPledgeSubTitle: '',
            travelPledgeText:
                '<p>TTC, along with its family of brands, has stood up to commit to TreadRight’s ethos as travelers, travel providers, and members of the travel industry. We’ve pledged to make travel matter together – for our planet, for people, and for wildlife.</p>',
            pledgeSubTitle: 'The Pledge – these are the words we live by.',
            pledgeText:
                '<p>I will make my travel matter – for our planet, for people and for wildlife.</p><p>When I explore this planet, I will do my best to tread right. I will refuse single use plastics when I can and recycle what I cannot avoid. When possible, I will offset my travels.</p><p>When I meet new people, I will honor their home as I do my own and do so in the spirit of diversity and inclusion. I will purchase locally made items wherever possible and pay a fair price.</p><p>When I experience wildlife, I will do so in nature. I will not ride animals that ought not be ridden, nor support animal cruelty in any way.</p><p>Together, we will tread right upon this earth – and we will make our travel matter.</p>',
            makePledgeButtonText: 'Visit TreadRight and Take the Pledge',
            brandPledgeInfoButtonText: 'Find out more about JoinTrafalgar',
            infoTile: {
                planet: {
                    altText: 'heart icon',
                    heading: 'Planet',
                    description:
                        'Reducing the environmental impact of our business and operations, while supporting projects striving to safeguard the future of our planet.',
                },
                people: {
                    altText: 'eye icon',
                    heading: 'People',
                    description:
                        'Encouraging local communities and cultures to thrive by supporting micro-enterprises and community-based tourism initiatives.',
                },
                wildlife: {
                    altText: 'star icon',
                    heading: 'Wildlife',
                    description: 'Protecting, rehabilitating, and promoting ethical practices for wildlife and the species most at risk.',
                },
            },
        },
    },
    KnowBeforeYouGo: {
        title: '<p class="Hero__knowBeforeYouGoText--title Hero__knowBeforeYouGoText--heroTitleQuestion">How will i stay well on trip?</p><p class="Hero__knowBeforeYouGoText--title Hero__knowBeforeYouGoText--heroTitle">Your wellbeing is our priority</p>',
        Card: {
            header: 'Know Before You Go',
        },
        travelHealth: 'Click here to find out more',
    },
    Sherpa: {
        title: 'Stay in the know',
        description: 'Check the latest travel requirements for your trip',
        mapViewTitle: 'Map View',
    },
    SustainabilitySubscriptionBox: {
        pledgeText: 'Take the Make Travel Matter pledge',
        buttonText: 'Show you care',
        altText: 'Subscription Box Image',
    },
    PrioAddOn: {
        heading: 'Free Time Add-ons Available!',
        warningText:
            'To be able to view all the experiences on offer please ensure your pop-up blockers are switched off in your browser settings – we would also suggest using Google Chrome for best experience.',
        details: 'This trip offers free time add-ons that are available to purchase while on the trip',
        buttonText: 'Browse Experiences',
    },
    ShipNPort: {
        title: 'Ship & Port Information',
        linkText: 'View Port Information',
        contactNumberMsg: 'Ship phone # displayed 45 days prior to departure',
    },
    VersionModal: {
        Card: {
            confirmDetails: 'Confirm Details',
        },
        Form: {
            header: 'Another user has submitted details for this step since you started it (this could be someone travelling with you or your travel agent), which details are correct?',
        },
        modalTitle: 'Which details are correct?',
    },
    PassengerModal: {
        title: 'Would you like to use the same information for any other guests on your booking?',
        heading: 'Select the passengers you wish to copy your updates to',
        buttons: {
            continueButton: 'Continue',
            goBack: 'Go Back',
        },
        Form: {
            header: 'Select guests from the list below if you wish to also update their information with the changes you have provided',
        },
        Callout: {
            messageForPassengerModal: 'You can also update information for other guests by selecting to edit any passenger within Trip Registration.',
        },
    },
    FutureTravelCredit: {
        titleName: 'Future Travel Credit',
        futureCreditButton: 'View Your Future Travel Credit',
    },
    PaymentSummary: {
        header: 'Payment Summary',
        nextPaymentTitle: 'Next payment due date',
        balanceDue: 'Balance Due',
        amountDue: 'Amount Due',
        balance: 'Balance',
        duePaymentSchedule: 'Payment Schedule',
        dueBy: 'due by ',
        description: 'Description',
        amount: 'Amount',
        paymentScheduleTotal: 'Payment Schedule Total',
        fullyPaid: 'Fully Paid',
        zeroDue: '0',
        payeeName: 'Payee Name',
        paymentsReceived: 'Payments Received',
        totalReceived: 'Total Received',
        totalBookingPrice: 'Total Booking Price',
        TransferIn: 'Transfer In',
        TransferOut: 'Transfer Out',
        remainingBalance: 'Remaining Balance',
        cardFeesAmountText: '(incl. $currencyCode$ $feesAmount$ fees)',
        hideStatement: 'Hide Statement',
        showStatement: 'View Full Statement',
        makePayment: 'Make a Payment',
        Discount: {
            earlyPayment: 'Early payment discount',
            payFullyText: 'If you pay fully ',
            appliesToTour: '(Applies to your tour only)',
            save: 'Save ',
            before: 'before ',
            tour: 'Tour:',
            dueAfterDiscountText: 'Amount due after discount',
        },
    },
    Invoice: {
        title: 'Invoice',
        description:
            "You can access a copy of your most recent booking invoice at any time. Click the 'Download Invoice' button below to save a copy to your computer/device.",
        downloadButtonText: 'Download Invoice',
        invoiceError: 'We were unable to download your invoice. Please check your internet connection or try again later.',
    },
    TripSummary: {
        header: 'Booking Summary',
        flightTitle: 'Flight',
        tripStart: 'Trip Start',
        tripEnd: 'Trip End',
        preTripAccomodation: 'Pre-Trip Accommodation',
        postTripAccomodation: 'Post-Trip Accommodation',
        outboundNotAvailable: 'You have not added any outbound flight',
        inboundNotAvailable: 'You have not added any inbound flight',
        preTripNotAvailable: 'You have not added any pre-trip accommodation',
        postTripNotAvailable: 'You have not added any post-trip accommodation',
        night: 'Night',
        nights: 'Nights',
        days: 'Days',
        singleDay: 'Day',
    },
}
