import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Utils } from 'helpers'
import './style.scss'

const Button = ({ innerRef, disabled, type, themeName, className, to, children, confirmBeforeAction, checkProducts, onClick, noStyle, ...props }) => {
    const preventDefaults = e => {
        if (e) {
            if (typeof e.preventDefault === 'function') {
                e.preventDefault()
            }
            if (typeof e.stopPropagation === 'function') {
                e.stopPropagation()
            }
            if (e.nativeEvent && typeof e.nativeEvent.stopImmediatePropagation === 'function') {
                e.nativeEvent.stopImmediatePropagation()
            }
        }
    }
    const buttonClassName = Utils.argsToString('Button', 'button', className, themeName, disabled && 'disabled')
    const noStyleClassName = Utils.argsToString(className, themeName, disabled && 'disabled')
    const disabledButtonProps = {
        disabled,
        'aria-disabled': true,
        onClick: preventDefaults,
    }
    const customOnClick = e => {
        preventDefaults(e)
        e.persist()
        Utils.getUserConfirmation({
            callback: result => {
                if (result) {
                    if (typeof onClick === 'function') {
                        onClick(e)
                    }
                    if (to) {
                        Utils.openUrl({
                            href: to,
                            ...props,
                        })
                    }
                }
            },
            checkCart: checkProducts,
        })
    }
    const buttonProps = {
        ...props,
        className: noStyle ? noStyleClassName : buttonClassName,
        onClick: confirmBeforeAction ? customOnClick : onClick,
        ...(disabled ? disabledButtonProps : {}),
    }

    switch (type.toLowerCase()) {
        case 'button': {
            return (
                <button ref={innerRef} type="button" {...buttonProps}>
                    <i className="Button__arrow" />
                    {children}
                </button>
            )
        }

        case 'link': {
            return (
                <Link innerRef={innerRef} to={disabled ? '#' : to} {...buttonProps}>
                    <i className="Button__arrow" />
                    {children}
                </Link>
            )
        }

        case 'external': {
            return (
                <a ref={innerRef} href={disabled ? '#' : to} {...buttonProps}>
                    <i className="Button__arrow" />
                    {children}
                </a>
            )
        }

        default: {
            return null
        }
    }
}
Button.displayName = 'Button'
Button.propTypes = {
    innerRef: PropTypes.func,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    noStyle: PropTypes.bool,
    confirmBeforeAction: PropTypes.bool,
    type: PropTypes.string,
    themeName: PropTypes.string,
    className: PropTypes.string,
    to: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
Button.defaultProps = {
    innerRef: () => true,
    onClick: null,
    disabled: false,
    noStyle: false,
    confirmBeforeAction: false,
    type: 'button',
    themeName: '',
    className: '',
    to: '',
    children: null,
}

export default Button
