import React from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import CalloutComponent from 'components/Callout'
import { withCard } from './CardContext'

const Callout = ({ componentName, className, themeName, ...calloutProps }) => {
    const classNames = Utils.removeDuplicates(...Utils.stringToArray(className))
    const parentClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentName, '__calloutWrapper'), ...classNames)
    const calloutClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentName, '__callout'))

    return (
        <div className="cell shrink">
            <div className={parentClassName}>
                <CalloutComponent {...calloutProps} className={calloutClassName} />
            </div>
        </div>
    )
}

Callout.displayName = 'CardCallout'
Callout.propTypes = {
    componentName: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    themeName: PropTypes.string.isRequired,
}
Callout.defaultProps = {
    className: '',
}

export default withCard(Callout)
