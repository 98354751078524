import {
    TOGGLE_LOADER,
    TOGGLE_ERROR_MODAL,
    SET_INSURANCE_DATA,
    SHOW_INSURANCE_MODAL,
    TOGGLE_WARNING_MODAL,
    SHOW_INSURANCE_DIFFERENCE,
    SET_FLIGHT_AMOUNT,
    SET_BOOKING_CLOSE,
    SHOW_BOOKING_LIST,
    SET_MODAL_MESSAGE,
    SET_TRANSFER_AIR,
} from 'redux/actionTypes'
import { appData } from 'redux/defaultState'

const App = (state = appData, { type, payload }) => {
    switch (type) {
        case TOGGLE_LOADER: {
            return {
                ...state,
                loaderVisible: payload,
            }
        }

        case TOGGLE_ERROR_MODAL: {
            return {
                ...state,
                errorModalVisible: payload.errorModalVisible,
                isLockedError: payload.isLockedError,
                confirmFlightError: payload.confirmFlightError,
                isAPIError: payload.isAPIError,
                customMessage: payload.customMessage,
                dontShowCloseButton: payload.dontShowCloseButton,
                showViewBooking: payload.showViewBooking,
            }
        }

        case TOGGLE_WARNING_MODAL: {
            return {
                ...state,
                warningModal: {
                    enabled: typeof payload.enabled !== 'undefined' ? payload.enabled : state.warningModal.enabled,
                    visible: typeof payload.visible !== 'undefined' ? payload.visible : state.warningModal.visible,
                    message: typeof payload.message !== 'undefined' ? payload.message : state.warningModal.message,
                    isEnabledForCart: typeof payload.isEnabledForCart !== 'undefined' ? payload.isEnabledForCart : state.warningModal.isEnabledForCart,
                    callback: typeof payload.callback !== 'undefined' ? payload.callback : state.warningModal.callback,
                },
            }
        }

        case SET_MODAL_MESSAGE: {
            const newState = {
                ...state,
            }
            newState.warningModal.message = payload.message
            return newState
        }

        case SHOW_BOOKING_LIST: {
            return {
                ...state,
                showBookingList: payload,
            }
        }

        case SET_INSURANCE_DATA: {
            return {
                ...state,
                insuranceData: payload,
            }
        }
        case SET_TRANSFER_AIR: {
            return {
                ...state,
                flightTransfer: payload,
            }
        }

        case SET_BOOKING_CLOSE: {
            return {
                ...state,
                bookingClose: payload,
            }
        }

        case SHOW_INSURANCE_DIFFERENCE: {
            return {
                ...state,
                showInsuranceDifference: payload,
            }
        }

        case SET_FLIGHT_AMOUNT: {
            return {
                ...state,
                flightAmount: payload,
            }
        }

        case SHOW_INSURANCE_MODAL: {
            return {
                ...state,
                showInsuranceModal: payload,
            }
        }

        default: {
            return state
        }
    }
}

export default App
