import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class TravelExtra {
    static getTransfers(params, config) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_TRANSFERS, { params, ...config })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET TRANSFERS ***'))
    }

    static getHotels(params, config) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_HOTELS, { params, ...config, skipErrorModal: true })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET HOTELS ***'))
    }

    static getGratuity(params, config) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_GRATUITY, { params, ...config, skipErrorModal: true })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET GRATUITY ***'))
    }

    static getAirlines(params, config) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_AIRLINES, { params, ...config, skipErrorModal: true })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET AIRLINES ***'))
    }

    static addProducts(data, params) {
        return Utils.ajax().post(NodeAPIUrl.ADD_PRODUCTS, { data }, { params })
    }

    static checkBookingUpdateStatus(data, params) {
        return Utils.ajax().post(NodeAPIUrl.CHECK_BOOKING_UPDATE_STATUS, { data }, { params })
    }

    static calculateInsurance(data, params) {
        return Utils.ajax().post(NodeAPIUrl.CALCULATE_INSURANCE, { data }, { params, skipErrorModal: true })
    }

    static updateBookingInTropics(data, params) {
        return Utils.ajax().post(NodeAPIUrl.UPDATE_BOOKING_IN_TROPICS, { data }, { params })
    }

    static searchFlights(data, params) {
        return Utils.ajax().post(NodeAPIUrl.SEARCH_FLIGHTS, { data }, { params })
    }

    static getAirfareRules(data, params) {
        return Utils.ajax().post(NodeAPIUrl.GET_AIRFARE_RULES, { data }, { params, skipErrorModal: true })
    }

    static getTransferForAir(data, params) {
        return Utils.ajax().post(NodeAPIUrl.GET_TRANSFER_FOR_AIR, { data }, { params })
    }
}
