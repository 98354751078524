import React from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import parse from 'html-react-parser'
import './styles.scss'

const Callout = ({ componentName, className, size, type, header, description, buttons, children }) => {
    const componentNames = Utils.removeDuplicates(...Utils.addToArray('Callout', componentName))
    const classNames = Utils.removeDuplicates(...Utils.stringToArray(className))
    const parentClassName = Utils.argsToString(
        ...componentNames,
        ...(size ? Utils.addSuffixToEachString(componentNames, `--${size}`) : []),
        ...(type ? Utils.addSuffixToEachString(componentNames, `--${type}`) : []),
        ...classNames,
    )
    const headerClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentNames, '__header'))
    const descriptionClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentNames, '__description'))
    const buttonsClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentNames, '__buttons'))
    const iconClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentNames, '__icon'))

    return (
        <div className={parentClassName}>
            {!!header && (
                <header className={headerClassName}>
                    <span className={iconClassName} />
                    <span>{parse(header)}</span>
                </header>
            )}
            {!!description && (
                <div className={descriptionClassName}>
                    <span>{parse(description)}</span>
                </div>
            )}
            {children}
            {!!buttons && <div className={buttonsClassName}>{buttons}</div>}
        </div>
    )
}

Callout.displayName = 'Callout'
Callout.propTypes = {
    componentName: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    className: PropTypes.string,
    size: PropTypes.oneOf(['', 'small', 'large']),
    type: PropTypes.oneOf(['', 'success', 'alert', 'info', 'error']),
    header: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    buttons: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
Callout.defaultProps = {
    componentName: '',
    className: '',
    size: '',
    type: '',
    header: null,
    description: null,
    buttons: null,
}

export default Callout
