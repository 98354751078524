import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART } from 'redux/actionTypes'
import { cart } from 'redux/defaultState'

const Cart = (state = cart, { type, payload }) => {
    switch (type) {
        case ADD_TO_CART: {
            const existingCartState = state
            existingCartState.push({ productType: payload.productType, ...payload.productData })
            return existingCartState.concat()
        }
        case REMOVE_FROM_CART: {
            const existingCartState = state
            const remRec = existingCartState.filter(rec => !(rec.productType === payload.productType && rec.id === payload.id))
            if (remRec.length === 1 && remRec[0].productType === 'insurance') {
                return []
            }
            return existingCartState.filter(rec => !(rec.productType === payload.productType && rec.id === payload.id)).concat()
        }
        case CLEAR_CART: {
            return []
        }

        default: {
            return state
        }
    }
}

export default Cart
