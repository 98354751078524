/* eslint-disable global-require, no-extend-native */
if (typeof window.Promise === 'undefined') {
    require('core-js/fn/promise')
}

if (typeof Object.assign === 'undefined') {
    require('core-js/fn/object/assign')
}

if (typeof Object.values === 'undefined') {
    require('core-js/fn/object/values')
}

if (typeof Object.entries === 'undefined') {
    require('core-js/fn/object/entries')
}

if (typeof Array.prototype.flatten === 'undefined') {
    if (typeof Array.prototype.flat !== 'undefined') {
        Array.prototype.flatten = Array.prototype.flat
    } else {
        require('core-js/fn/array/flatten')
    }
}

if (typeof Array.prototype.find === 'undefined') {
    require('core-js/fn/array/find')
}

if (typeof Array.prototype.fill === 'undefined') {
    require('core-js/fn/array/fill')
}

if (typeof Array.prototype.includes === 'undefined') {
    require('core-js/fn/array/includes')
}
/* eslint-enable global-require, no-extend-native */
