export default {
    API_TIMEOUT: 120000,
    CHAT_CLIENT_API_KEY: process.env.CHAT_API_KEY,
    CHILD_PASSENGER: 'child',
    ADULT_PASSENGER: 'adult',
    ERROR_CODES: {
        ECONNABORTED: 'ECONNABORTED',
        BAD_REQUEST: 'BAD_REQUEST',
        NOT_ALLOWED: 'NOT_ALLOWED',
        BAD_RESPONSE: 'BAD_RESPONSE',
        ERROR_RESPONSE: 'ERROR_RESPONSE',
        AUTH_FAILED: 'AUTH_FAILED',
        NOT_FOUND: 'NOT_FOUND',
        SERVER_ERROR: 'SERVER_ERROR',
    },
    DAY_TOUR: 'dayTour',
    INTERNAL_AIR_CATEGORIES: [
        'Internal Air Africa',
        'Internal Air Price',
        'Internal Air Price Latin',
        'Internal Air Price N America',
        'Internal Air Tax Africa',
        'Internal Air Taxes & Surcharges Latin',
        'Internal Air Taxes & Surcharges N America',
        'Intra Tour Air',
        'Intra Tour Air Asia',
        'Intra Tour Air Fuel Surcharge',
        'Intra Tour Air Tax',
        'Intra Tour Air Tax South Pacific',
        'IntraTourAirTaxAsia',
    ],
    CLIENT_DIRECT: 'Client Direct',
    DATE_FORMAT: 'ddd, DD MMM YYYY',
    DATE_FORMAT_YYYY_MM_DD: 'YYYY-MM-DD',
    DATE_FORMAT_DD_MMM_YYYY: 'DD MMM YYYY',
    DATE_FORMAT_DDD_DD_MMM_YYYY: 'ddd DD MMM, YYYY',
    DATE_FORMAT_eee_dd_MMM_yyyy: 'eee, dd MMM yyyy',
    DEPARTURE_AIRPORT: 'departureAirport',
    ARRIVAL_AIRPORT: 'arrivalAirport',
    DEPARTURE_DATE: 'departureDate',
    DEFAULT_NUMBER_OF_ROW_MULTICITY: 4,
    PRODUCT_TYPE: {
        TRANSFER: 'transfer',
        FLIGHT: 'flights',
        HOTEL: 'hotel',
    },
    PRODUCT_SUB_TYPE: {
        AFTER: 'after',
        BEFORE: 'before',
    },
    DEPARTURE: 'departure',
    ARRIVAL: 'arrival',
    EPD_DISCOUNT: 'EPD',
    DEPOSIT: 'Deposit',
    INTERIM_PAYMENT: 'Interim Payment',
    FINAL_PAYMENT: 'Final Payment',
    FULLY_PAID: 'fullyPaid',
    OVER_PAID: 'overPaid',
    BOOKING_TYPE_CLIENT_DIRECT: 'Client Direct',
    BOOKING_TYPE_TRAVEL_AGENT: 'Travel Agent',
    BOOKING_TYPE_GROUP_BOOKING: 'Group Allocation',
    BOOKING_TYPE_SPECIAL_GROUP: 'Special Group',
    SHERPA_SDK_URL: process.env.SHERPA_SDK_URL,
    SHERPA_APP_ID: process.env.SHERPA_APP_ID,
    PREPAID_GRATUITIES: 'prepaidGratuities',
    TRAVEL_PROTECTION: 'travelProtection',
    ACCOMMODATION_PRE: 'accommodationPre',
    ACCOMMODATION_POST: 'accommodationPost',
    DEFAULT_TIME_SEARCH_FLIGHT: 'T15:00',
    TOUR_STATUS: 'confirmed',
    AIRPORT_TRANSFER: 'Transfer Airport',
    DAYS: 'days',
    GENESYS_COBROWSE_SRC: process.env.GENESYS_COBROWSE_SRC,
    GENESYS_COBROWSE_URL: process.env.GENESYS_COBROWSE_URL,
    GENESYS_DATA_URL: process.env.GENESYS_DATA_URL,
    FLIGHTCLASS: [
        {
            value: 'Any',
            label: 'Any',
            id: 'Any',
        },
        {
            value: 'Economy',
            label: 'Economy',
            id: 'Economy',
        },
        {
            value: 'PremiumEconomy',
            label: 'Premium Economy',
            id: 'PremiumEconomy',
        },
        {
            value: 'Business',
            label: 'Business',
            id: 'Business',
        },
        {
            value: 'First',
            label: 'First',
            id: 'First',
        },
    ],
    TIMELIST: [
        {
            value: 'Any time',
            label: 'Any time',
            id: 'Any time',
        },
        {
            value: '01:00',
            label: '01:00',
            id: '01:00',
        },
        {
            value: '02:00',
            label: '02:00',
            id: '02:00',
        },
        {
            value: '03:00',
            label: '03:00',
            id: '03:00',
        },
        {
            value: '04:00',
            label: '04:00',
            id: '04:00',
        },
        {
            value: '05:00',
            label: '05:00',
            id: '05:00',
        },
        {
            value: '06:00',
            label: '06:00',
            id: '06:00',
        },
        {
            value: '07:00',
            label: '07:00',
            id: '07:00',
        },
        {
            value: '08:00',
            label: '08:00',
            id: '08:00',
        },
        {
            value: '09:00',
            label: '09:00',
            id: '09:00',
        },
        {
            value: '10:00',
            label: '10:00',
            id: '10:00',
        },
        {
            value: '11:00',
            label: '11:00',
            id: '11:00',
        },
        {
            value: '12:00',
            label: '12:00',
            id: '12:00',
        },
        {
            value: '13:00',
            label: '13:00',
            id: '13:00',
        },
        {
            value: '14:00',
            label: '14:00',
            id: '14:00',
        },
        {
            value: '15:00',
            label: '15:00',
            id: '15:00',
        },
        {
            value: '16:00',
            label: '16:00',
            id: '16:00',
        },
        {
            value: '17:00',
            label: '17:00',
            id: '17:00',
        },
        {
            value: '18:00',
            label: '18:00',
            id: '18:00',
        },
        {
            value: '19:00',
            label: '19:00',
            id: '19:00',
        },
        {
            value: '20:00',
            label: '20:00',
            id: '20:00',
        },
        {
            value: '21:00',
            label: '21:00',
            id: '21:00',
        },
        {
            value: '22:00',
            label: '22:00',
            id: '22:00',
        },
        {
            value: '23:00',
            label: '23:00',
            id: '23:00',
        },
        {
            value: '24:00',
            label: '24:00',
            id: '24:00',
        },
    ],
    AIRLINELIST: [
        {
            value: 'Any',
            label: 'Any',
            id: 'Any',
        },
        {
            value: 'EI',
            label: 'Aer Lingus',
            id: 'EI',
        },
        {
            value: 'AB',
            label: 'Air Berlin',
            id: 'AB',
        },
        {
            value: 'AC',
            label: 'Air Canada',
            id: 'AC',
        },
        {
            value: 'AF',
            label: 'Air France',
            id: 'AF',
        },
        {
            value: 'NZ',
            label: 'Air New Zealand',
            id: 'NZ',
        },
        {
            value: 'CT',
            label: 'Alitalia S.a.S.p.a',
            id: 'CT',
        },
        {
            value: 'AA',
            label: 'American Airlines',
            id: 'AA',
        },
        {
            value: 'OS',
            label: 'Austrian Airlines',
            id: 'OS',
        },
        {
            value: 'AV',
            label: 'Avianca',
            id: 'AV',
        },
        {
            value: 'BA',
            label: 'British Airways',
            id: 'BA',
        },
        {
            value: 'SN',
            label: 'Brussels Airlines',
            id: 'SN',
        },
        {
            value: 'DL',
            label: 'Delta Airlines',
            id: 'DL',
        },
        {
            value: 'EK',
            label: 'Emirates',
            id: 'EK',
        },
        {
            value: 'EY',
            label: 'Etihad Airways',
            id: 'EY',
        },
        {
            value: 'BR',
            label: 'Eva Airways',
            id: 'BR',
        },
        {
            value: 'AY',
            label: 'Finnair',
            id: 'AY',
        },
        {
            value: 'IB',
            label: 'Iberia',
            id: 'IB',
        },
        {
            value: 'FI',
            label: 'Iceland Air',
            id: 'FI',
        },
        {
            value: 'KL',
            label: 'Klm Royal Dutch Airlines',
            id: 'KL',
        },
        {
            value: 'KE',
            label: 'Korean Air',
            id: 'KE',
        },
        {
            value: 'LP',
            label: 'Lan Peru',
            id: 'LP',
        },
        {
            value: 'XL',
            label: 'Latam Airlines Ecuador',
            id: 'XL',
        },
        {
            value: 'LA',
            label: 'Latam Airlines Group',
            id: 'LA',
        },
        {
            value: 'LO',
            label: 'Lot Polish Airlines',
            id: 'LO',
        },
        {
            value: 'LH',
            label: 'Lufthansa',
            id: 'LH',
        },
        {
            value: 'QF',
            label: 'Qantas Airways',
            id: 'QF',
        },
        {
            value: 'SK',
            label: 'Scandinavian Airlines',
            id: 'SK',
        },
        {
            value: 'SA',
            label: 'South African Airways',
            id: 'SA',
        },
        {
            value: 'LX',
            label: 'Swiss International Air Lines',
            id: 'LX',
        },
        {
            value: 'TK',
            label: 'Turkish Airlines Inc',
            id: 'TK',
        },
        {
            value: 'UN',
            label: 'United Airlines',
            id: 'UN',
        },
        {
            value: 'VA',
            label: 'Virgin Atlantic',
            id: 'VA',
        },
    ],
    FILTERLIST: [
        {
            value: 'asc',
            label: 'Price: Ascending',
            id: 'asc',
        },
        {
            value: 'desc',
            label: 'Price: Descending',
            id: 'desc',
        },
    ],
}
