import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Buttons'
import { Link, withRouter } from 'react-router-dom'
import CallUsButton from 'components/CallUsButton'
import NavigationBarItem from 'components/NavigationBarItem'
import { connect } from 'react-redux'
import * as actions from 'redux/actions'
import { bindActionCreators } from 'redux'
import { Utils, Analytics, Cart } from 'helpers'
import { AppConstant } from 'constants'
import './styles.scss'

const {
    Header: {
        urls: { brandSite: urlBrandSite },
    },
    App: { showBasket },
} = Utils.getConfigs()

const {
    Header: {
        CallUsButton: { suffix },
        myTripsMD,
        callNow,
        chat,
        viewBasket,
    },
    CallUsButton: { defaultPhoneNumber, sellingCompanyToPhoneNumberMapping },
} = Utils.getTranslations()

class NavigationBar extends Component {
    static displayName = 'NavigationBar'

    static propTypes = {
        actions: PropTypes.shape({}).isRequired,
        sellingCompanyShortName: PropTypes.string.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            isActiveNavigation: false,
            callChecked: false,
            personChecked: false,
        }
    }

    componentDidMount = () => {
        document.body.addEventListener('click', e => {
            if (
                e.target &&
                e.target.className &&
                typeof e.target.className.indexOf === 'function' &&
                (e.target.className.indexOf('NavigationBar') < 0 || e.target.className.indexOf('NavigationBar__topMenu__tripLink') >= 0)
            ) {
                if (this && this.setState) {
                    this.setState({ callChecked: false, personChecked: false })
                }
            }
        })
    }

    toggleNavigation = (e, isDesktop, isMyTrip = false) => {
        const {
            actions: { showBookingList, setBookingClose },
        } = this.props
        showBookingList(true)
        if (isMyTrip) {
            setBookingClose(true)
        }

        if (!isDesktop) {
            this.setState(prevState => ({
                isActiveNavigation: !prevState.isActiveNavigation,
            }))
        }
    }

    logoutClick = e => {
        const {
            actions: { toggleLoader, setBookingClose },
        } = this.props
        e.preventDefault()
        sessionStorage.removeItem('insecurelogin')
        sessionStorage.removeItem('firstname')
        sessionStorage.removeItem('lastname')
        sessionStorage.removeItem('bookingnumber')
        setBookingClose(true)
        if (typeof SsoHelper !== 'undefined') {
            const ssoHelper = new SsoHelper()
            if (typeof ssoHelper.logout === 'function') {
                ssoHelper.logout()
                toggleLoader(true)
                setTimeout(() => {
                    toggleLoader(false)
                    window.location.href = urlBrandSite
                }, 3000)
            }
        }
        const params = {
            action: 'logout',
            category: 'user_logout',
            label: 'User Logged out',
        }
        Analytics.click(params)
    }

    onClick = (e, context) => {
        let callChecked = false
        let personChecked = false
        if (context === 'call') {
            if (e.target && e.target.checked) {
                callChecked = true
                personChecked = false
            } else {
                callChecked = false
                personChecked = false
            }
        }
        if (context === 'person') {
            if (e.target && e.target.checked) {
                callChecked = false
                personChecked = true
            } else {
                callChecked = false
                personChecked = false
            }
        }
        if (context === 'blur') {
            callChecked = false
            personChecked = false
        }
        this.setState({ callChecked, personChecked })
    }

    render() {
        const { isActiveNavigation, callChecked, personChecked } = this.state
        const { sellingCompanyShortName, unReadCount, activeBookingDetails, isfutureTravelCredit, cart, activeBookingType, tripStarted, appData } = this.props
        const { isChatterEnabled } = activeBookingDetails
        const {
            App: { showContactNumbers },
        } = Utils.getConfigs()
        let phoneNumber = defaultPhoneNumber
        const isClientDirect = activeBookingType === AppConstant.BOOKING_TYPE_CLIENT_DIRECT
        if (sellingCompanyToPhoneNumberMapping && sellingCompanyToPhoneNumberMapping[sellingCompanyShortName]) {
            phoneNumber = sellingCompanyToPhoneNumberMapping[sellingCompanyShortName]
        }
        const clickPhoneNumber = phoneNumber.replace(/ /g, '')
        const totalPrice = Cart.getTotalPrice(cart)
        const currencyCode = Utils.getCurrencyCode()
        const numberOfItems = Cart.getNumberOfItems(cart)
        let finalAmount = totalPrice
        if (finalAmount > 0 && appData.showInsuranceDifference && appData.showInsuranceDifference > 0) {
            finalAmount = Number(Number(finalAmount) + appData.showInsuranceDifference).toFixed(2)
        }

        return (
            <div className="NavigationBar cell small-4 large-7 medium-6 small-order-1 medium-order-2">
                <nav role="navigation" className="show-for-small-only">
                    <div className="NavigationBar__menuToggle">
                        <input type="checkbox" onChange={() => {}} checked={isActiveNavigation} onClick={this.toggleNavigation} />
                        <span className="NavigationBar__icon" />
                        <ul className="NavigationBar__menu">
                            <li>
                                <NavigationBarItem logoutClick={this.logoutClick} onClick={this.toggleNavigation} />
                            </li>
                            {showContactNumbers && (
                                <li className="NavigationBar--callAgent">
                                    <CallUsButton suffix={suffix} />
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>

                <nav className="align-right simple hide-for-small-only">
                    <div role="navigation" className="NavigationBar__topMenu">
                        {currencyCode && showBasket && isClientDirect && !tripStarted && window.location.pathname !== '/' && (
                            <div className="NavigationBar__basketSection">
                                {`${numberOfItems} item(s)`} <strong className="NavigationBar__basketSection--price">{`${currencyCode} ${finalAmount}`}</strong>
                                <Button
                                    disabled={!(cart.length > 0 && window.location.pathname !== '/travelbasket')}
                                    className="primary medium-down-expanded NavigationBar__basket"
                                    onClick={() => {
                                        const { history } = this.props
                                        history.push('/travelbasket')
                                    }}
                                >
                                    {viewBasket}
                                </Button>
                            </div>
                        )}
                        {isChatterEnabled && !isfutureTravelCredit && (
                            <div className="NavigationBar__topMenu__item NavigationBar__topMenu__chat">
                                <Link to="chat" className="NavigationBar__topMenu__chatLink">
                                    <div
                                        className={`NavigationBar__chat__arrow NavigationBar__chat__unread ${
                                            unReadCount > 0 ? 'NavigationBar__chat--messages NavigationBar__chat__unread--messages' : ''
                                        }`}
                                    >
                                        <span>{unReadCount || 0}</span>
                                    </div>
                                    {chat}
                                </Link>
                            </div>
                        )}
                        <div className="NavigationBar__topMenu__item">
                            <Link onClick={e => this.toggleNavigation(e, true, true)} to="/" className="NavigationBar__topMenu__tripLink" id="my-trips">
                                {myTripsMD}
                            </Link>
                        </div>
                        {sellingCompanyShortName && sellingCompanyToPhoneNumberMapping && sellingCompanyToPhoneNumberMapping[sellingCompanyShortName] && (
                            <div className="NavigationBar__topMenu__item">
                                <input
                                    className="NavigationBar__input"
                                    checked={callChecked}
                                    onChange={() => {}}
                                    onClick={e => this.onClick(e, 'call')}
                                    type="checkbox"
                                />
                                {showContactNumbers && (
                                    <div>
                                        <span className="NavigationBar__topMenu__icon NavigationBar__topMenu__icon--phoneIcon" />
                                        <ul className="NavigationBar__topMenu--submenu">
                                            <li className="NavigationBar--callAgent">
                                                <CallUsButton suffix={suffix} />
                                            </li>
                                            <li className="NavigationBar--callButton">
                                                <a href={`tel:${clickPhoneNumber}`}>
                                                    <Button className="medium-expanded primary show-for-medium">{callNow}</Button>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="NavigationBar__topMenu__item">
                            <input
                                className="NavigationBar__input"
                                onChange={() => {}}
                                checked={personChecked}
                                onClick={e => this.onClick(e, 'person')}
                                type="checkbox"
                            />
                            <span className="NavigationBar__topMenu__icon NavigationBar__topMenu__icon--accountIcon" />
                            <ul className="NavigationBar__topMenu--submenu NavigationBar__personMenu">
                                <li>
                                    <NavigationBarItem logoutClick={this.logoutClick} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch),
})

const mapStateToProps = state => ({
    firstname: state.userData.firstname,
    lastname: state.userData.lastname,
    email: state.userData.email,
    cart: state.cart,
    unReadCount: state.chatter.unReadCount,
    tripStarted: state.activeBooking.tripStarted,
    sellingCompanyShortName: state.activeBooking && state.activeBooking.sellingCompany && state.activeBooking.sellingCompany.sellingCompanyShortName,
    isfutureTravelCredit: state.activeBooking.isfutureTravelCredit,
    activeBookingType: state.activeBooking.bookingNumber.bookingType,
    activeBookingDetails: state.activeBookingDetails,
    appData: state.appData,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationBar))
