import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class User {
    static getUserDetails(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.USER_DETAIL, { params })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET CHAT USER TOKEN ***'))
    }

    static getLoggedInUserInfo() {
        return Utils.ajax()
            .get(NodeAPIUrl.LOGGED_IN_USER_INFO)
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN LOGGED IN USER INFO ***'))
    }
}
