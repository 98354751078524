import { SET_BOOKING_DETAILS_FOR_OVERVIEW, SET_PAYMENT_FORM_RESPONSE, SET_PAYMENT_FAILED_ERROR, SET_GET_BOOKING_DETAILS_FAILED_ERROR } from 'redux/actionTypes'

export const setBookingDetailsForOverview = bookingDetails => ({
    type: SET_BOOKING_DETAILS_FOR_OVERVIEW,
    payload: bookingDetails,
})

export const setPaymentFormResponse = payload => ({
    type: SET_PAYMENT_FORM_RESPONSE,
    payload,
})

export const setPaymentFailedError = payload => ({
    type: SET_PAYMENT_FAILED_ERROR,
    payload,
})
export const setGetBookingDetailsFailedError = payload => ({
    type: SET_GET_BOOKING_DETAILS_FAILED_ERROR,
    payload,
})
