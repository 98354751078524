import axios from 'axios'
import JSZip from 'jszip'
import merge from 'deepmerge'
import FileSaver from 'file-saver'
import React, { createContext as ReactCreateContext } from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import Configs from 'configs'
import Translations from 'translations'
import { AppConstant } from 'constants'
import { setBookingClose, toggleWarningModal } from 'redux/actions'

const deepmergeOptions = { arrayMerge: (destinationArray, sourceArray) => sourceArray }
let uniqueId = 1

export default class Utils {
    static store = null

    static openUrl(props) {
        const { download, href, url, rel, target } = props

        Object.assign(document.createElement('a'), {
            href: href || url,
            ...(download ? { download } : {}),
            ...(rel ? { rel } : {}),
            ...(target ? { target } : {}),
        }).click()
    }

    static urlify(text) {
        const urlRegex = /(https?:\/\/[^\s]+)/g
        return text.replace(urlRegex, url => `<a target="_blank" href="${url}">${url}</a>`)
    }

    static getUserConfirmation({ message, callback, checkCart }) {
        const cb = typeof callback === 'function' ? callback : this.noop
        const { dispatch, getState } = this.store
        const {
            appData: {
                warningModal: { enabled, isEnabledForCart, message: errorMessage },
                bookingClose,
            },
        } = getState()
        if (enabled || (isEnabledForCart && bookingClose) || (isEnabledForCart && checkCart)) {
            dispatch(
                toggleWarningModal({
                    visible: true,
                    message: errorMessage || message,
                    callback: result => {
                        if (result) {
                            dispatch(toggleWarningModal({ enabled: false, isEnabledForCart: false }))
                            dispatch(setBookingClose(false))
                        }
                        dispatch(setBookingClose(false))
                        cb(result)
                    },
                }),
            )
        } else {
            cb(true)
        }
    }

    static ajax() {
        return axios
    }

    static setToMounted(component) {
        component.isUnMounted = false
        return component
    }

    static setToUnmounted(component) {
        component.isUnMounted = true
        return component
    }

    static isMounted(component) {
        return typeof component.isUnMounted === 'boolean' && !component.isUnMounted
    }

    static isUnmounted(component) {
        return typeof component.isUnMounted === 'boolean' && component.isUnMounted
    }

    static getConfigs(key) {
        const configs = merge.all([Configs.base, Configs[process.env.BRAND]], deepmergeOptions)
        return typeof key === 'string' ? configs[key] : configs
    }

    static isUniworldBrand() {
        const {
            App: { brandId },
        } = this.getConfigs()
        return brandId === 'ubyuniworld' || brandId === 'uniworld'
    }

    static getTranslations(key) {
        const translations = merge.all([Translations.base, Translations[process.env.BRAND]], deepmergeOptions)
        return typeof key === 'string' ? translations[key] : translations
    }

    static getCurrencyCode() {
        const { getState = () => {} } = this.store || {}
        const {
            App: { sellingCompanyCurrencySymbols = {} },
        } = this.getConfigs()
        const { activeBooking = {} } = getState() || {}
        const { sellingCompany = {} } = activeBooking
        const { sellingCompanyShortName = '' } = sellingCompany
        const currencyCode = sellingCompanyCurrencySymbols[sellingCompanyShortName.substring(2)]
        return currencyCode
    }

    static getResponseData(response, skipLengthCheck) {
        const { data = {} } = response
        if (response && data && ((Array.isArray(data) && (skipLengthCheck === true || data.length)) || !Array.isArray(data))) {
            return Promise.resolve(data)
        }
        return Promise.reject(new Error('No Data found'))
    }

    static throwError(error, message) {
        this.consoleErr(message, { error })
        throw error
    }

    static checkImage(imageSrc, onError, onLoad) {
        if (!imageSrc) {
            onError()
        } else {
            const img = new window.Image()
            img.onload = () => onLoad(img)
            img.onerror = () => onError(imageSrc)
            img.src = imageSrc
        }
    }

    static openInNewTab(url, name = '_blank') {
        window.open(url, name)
    }

    static isHtml(str) {
        const htmlRegex = new RegExp('<([A-Za-z][A-Za-z0-9]*)\\b[^>]*>(.*?)</\\1>')
        return htmlRegex.test(str)
    }

    static stringToBinary(string) {
        return atob(string.replace(/\s/g, ''))
    }

    static binaryToBuffer(binary) {
        const len = binary.length
        const buffer = new Uint8Array(new ArrayBuffer(len))

        for (let i = 0; i < len; i += 1) {
            buffer[i] = binary.charCodeAt(i)
        }

        return buffer
    }

    static bufferToBlob(buffer, docType) {
        return new Blob([buffer], { type: docType })
    }

    static createZIP(files) {
        const zip = new JSZip()

        files.forEach(({ content, name }) => zip.file(name, content, { binary: true }))

        return zip.generateAsync({ type: 'blob' })
    }

    static createPDF(content) {
        const binary = this.stringToBinary(content)
        const buffer = this.binaryToBuffer(binary)

        return this.bufferToBlob(buffer, 'application/pdf')
    }

    static saveAs(content, fileName) {
        return FileSaver.saveAs(content, fileName)
    }

    static consoleLog(...args) {
        /* eslint-disable-next-line no-console */
        console.log('----------------- *** INFO *** -----------------\n', ...args, '\n----------------- *** INFO *** -----------------')
    }

    static consoleErr(...args) {
        /* eslint-disable-next-line no-console */
        console.error('----------------- *** ERROR *** -----------------\n', ...args, '\n----------------- *** ERROR *** -----------------')
    }

    static noop() {}

    static addToArray(arg1, arg2) {
        return [arg1, ...(Array.isArray(arg2) ? arg2 : [arg2])].filter(Boolean)
    }

    static removeDuplicates(...args) {
        return args.filter((val, index, arr) => !arr.slice(index + 1).includes(val))
    }

    static stringToArray(string, separator = ' ') {
        return string.split(separator)
    }

    static argsToString(...args) {
        return args.filter(Boolean).join(' ')
    }

    static addSuffixToEachString(strings, ...suffixes) {
        const stringsArray = strings.filter(Boolean)
        const suffixesArray = suffixes.filter(Boolean)
        return suffixesArray.map(suffix => stringsArray.map(string => `${string}${suffix}`)).flatten(suffixesArray.length)
    }

    static getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        if (parts.length === 2) {
            return parts.pop().split(';').shift()
        }
        return ''
    }

    static getUID(prefix) {
        try {
            uniqueId += 1
        } catch (ignore) {
            uniqueId = 1
        }
        return `${prefix || ''}_${uniqueId}${(+Math.random().toString().substring(2)).toString(24)}${(+new Date()).toString(24)}`
    }

    static replaceParamsInString(string = '', params = {}) {
        Object.entries(params).forEach(([key, value]) => {
            string = string.split(`$${key}$`).join(value)
        })

        return string
    }

    static getParameterByName(name, url = window.location.href) {
        /* eslint-disable no-useless-escape */
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
        const results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
    }

    static changeTextForChat(success, url) {
        /* eslint-disable no-undef */
        const {
            Chatter: { flagSuccessText, flagErrorText },
        } = this.getTranslations()
        const isFlagCall = url.indexOf('flag') >= 0
        setTimeout(() => {
            if (success) {
                if (isFlagCall) {
                    $('.notification-success').text(flagSuccessText)
                }
                $('.notification-success').show()
            } else {
                if (isFlagCall) {
                    $('.notification-error').text(flagErrorText)
                }
                $('.notification-error').show()
            }
        }, 500)
    }

    static createContext = (name = '') => {
        const context = ReactCreateContext()

        context.displayName = `${name || 'Anonymous'}`
        context.Provider.displayName = `${name || 'Anonymous'}.Provider`
        context.Consumer.displayName = `${name || 'Anonymous'}.Consumer`

        return context
    }

    static withContext = (Context, Component) => {
        const contextName = Context.displayName || Context.name || 'Anonymous'
        const componentName = Component.displayName || Component.name || 'Anonymous'
        const ComponentWithContext = props => <Context.Consumer>{context => <Component {...props} {...context} />}</Context.Consumer>

        ComponentWithContext.displayName = `with${contextName}(${componentName})`

        return hoistNonReactStatics(ComponentWithContext, Component)
    }

    static scrollToTop = (scrollToTarget, ...args) =>
        new Promise(resolve => {
            try {
                scrollToTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
            } catch (ignore) {
                window.scrollTo(0, 0)
            }

            return resolve(...args)
        })

    static isMobile = () => ({
        Android() {
            return navigator.userAgent.match(/Android/i)
        },
        BlackBerry() {
            return navigator.userAgent.match(/BlackBerry/i)
        },
        iOS() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i)
        },
        Opera() {
            return navigator.userAgent.match(/Opera Mini/i)
        },
        Windows() {
            return navigator.userAgent.match(/IEMobile/i)
        },
        any() {
            return this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows()
        },
    })

    static getPassengerParams = (firstName, lastName) => {
        const fName = firstName && firstName.toLowerCase().replace(/ /g, '+')
        const lName = lastName && lastName.toLowerCase().replace(/ /g, '+')
        return `?firstName=${fName || ''}&lastName=${lName || ''}`
    }

    static getOrigin(url) {
        const expression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        const regex = new RegExp(expression)
        if (url && typeof url === 'string' && url.match(regex)) {
            const pathArray = url.split('/')
            const protocol = pathArray[0]
            const host = pathArray[2]
            return `${protocol}//${host}`
        }
        return ''
    }

    static isPaxHaveInternalAir(tours = [], paxId) {
        let hasInternalAir = false

        tours.forEach(tour => {
            const { included = [], additionalProducts = [] } = tour
            let internalAir = included.find(rec => AppConstant.INTERNAL_AIR_CATEGORIES.includes(rec.productCategory))
            if (!internalAir) {
                internalAir = included.find(rec => rec.name && rec.name.indexOf('Intra Tour Air') >= 0)
            }
            if (!internalAir) {
                internalAir = additionalProducts.find(rec => rec.name && rec.name.indexOf('Intra Tour Air') >= 0)
            }
            if (internalAir && internalAir.passengerIds) {
                if (internalAir.passengerIds.indexOf(paxId) >= 0) {
                    hasInternalAir = true
                }
            }
        })
        return hasInternalAir
    }
}
