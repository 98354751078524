export default {
    MyBrand: { name: 'Trafalgar', fullName: 'Trafalgar', mtpName: 'MyTrafalgar' },
    Header: { labelBrandSite: 'Back to Trafalgar.com' },
    Footer: { copyright: 'Copyright $year$ Trafalgar.' },
    CallUsButton: {
        defaultPhoneNumber: '0808 256 7995',
        sellingCompanyToPhoneNumberMapping: {
            TTSYDS: '1800 002 007',
            TTCANS: '800 352 4444',
            TTEUOS: '0808 281 1126',
            TTAKLS: '0800 484 333',
            TTSINS: '+65 6922 5965',
            TTJBGS: '+27 011 280 8440',
            TTUSAS: '866 513 1995',
            TTUKLS: '0808 301 2324',
        },
    },
    Hero: {
        greeting: { guest: 'Welcome to Trafalgar', member: 'Welcome to my Trafalgar', prefix: 'Hello' },
        alertMessage:
            "<strong>Travel Alert</strong> - COVID 19 - We are here and we want to help you. If you are concerned about your travel plans please <a href='https://www.trafalgar.com/coronavirus-travel-update' target='_blank'>click here</a>",
        healthAlertMessage:
            "<strong>Travel with confidence</strong> - High Hygiene standards and your health are our priority. <a href='https://www.trafalgar.com/en-us/resources/travel-health' target='_blank'>Learn more</a>",
    },
    SignUpCard: {
        haveAccountDescription: 'Your best trip starts here.',
        description:
            'All guests within a booking must create their own account. Register your details to personalize your trip and receive all your travel documents via email, quickly and conveniently.',
        description1: 'We can’t wait for you to experience the world with us and make a positive impact to the people, planet and wildlife on every tour.',
        description2: 'Together, WE MAKE TRAVEL MATTER®.',
    },
}
