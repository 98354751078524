/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Utils } from 'helpers'
import widgetConfig from './widget.config'
import cobrowseConfig from './cobrowse.config'
import './styles.scss'

const {
    App: { showGenesysWidget, showCobrowseWidget },
} = Utils.getConfigs()

class GenesysWidget extends React.Component {
    constructor(props) {
        super(props)
        if (!window._genesys) window._genesys = {}
        if (!window._gt) window._gt = []
        window._genesys.widgets = widgetConfig

        if (showCobrowseWidget) {
            window._genesys.widgets.cobrowse = cobrowseConfig.cobrowse
            window._genesys.widgets.sidebar.channels.push(cobrowseConfig.callToCobrowse)
        }

        if (showGenesysWidget) {
            this.loadGenesysWidget()
        }
    }

    loadGenesysWidget = async () => {
        const widgetBaseUrl = process.env.GENESYS_URL
        const widgetScriptElement = document.createElement('script')

        widgetScriptElement.setAttribute('src', `${widgetBaseUrl}cxbus.min.js`)
        widgetScriptElement.addEventListener('load', () => {
            CXBus.configure({ debug: false, pluginsPath: `${widgetBaseUrl}plugins/` })
            CXBus.loadPlugin('widgets-core')
        })
        document.head.append(widgetScriptElement)
    }

    render() {
        const { children } = this.props
        return children
    }
}
GenesysWidget.displayName = 'GenesysyWidget'

export default GenesysWidget
