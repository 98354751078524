const appData = {
    loaderVisible: false,
    errorModalVisible: false,
    showBookingList: false,
    bookingClose: false,
    showInsuranceDifference: false,
    showInsuranceModal: false,
    flightAmount: false,
    insuranceData: false,
    flightTransfer: false,
    warningModal: {
        enabled: false,
        visible: false,
        message: '',
        callback: null,
    },
}
const userData = {
    authToken: false,
    refreshBookingsList: '',
    firstname: '',
    lastname: '',
    email: '',
    preferredName: '',
    avatar: '',
    ssoId: '',
}
const bookings = []
const cart = []
const activeBooking = {
    id: '',
    bookingPassenger: {
        id: '',
        clientID: '',
        leadPassenger: false,
    },
    number: '',
    bookingNumber: {
        name: '',
        bookingType: '',
    },
    sellingCompany: {
        sellingCompanyShortName: '',
    },
    startLocation: '',
    endLocation: '',
    startDate: '',
    endDate: '',
    productType: '',
    productName: '',
    product: {
        id: '',
        name: '',
        backgroundUrl: '',
        mapURL: '',
    },
    mtpBookingId: '',
    today: '',
    tripStarted: false,
    tripEnded: false,
    daysToGo: null,
    tripDay: null,
    isCurrentBooking: false,
    isFutureBooking: false,
    activeChannel: [],
}
const activeBookingDetails = {
    bookingType: '',
    eDocs: false,
    documentsComplete: false,
    passengers: [],
    paxesWithBothRegAndPrefComplete: 0,
    paxesWithRegComplete: 0,
    paxesWithPrefComplete: 0,
    bookingVersion: '',
    payments: [],
    paymentsSchedule: [],
    hotels: {},
    gratuity: {},
    airlines: [],
    additionalProducts: [],
    instalmentPlan: false,
}
const activeDocuments = []

export { bookings, activeBooking, activeBookingDetails, activeDocuments, appData, userData, cart }
