import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class Itinerary {
    static getItineraryData(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_ITINERARY, { params })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET ITINERARY DATA ***'))
    }

    static getItineraryDataFromTropics(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_ITINERARY_FROM_TROPICS, { params })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET ITINERARY DATA FROM TROPICS ***'))
    }

    static getItineraryDetail(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_ITINERARY_DETAIL, { params })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET ITINERARY DETAIL ***'))
    }
}
