import base from './base'
import contiki from './contiki'
import trafalgar from './trafalgar'
import insightvacations from './insightvacations'
import costsaver from './costsaver'
import luxurygold from './luxurygold'
import aatkings from './aatkings'
import getours from './getours'
import brendanvacations from './brendanvacations'
import ubyuniworld from './ubyuniworld'
import uniworld from './uniworld'

export default { base, contiki, trafalgar, insightvacations, costsaver, luxurygold, aatkings, getours, brendanvacations, ubyuniworld, uniworld }
