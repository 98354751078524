import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class EDocs {
    static getDocuments(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_EDOCS_DOCLIST, { params })
            .catch(error => Utils.throwError(error, '*** ERROR IN GET DOCUMENT ***'))
    }

    static getSignedUrl(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.SIGNED_URL, { params })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN SIGNED URL ***'))
    }
}
