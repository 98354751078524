import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Utils, SSO } from 'helpers'
import { updateBookingsData, updateLoggedInUserDetails, updateActiveBooking, updateActiveBookingDetails, updateSessionData } from 'redux/actions'
import * as defaultState from 'redux/defaultState'

class SessionDataManager extends PureComponent {
    static displayName = 'SessionDataManager'

    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
        dispatchUpdateBookingsData: PropTypes.func.isRequired,
        dispatchUpdateActiveBooking: PropTypes.func.isRequired,
        dispatchUpdateLoggedInUserDetails: PropTypes.func.isRequired,
        dispatchUpdateActiveBookingDetails: PropTypes.func.isRequired,
        dispatchUpdateSessionData: PropTypes.func.isRequired,
    }

    componentDidMount() {
        Utils.setToMounted(this)
        this.checkData()
    }

    componentDidUpdate() {
        this.checkData()
    }

    componentWillUnmount() {
        Utils.setToUnmounted(this)
    }

    checkData = () => {
        const {
            dispatchUpdateBookingsData,
            dispatchUpdateLoggedInUserDetails,
            dispatchUpdateActiveBooking,
            dispatchUpdateActiveBookingDetails,
            dispatchUpdateSessionData,
        } = this.props
        const {
            activeBooking,
            activeBookingDetails,
            userData: { firstname, lastname, email, authToken },
            bookings,
        } = defaultState

        if (Utils.isMounted(this)) {
            const inSecureLogin = sessionStorage.getItem('insecurelogin')
            return SSO.getToken().then(token => {
                if (!token && !inSecureLogin) {
                    dispatchUpdateBookingsData(bookings)
                    dispatchUpdateLoggedInUserDetails({ firstname, lastname, email })
                    dispatchUpdateActiveBooking(activeBooking)
                    dispatchUpdateActiveBookingDetails(activeBookingDetails)
                    dispatchUpdateSessionData(authToken)
                    dispatchUpdateLoggedInUserDetails({ firstname, lastname, email })
                }
                return false
            })
        }
        return false
    }

    render() {
        const { children } = this.props
        return children
    }
}

export default connect(
    ({ userData: { authToken } }) => ({ authToken }),

    dispatch => ({
        dispatchUpdateBookingsData: payload => dispatch(updateBookingsData(payload)),
        dispatchUpdateLoggedInUserDetails: payload => dispatch(updateLoggedInUserDetails(payload)),
        dispatchUpdateActiveBooking: payload => dispatch(updateActiveBooking(payload)),
        dispatchUpdateActiveBookingDetails: payload => dispatch(updateActiveBookingDetails(payload)),
        dispatchUpdateSessionData: payload => dispatch(updateSessionData(payload)),
    }),
)(SessionDataManager)
