import produce from 'immer'
import * as actionType from 'redux/actionTypes'

const defaultState = {
    selectedPassenger: {
        firstName: '',
        dateOfBirth: {},
        lastName: '',
        hasProtection: false,
        isGoldSeal: false,
    },
    error: {},
    isValidationError: false,
    validationErrorMessage: '',
    timeline: {},
    updatedPassenger: {
        phoneNumberWhileAway: {},
        emailAddress: '',
        emergencyContact: {},
        passport: {},
        dateOfBirth: {},
        thirdPartyInsurance: {},
        hasThirdPartyInsurance: false,
    },
    versionModal: {
        stepIndex: 0,
        isDataConflictedForTab: false,
        keys: [],
    },
    passengerModal: {},
}

/**
 * Note we are using Immer.js for immutability
 */

export default function TripRegistration(state = defaultState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case actionType.SET_TRIP_REGISTRATION_PASSENGER:
                draft.selectedPassenger = action.payload
                break
            case actionType.SET_INPUT_ERROR_MESSAGE:
                draft.error[action.payload.fieldName] = action.payload.errorMessage
                draft.isValidationError = action.payload.isValidationError
                break
            case actionType.RESET_STATE:
                draft.error = defaultState.error
                draft.isValidationError = defaultState.isValidationError
                draft.validationErrorMessage = defaultState.validationErrorMessage
                draft.versionModal = defaultState.versionModal
                draft.updatedPassenger = defaultState.updatedPassenger
                break
            case actionType.SET_ERROR_MESSAGE:
                draft.validationErrorMessage = action.payload.error
                break
            case actionType.UPDATE_TIMELINE:
                draft.timeline = action.payload
                break
            case actionType.NEW_PASSENGER_DATA:
                draft.updatedPassenger = action.payload
                break
            case actionType.UPDATE_VERSION_MODAL:
                draft.versionModal.stepIndex = action.payload.stepIndex
                draft.versionModal.isDataConflictedForTab = action.payload.isDirty
                draft.versionModal.keys = action.payload.keys
                break
            case actionType.UPDATE_PASSENGER_MODAL:
                draft.passengerModal.stepIndex = action.payload.stepIndex
                break
            default:
                break
        }
    })
}
