import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class Uniworld {
    static getDocumentType(params) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_DOCUMENT_TYPE, { params })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GET DOCUMENT TYPE ***'))
    }

    static setDocumentType(params) {
        return Utils.ajax()
            .post(NodeAPIUrl.SET_DOCUMENT_TYPE, params)
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN SET DOCUMENT TYPE ***'))
    }
}
