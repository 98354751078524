import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Utils } from 'helpers'
import './styles.scss'

const {
    Loader: { message },
} = Utils.getTranslations()

const Loader = ({ loaderVisible }) => (
    <div className="reveal-overlay" style={{ display: loaderVisible ? 'block' : 'none' }}>
        <div className="reveal tiny text-center" style={{ display: 'block' }}>
            <div className="loader" />
            <div>{message}</div>
        </div>
    </div>
)

Loader.displayName = 'Loader'
Loader.propTypes = {
    loaderVisible: PropTypes.bool.isRequired,
}

export default connect(({ appData: { loaderVisible } }) => ({ loaderVisible }))(Loader)
