import produce from 'immer'
import * as actionType from 'redux/actionTypes'
import { activeBookingDetails } from 'redux/defaultState'

const defaultState = {
    ...activeBookingDetails,
    bookingVersion: '',
    oldBookingVersion: '',
}

/**
 * Note we are using Immer.js for immutability
 */

export default function BookingDetails(state = defaultState, action) {
    // eslint-disable-next-line consistent-return
    return produce(state, draft => {
        switch (action.type) {
            case actionType.UPDATE_ACTIVE_BOOKING_DETAILS:
                return {
                    ...draft,
                    ...action.payload,
                    isSpecialOrGroupBookingType: ['specialGroup', 'groupAllocation'].includes(action.payload.bookingType),
                    oldBookingVersion: action.payload.bookingVersion,
                }
            case actionType.UPDATE_BOOKING_VERSION:
                return {
                    ...draft,
                    oldBookingVersion: action.payload,
                }
            case actionType.UPDATE_INSTALMENT_PLAN:
                return {
                    ...draft,
                    instalmentPlan: action.payload,
                }

            default:
                break
        }
    })
}
