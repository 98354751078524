import produce from 'immer'
import * as actionType from 'redux/actionTypes'

const defaultState = {
    selectedPassenger: {
        beddingPreference: '',
        specialRequests: '',
        travelPassion: '',
    },
    hasFormSubmitError: false,
    errorMessage: '',
    updatedPassenger: {
        beddingPreference: '',
        specialRequests: '',
        travelPassion: '',
    },
    versionModal: {
        isDataConflict: false,
    },
}

/**
 * Note we are using Immer.js for immutability
 */

export default function TripPersonalisation(state = defaultState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case actionType.SET_TRIP_PERSONALISATION_PASSENGER:
                draft.selectedPassenger = action.payload
                break
            case actionType.UPDATE_SELECTED_PASSANGER_DATA: {
                const { beddingPreference, specialRequests, travelPassion, preferenceComplete } = action.payload
                draft.selectedPassenger.beddingPreference = beddingPreference
                draft.selectedPassenger.specialRequests = specialRequests
                draft.selectedPassenger.travelPassion = travelPassion
                draft.selectedPassenger.preferenceComplete = preferenceComplete
                break
            }
            case actionType.VALIDATE_PERSONALISATION_INPUT_FORM: {
                const { hasFormSubmitError, errorMessage } = action.payload
                draft.hasFormSubmitError = hasFormSubmitError
                draft.errorMessage = errorMessage
                break
            }
            case actionType.SET_NEW_TRIP_PERSONALISATION_PASSENGER_DATA:
                draft.updatedPassenger = action.payload
                break
            case actionType.RESET_TRIP_PERSONALISATION_DATA:
                draft.versionModal = defaultState.versionModal
                draft.hasFormSubmitError = defaultState.hasFormSubmitError
                draft.errorMessage = defaultState.errorMessage
                draft.updatedPassenger = defaultState.updatedPassenger
                break
            case actionType.SET_TRIP_PERSONALISATION_VERSION_MODAL:
                draft.versionModal.isDataConflict = action.payload
                break
            default:
                break
        }
    })
}
