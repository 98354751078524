import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Utils, Analytics } from 'helpers'
import './styles.scss'

const {
    HomeButton: { buttonLabel },
} = Utils.getTranslations()

const homeButtonClick = () => {
    const params = {
        action: 'home_button',
        category: 'home_button_click',
        label: 'Home Page',
    }
    Analytics.click(params)
}

const HomeButton = ({ className, customButtonLabel, customLink }) => (
    <Link to={customLink || '/dashboard'} className={`HomeButton ${className}`} onClick={homeButtonClick}>
        <div className="HomeButton__arrow" role="presentation" />
        {customButtonLabel || buttonLabel}
    </Link>
)

HomeButton.displayName = 'HomeButton'
HomeButton.propTypes = {
    className: PropTypes.string,
    customButtonLabel: PropTypes.string,
    customLink: PropTypes.string,
}
HomeButton.defaultProps = {
    className: '',
    customButtonLabel: '',
    customLink: '',
}

export default HomeButton
