import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class Prio {
    static getAccessToken(isGBPRegion) {
        return Utils.ajax()
            .post(NodeAPIUrl.GET_PRIO_ACCESS_TOKEN, null, {
                params: {
                    isGBPRegion,
                },
            })
            .then(Utils.getResponseData)
            .catch(error => Utils.throwError(error, '*** ERROR IN GETTING PRIO ACCESS TOKEN ***'))
    }

    static postToPrio(params, isGBPRegion) {
        const form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', isGBPRegion ? process.env.PRIO_POST_URL_GBP : process.env.PRIO_POST_URL_EUR)
        form.setAttribute('target', '_blank')
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute('name', 'data')
        hiddenField.setAttribute('value', JSON.stringify(params))
        form.appendChild(hiddenField)
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }
}
