import _ from 'lodash'

export default class Cart {
    static getTotalPrice(items = []) {
        let totalPrice = 0
        items.forEach(rec => {
            const { amount, paxIds, price } = rec.data
            if (rec.productType === 'hotel' || rec.productType === 'flights') {
                totalPrice += price
            } else {
                totalPrice += amount * paxIds.length
            }
        })
        return totalPrice.toFixed(2)
    }

    static getNumberOfItems(items = []) {
        let count = 0
        items.forEach(rec => {
            if (rec.productType !== 'hotel') {
                count += 1
            }
        })
        const preHotels = items.filter(rec => rec.subType === 'before')
        if (preHotels.length > 0) {
            count += Object.keys(_.groupBy(preHotels, 'id')).length
        }
        const postHotels = items.filter(rec => rec.subType === 'after')
        if (postHotels.length > 0) {
            count += Object.keys(_.groupBy(postHotels, 'id')).length
        }
        return count
    }
}
