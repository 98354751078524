import { combineReducers } from 'redux'
import { persistReducer, createTransform } from 'redux-persist'
import { setAutoFreeze } from 'immer'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import { parse, stringify } from 'flatted'
import storage from 'redux-persist/lib/storage'
import App from './App'
import Bookings from './Bookings'
import Booking from './Booking'
import BookingDetails from './BookingDetails'
import Documents from './Documents'
import Itinerary from './Itinerary'
import TripPersonalisation from './TripPersonalisation'
import BookingDetailsForOverview from './BookingDetailsForOverview'
import User from './User'
import Chatter from './Chatter'
import Edocs from './Edocs'
import TripRegistration from './TripRegistration'
import Cart from './Cart'

// need to turn off auto freeze for redux-persist
setAutoFreeze(false)

export const transformCircular = createTransform(
    inboundState => stringify(inboundState),
    outboundState => parse(outboundState),
)

const config = {
    key: 'mtp-redux-root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['appData', 'cart'],
    transforms: [transformCircular],
}

const rootReducer = persistReducer(
    config,
    combineReducers({
        bookings: Bookings,
        activeBooking: Booking,
        activeBookingDetails: BookingDetails,
        activeDocuments: Documents,
        activeItinerary: Itinerary,
        appData: App,
        userData: User,
        tripPersonalisation: TripPersonalisation,
        bookingDetailsForOverview: BookingDetailsForOverview,
        chatter: Chatter,
        edocs: Edocs,
        tripRegistration: TripRegistration,
        cart: Cart,
    }),
)

export default rootReducer
