import Parser from 'awesome-phonenumber'
import moment from 'moment'
import Utils from './Utils'
import Time from './Time'

const {
    ValidationMessages: {
        required,
        validName,
        nameFormat,
        maxLength,
        minLength,
        numberOnly,
        alphaNumericOnly,
        validPassportNumber,
        emailFormat,
        phoneFormat,
        dateFormat,
        bookingnumberFormatForTropicsBrands,
        bookingnumberFormatForUniworldBrands,
    },
} = Utils.getTranslations()

export default class Validation {
    static getMessage(custom = '', base = 'Invalid Value', key = '', params = {}) {
        let string = custom || base
        string = string[key] || string

        return Utils.replaceParamsInString(string, params)
    }

    static getRule(ruleType, params = {}) {
        let rule
        let errorMessage
        const data = {}

        switch (ruleType.toLowerCase()) {
            case 'required': {
                const { message = '' } = params
                rule = {
                    test: value => {
                        const regEx = /[^\s\r\n\t]{1,}/

                        if (value) {
                            if (typeof value.internationalCallingCode !== 'undefined' && typeof value.number !== 'undefined') {
                                const { internationalCallingCode = '', number = '' } = value
                                return regEx.test(internationalCallingCode) && regEx.test(number)
                            }
                            if (typeof value.date !== 'undefined' && typeof value.month !== 'undefined' && typeof value.year !== 'undefined') {
                                const { date = '', month = '', year = '' } = value
                                return regEx.test(date) && regEx.test(month) && regEx.test(year)
                            }
                        }

                        return regEx.test(value)
                    },
                }
                errorMessage = this.getMessage(message, required)
                break
            }

            case 'maxlength': {
                const { length = '', type = 'numbers', message = '' } = params
                rule = {
                    test: value => {
                        const regEx = new RegExp(`^(.|\\s|\\r|\\n|\\t){0,${length}}$`)

                        if (value) {
                            if (typeof value.internationalCallingCode !== 'undefined' && typeof value.number !== 'undefined') {
                                const { number = '' } = value
                                return regEx.test(number)
                            }
                        }

                        return regEx.test(value)
                    },
                }
                errorMessage = this.getMessage(message, maxLength, type, { length })
                data.maxLength = length
                break
            }

            case 'maxlengthexludespecial': {
                const { length = '', type = 'numbers', message = '' } = params
                rule = {
                    test: value => {
                        const regEx = new RegExp(`^(.|\\s|\\r|\\n|\\t){0,${length}}$`)

                        if (value) {
                            if (value.internationalCallingCode && value.number) {
                                const { number = '' } = value
                                const regExToReplaceSpecialChar = new RegExp(/[ #()-]/gi)
                                const updatetdNumber = number.replace(regExToReplaceSpecialChar, '')
                                return regEx.test(updatetdNumber)
                            }
                        }

                        return regEx.test(value)
                    },
                }
                errorMessage = this.getMessage(message, maxLength, type, { length })
                data.maxLength = length
                break
            }

            case 'minlength': {
                const { length = '', type = 'numbers', message = '' } = params
                rule = {
                    test: value => {
                        const regEx = new RegExp(`^(.|\\s|\\r|\\n|\\t){${length},}$`)

                        if (value) {
                            if (typeof value.internationalCallingCode !== 'undefined' && typeof value.number !== 'undefined') {
                                const { number = '' } = value
                                return regEx.test(number)
                            }
                        }

                        return regEx.test(value)
                    },
                }
                errorMessage = this.getMessage(message, minLength, type, { length })
                data.minLength = length
                break
            }

            case 'numberonly': {
                const { message = '' } = params
                rule = /^[\d]+$/
                errorMessage = this.getMessage(message, numberOnly)
                break
            }

            case 'alphanumericonly': {
                const { message = '' } = params
                rule = /^[\w\d]+$/
                errorMessage = this.getMessage(message, alphaNumericOnly)
                break
            }

            case 'validname': {
                const { message = '' } = params
                rule = /^[-'a-zA-Z ]*$/
                errorMessage = this.getMessage(message, validName)
                break
            }

            case 'alphanumericwithspace': {
                const { message = '' } = params
                rule = /^[\w\d\s]+$/
                errorMessage = this.getMessage(message, validPassportNumber)
                break
            }

            case 'emailformat': {
                const { message = '' } = params
                rule =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                errorMessage = this.getMessage(message, emailFormat)
                break
            }

            case 'notnull': {
                const { message = '' } = params
                rule = {
                    test: value => typeof value !== 'undefined' && value !== null,
                }
                errorMessage = this.getMessage(message, required)
                break
            }

            case 'notfalse': {
                const { message = '' } = params
                rule = {
                    test: value => typeof value === 'boolean' && value !== false,
                }
                errorMessage = this.getMessage(message, required)
                break
            }

            case 'phoneformat': {
                const { message = '' } = params
                rule = {
                    test: (value = {}) => {
                        const { internationalCallingCode = '', number = '' } = value
                        const regEx = /^[\d]+$/
                        return regEx.test(number) && new Parser(`${internationalCallingCode}${number}`).isValid()
                    },
                }
                errorMessage = this.getMessage(message, phoneFormat)
                break
            }

            case 'phoneformatspecial': {
                const { message = '' } = params
                rule = {
                    test: (value = {}) => {
                        const { internationalCallingCode = '', number = '' } = value
                        const regEx = /^[\d]+$/
                        const regExToReplaceSpecialChar = new RegExp(/[ #()-]/gi)
                        const updatedNumber = number.replace(regExToReplaceSpecialChar, '')
                        return regEx.test(updatedNumber) && new Parser(`${internationalCallingCode}${updatedNumber}`).isValid()
                    },
                }
                errorMessage = this.getMessage(message, phoneFormat)
                break
            }

            case 'dateformat': {
                const { message = '' } = params
                rule = {
                    test: (value = {}) => {
                        const { date = '', month = '', year = '' } = value
                        const dateValue = year && month && date ? Time.toUTC(`${year}-${month}-${date}`) : Time.toUTC()
                        return moment(dateValue).isValid()
                    },
                }
                errorMessage = this.getMessage(message, dateFormat)
                break
            }

            case 'datenotmorethan': {
                const { message = '', value: param = '' } = params
                rule = {
                    test: (value = {}) => {
                        const { date = '', month = '', year = '' } = value
                        const dateValue = year && month && date ? Time.toUTC(`${year}-${month}-${date}`) : Time.toUTC()
                        return moment(dateValue).isValid() && dateValue.isSameOrBefore(Time.toUTC(param.format('YYYY-MM-DD')))
                    },
                }
                errorMessage = this.getMessage(message, dateFormat)
                break
            }

            case 'datenotlessthan': {
                const { message = '', value: param = '' } = params
                rule = {
                    test: (value = {}) => {
                        const { date = '', month = '', year = '' } = value
                        const dateValue = year && month && date ? Time.toUTC(`${year}-${month}-${date}`) : Time.toUTC()
                        return moment(dateValue).isValid() && dateValue.isSameOrAfter(param.format('YYYY-MM-DD'))
                    },
                }
                errorMessage = this.getMessage(message, dateFormat)
                break
            }

            case 'nameformat': {
                const { message = '' } = params
                rule = /^[a-zA-Z ]*$/
                errorMessage = this.getMessage(message, nameFormat)
                break
            }

            case 'bookingnumberformatfortropicsbrands': {
                const { message = '' } = params
                rule = /^[a-zA-Z][0-9]{6}$/i
                errorMessage = this.getMessage(message, bookingnumberFormatForTropicsBrands)
                break
            }

            case 'bookingnumberformatforuniworldbrands': {
                const { message = '' } = params
                rule = /^[0-9]{7}$/i
                errorMessage = this.getMessage(message, bookingnumberFormatForUniworldBrands)
                break
            }

            default: {
                rule = /[^\s\S]/
                errorMessage = this.getMessage()
            }
        }

        return {
            rule,
            message: errorMessage,
            ...data,
        }
    }

    static validate(value = '', rules, isValidationRequired) {
        if (!isValidationRequired) {
            const rulesParam = Array.isArray(rules) ? rules : [rules]
            const numberOfRulesParam = rulesParam.length
            let result = true
            let errorMessage

            for (let i = 0; i < numberOfRulesParam; i += 1) {
                if (result) {
                    const ruleParam = rulesParam[i] || {}
                    const { rule, message } = ruleParam

                    if (rule && typeof rule.test === 'function') {
                        result = rule.test(value)
                        errorMessage = message || 'Invalid Value'
                    }
                }
            }

            return {
                message: !result ? errorMessage : '',
            }
        }
        return {
            message: '',
        }
    }
}
