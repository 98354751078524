import React from 'react'
import ReactGA from 'react-ga4'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { hotjar } from 'react-hotjar'
import App from 'containers/App'
import { Utils } from 'helpers'

import * as serviceWorker from './serviceWorker'

const {
    App: { gtaId, execHotJar },
} = Utils.getConfigs()

// Initializing GTM for analytics
if (process.env.EXECGOOGLEANALYTICS) {
    ReactGA.initialize(gtaId, { testMode: process.env.NODE_ENV === 'test' })
}

Modal.setAppElement('#root')

ReactDOM.render(<App />, document.getElementById('root') || document.createElement('div'))
// Initialize hotjar tracking
if (process.env.EXECHOTJAR && execHotJar) {
    hotjar.initialize(process.env.HZID, process.env.HZSV)
}

serviceWorker.unregister()

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === 'production') {
//     serviceWorker.register()
// } else {
//     serviceWorker.unregister()
// }
