import { createChatClient, unReadCount, setActiveBookingTIList, setChannelsList } from 'redux/actions'
import { Utils } from 'helpers'
import { AppConstant } from 'constants'

import { StreamChat } from 'stream-chat'

const {
    App: { brandCode },
} = Utils.getConfigs()

const chatClient = StreamChat.getInstance(AppConstant.CHAT_CLIENT_API_KEY)

const setChatUser = () => async (dispatch, getState) => {
    const {
        userData: { avatar, ssoId, userToken, firstname, preferredName },
        activeBooking: { tourInstanceDetails },
    } = getState()
    const { user } = chatClient
    if (!user) {
        const chatUserName = preferredName || firstname
        const newUser = {
            id: `${ssoId}-${brandCode.toLowerCase()}`,
            name: chatUserName,
            image: avatar,
        }

        await chatClient.connectUser(newUser, userToken)
    }

    const tourInstanceIdList = []

    await Promise.all(
        tourInstanceDetails.map(async tourInstance => {
            const {
                tourInstance: { instanceCode },
            } = tourInstance
            const instanceId = instanceCode.replace('/', '-')
            tourInstanceIdList.push(instanceId)
        }),
    )
    dispatch(setActiveBookingTIList(tourInstanceIdList))
    dispatch(createChatClient(chatClient))
    return chatClient
}

const updateUnreadMessageCount = () => async (dispatch, getState) => {
    const {
        chatter: { activeBookingTIList },
    } = getState()
    const filter = { type: 'messaging', id: { $in: activeBookingTIList } }
    const channelList = await chatClient.queryChannels(filter)
    let count = 0
    channelList.forEach(channel => {
        count += channel.countUnread()
    })
    dispatch(unReadCount(count))
}

const createChannel = () => async (dispatch, getState) => {
    const {
        activeBooking: {
            tourInstanceDetails,
            product: { backgroundUrl },
        },
        userData: { ssoId },
    } = getState()
    const channelsList = []
    let unreadCount = 0
    await Promise.all(
        tourInstanceDetails.map(async tourInstance => {
            const {
                tourInstance: { instanceCode, productName, startDate, endDate },
            } = tourInstance
            const channelOptions = { watch: true, state: true }
            const instanceId = instanceCode.replace('/', '-')
            const filter = { type: 'messaging', id: { $in: [instanceId] } }
            const alreadyCreatedChannel = await chatClient.queryChannels(filter, {}, channelOptions)
            const { 0: oldChannel } = alreadyCreatedChannel
            let channel = oldChannel
            if (!channel) {
                channel = await chatClient.channel('messaging', instanceId, {
                    image: `${backgroundUrl}`,
                    name: `${productName}`,
                    startDate: `${startDate}`,
                    endDate: `${endDate}`,
                })
            }
            const watchers = await channel.watch()
            const { members } = watchers
            const memberFound = await members.find(member => member.user.id === `${ssoId}-${brandCode.toLowerCase()}`)
            if (!memberFound) {
                await channel.addMembers([`${ssoId}-${brandCode.toLowerCase()}`])
            }
            unreadCount += channel.countUnread()
            channelsList.push(channel)
        }),
    )
    dispatch(unReadCount(unreadCount))
    dispatch(setChannelsList(channelsList))
}

const updateChatUser = () => async (dispatch, getState) => {
    const {
        userData: { ssoId, avatar, firstname, preferredName },
    } = getState()

    let chatUserName = ''
    if (preferredName && preferredName !== '') {
        chatUserName = preferredName
    } else {
        chatUserName = firstname
    }

    const update = {
        id: `${ssoId}-${brandCode.toLowerCase()}`,
        set: {
            name: chatUserName,
            image: avatar,
        },
    }
    await chatClient.partialUpdateUser(update)
}

export { setChatUser, createChannel, updateChatUser, updateUnreadMessageCount }
