import { UPDATE_DOCUMENT_TYPE, UPDATE_AVAILABLE_DOCUMENT_TYPES } from 'redux/actionTypes'

export const updateAvailableDocumentTypes = documentTypes => ({
    type: UPDATE_AVAILABLE_DOCUMENT_TYPES,
    payload: documentTypes,
})

export const updateDocumentType = documentType => ({
    type: UPDATE_DOCUMENT_TYPE,
    payload: documentType,
})
