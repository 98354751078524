import produce from 'immer'
import * as actionType from 'redux/actionTypes'

const defaultState = {
    itinerary: [],
    optionals: [],
    hotels: [],
}

/**
 * Note we are using Immer.js for immutability
 */

export default function Itinerary(state = defaultState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case actionType.UPDATE_ACTIVE_ITINERARY:
                draft.itinerary = action.payload
                break
            case actionType.UPDATE_ACTIVE_ITINERARY_DETAILS: {
                const { optionals, hotels } = action.payload
                draft.optionals = optionals
                draft.hotels = hotels
                break
            }
            default:
                break
        }
    })
}
