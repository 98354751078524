/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

import merge from 'deepmerge'
import base from './base'
import contiki from './brands/contiki'
import trafalgar from './brands/trafalgar'
import insightvacations from './brands/insightvacations'
import costsaver from './brands/costsaver'
import luxurygold from './brands/luxurygold'
import aatkings from './brands/aatkings'
import getours from './brands/getours'
import brendanvacations from './brands/brendanvacations'
import ubyuniworld from './brands/ubyuniworld'
import uniworld from './brands/uniworld'

const deepmergeOptions = { arrayMerge: (destinationArray, sourceArray) => sourceArray }

const brandCodes = {
    aatkings: 'AA',
    brendanvacations: 'BV',
    contiki: 'CH',
    costsaver: 'CS',
    getours: 'GE',
    insightvacations: 'IV',
    luxurygold: 'LG',
    trafalgar: 'TT',
    ubyuniworld: 'UN',
    uniworld: 'UU',
}

async function getTranslations({ brand = process.env.BRAND, regionCode } = {}) {
    const brandTranslations = await import(`./brands/${brand}`)
    const sellingCompanyCode = `${brandCodes[brand]}${regionCode}`

    let sellingCompanyTranslations = { default: {} }
    try {
        // eslint-disable-next-line no-return-assign
        sellingCompanyTranslations = await import(`./sellingCompany/${sellingCompanyCode}`)
        // eslint-disable-next-line no-empty
    } catch {}

    if (regionCode) {
        let regionTranslations = { default: {} }
        try {
            // eslint-disable-next-line no-return-assign
            regionTranslations = await import(`./regions/${regionCode}`)
            // eslint-disable-next-line no-empty
        } catch {}

        return merge.all([base, brandTranslations.default, regionTranslations.default, sellingCompanyTranslations.default], deepmergeOptions)
    }

    return merge.all([base, brandTranslations.default], deepmergeOptions)
}

export default {
    getTranslations,
    base,
    aatkings,
    brendanvacations,
    contiki,
    costsaver,
    getours,
    insightvacations,
    luxurygold,
    trafalgar,
    ubyuniworld,
    uniworld,
}
