import {
    TOGGLE_LOADER,
    TOGGLE_ERROR_MODAL,
    SET_HOTELS,
    SET_INSURANCE_DATA,
    SET_FLIGHT_AMOUNT,
    SHOW_INSURANCE_DIFFERENCE,
    SHOW_INSURANCE_MODAL,
    SET_BOOKING_CLOSE,
    TOGGLE_WARNING_MODAL,
    SHOW_BOOKING_LIST,
    SET_MODAL_MESSAGE,
    SET_TRANSFER_AIR,
} from 'redux/actionTypes'

export const toggleLoader = payload => ({ type: TOGGLE_LOADER, payload })
export const toggleErrorModal = payload => ({ type: TOGGLE_ERROR_MODAL, payload })
export const toggleWarningModal = payload => ({ type: TOGGLE_WARNING_MODAL, payload })
export const showBookingList = payload => ({ type: SHOW_BOOKING_LIST, payload })
export const setBookingClose = payload => ({ type: SET_BOOKING_CLOSE, payload })
export const setModalMessage = payload => ({ type: SET_MODAL_MESSAGE, payload })
export const setHotelsData = payload => ({ type: SET_HOTELS, payload })
export const setShowInsuranceDifference = payload => ({ type: SHOW_INSURANCE_DIFFERENCE, payload })
export const setFlightAmount = payload => ({ type: SET_FLIGHT_AMOUNT, payload })
export const setShowInsuranceModal = payload => ({ type: SHOW_INSURANCE_MODAL, payload })
export const setInsuranceData = payload => ({ type: SET_INSURANCE_DATA, payload })
export const setTransferData = payload => ({ type: SET_TRANSFER_AIR, payload })
