import { Utils } from 'helpers'
import { NodeAPIUrl } from 'constants'

export default class Payments {
    static getInvoiceForBooking(bookingReference) {
        return Utils.ajax()
            .get(NodeAPIUrl.GET_INVOICE, { params: { booking: bookingReference } })
            .then(response => Utils.getResponseData(response, true))
            .catch(error => {
                Utils.consoleErr(error, '*** ERROR IN GET INVOICE API ***')
                return error
            })
    }
}
