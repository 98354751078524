import Utils from './Utils'

const {
    MyBrand: { name },
} = Utils.getTranslations()

export default class SSO {
    static initSso() {
        if (typeof SsoHelper === 'undefined') {
            const initiator = { initiator: name }
            if (typeof initializeSso !== 'undefined' && window.sso && !window.sso.initialized) {
                return new Promise(resolve => initializeSso(initiator).then(resolve(true)))
            }
            return new Promise(resolve => resolve(false))
        }
        return Promise.resolve(false)
    }

    static getToken() {
        return new Promise(resolve => {
            this.initSso()
                .then(() => {
                    const ssoHelper = new SsoHelper()
                    return ssoHelper.getToken((tokenKey, tokenValue) => {
                        resolve(tokenValue)
                    })
                })
                .catch(err => Utils.consoleErr(err))
        })
    }

    static isUserAlreadyLoggedIn() {
        const ssoHelper = new SsoHelper()
        return new Promise(resolve => ssoHelper.isLoggedIn(loggedInInfo => resolve(loggedInInfo)))
    }

    static getSSOData() {
        const ssoHelper = new SsoHelper()
        return new Promise(
            resolve =>
                ssoHelper.getToken((tokenKey, tokenValue) => {
                    if (tokenValue) {
                        return ssoHelper.decryptToken(
                            tokenValue,
                            ({ UserInfo }) => {
                                if (UserInfo && UserInfo.Email) {
                                    return resolve(UserInfo)
                                }
                                return Utils.consoleErr('Invalid token. User not identified.')
                            },
                            error => {
                                Utils.consoleErr(error)
                            },
                        )
                    }
                    return resolve(false)
                }),
            // eslint-disable-next-line function-paren-newline
        )
    }
}
