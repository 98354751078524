import produce from 'immer'
import { CREATE_CHAT_CLIENT, UNREAD_COUNT, SET_ACTIVE_BOOKING_TI_LIST, SET_CHANNELS } from 'redux/actionTypes'

const defaultState = {
    chatClient: {},
    activeBookingTIList: [],
    unReadCount: 0,
    channels: [],
}

/**
 * Note we are using Immer.js for immutability
 */

export default function Chatter(state = defaultState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case CREATE_CHAT_CLIENT: {
                draft.chatClient = action.payload
                break
            }
            case SET_ACTIVE_BOOKING_TI_LIST: {
                draft.activeBookingTIList = action.payload
                break
            }
            case UNREAD_COUNT: {
                draft.unReadCount = action.payload
                break
            }
            case SET_CHANNELS: {
                draft.channels = action.payload
                break
            }
            default:
                break
        }
    })
}
