import { UPDATE_BOOKINGS_DATA } from 'redux/actionTypes'
import { bookings } from 'redux/defaultState'

const Bookings = (state = bookings, { type, payload }) => {
    switch (type) {
        case UPDATE_BOOKINGS_DATA: {
            return [...payload]
        }
        default: {
            return state
        }
    }
}

export default Bookings
