import React from 'react'
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from 'redux/store'
import Sticky from 'react-sticky-el'
import ErrorModal from 'components/ErrorModal'
import WarningModal from 'components/WarningModal'
import Loader from 'components/Loader'
import Header from 'components/Header'
import AlertMessageBlock from 'components/AlertMessageBlock'
import Footer from 'components/Footer'
import { Utils } from 'helpers'
import GenesysWidget from 'widgets/genesys/index'
import moment from 'moment'
import SessionDataManager from './SessionDataManager'
import AxiosInterceptors from './AxiosInterceptors'
import RouteLoader from './RouteLoader'
import 'assets/styles.scss'

const {
    App: { routes, brandId, showKnowBeforeYouGoPage, downTimeFrom, downTimeTo },
} = Utils.getConfigs()

// cache store for reuse
Utils.store = store

const isMaintenanceTime = new Date() >= moment.parseZone(downTimeFrom) && new Date() <= moment.parseZone(downTimeTo)

const style = { zIndex: 5000 }
const shouldUpdateScroll = (previousRouterProps, currentRouterProps) => {
    const { location: { pathname: previousPath = '' } = {} } = previousRouterProps || {}
    const { location: { pathname: currentPath = '' } = {} } = currentRouterProps || {}
    return previousPath !== currentPath
}

const myHeader = () => (
    <Sticky stickyStyle={style} className="Header__sticky">
        <Header path={window.location.pathname} />
        {isMaintenanceTime && (
            <AlertMessageBlock
                message="Please be aware that we will be performing routine server maintenance on 20 August 2023 (07:00am to 11:00am Eastern Daylight Time). As a result the portal will be unavailable during this time."
                className="DownTimeMessage"
            />
        )}
    </Sticky>
)

const App = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <GenesysWidget>
                <SessionDataManager>
                    <AxiosInterceptors>
                        <Router getUserConfirmation={(message, callback) => Utils.getUserConfirmation({ message, callback })}>
                                <div className={`grid-container full ${brandId}`}>
                                    <div className="grid-y App">
                                        <Loader />

                                        <Switch>
                                            {routes
                                                .map(route => {
                                                    if (route.name === 'KnowBeforeYouGo') {
                                                        route.enabled = showKnowBeforeYouGoPage
                                                    }
                                                    return route
                                                })
                                                .filter(({ enabled }) => enabled)
                                                .map(({ name, path, secured = true }) => (
                                                    <Route
                                                        key={name}
                                                        exact
                                                        path={path}
                                                        render={() => (
                                                            <>
                                                                {myHeader()}
                                                                <RouteLoader secured={secured} name={name} />
                                                            </>
                                                        )}
                                                    />
                                                ))}
                                            <Route
                                                render={() => (
                                                    <>
                                                        {myHeader()}
                                                        <RouteLoader secured name="NotFound" />
                                                    </>
                                                )}
                                            />
                                        </Switch>
                                        <Footer />
                                        <ErrorModal />
                                        <WarningModal />
                                    </div>
                                </div>
                        
                        </Router>
                    </AxiosInterceptors>
                </SessionDataManager>
            </GenesysWidget>
        </PersistGate>
    </Provider>
)

App.displayName = 'App'

export default App
