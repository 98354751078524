import React from 'react'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import { withCard } from './CardContext'

const Banner = ({ componentName, className, themeName, children }) => {
    const classNames = Utils.removeDuplicates(...Utils.stringToArray(className))
    const parentClassName = Utils.argsToString(...Utils.addSuffixToEachString(componentName, '__banner'), themeName, ...classNames)

    return (
        <div className="cell shrink">
            <div className={parentClassName}>{children}</div>
        </div>
    )
}

Banner.displayName = 'CardBanner'
Banner.propTypes = {
    componentName: PropTypes.arrayOf(PropTypes.string).isRequired,
    className: PropTypes.string,
    themeName: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}
Banner.defaultProps = {
    className: '',
    children: null,
}

export default withCard(Banner)
