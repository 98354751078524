import React from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Utils } from 'helpers'
import { connect } from 'react-redux'
import loadable from '@loadable/component'
import ReactGA from 'react-ga4'

const RouteLoader = outerProps => {
    const { name, secured, authToken } = outerProps
    const inSecureLogin = sessionStorage.getItem('insecurelogin')
    const Component = loadable(() => import(`pages/${name}/index.js`))
    Component.load()
        .then(() => {
            if (process.env.EXECGOOGLEANALYTICS) {
                ReactGA.ga('send', {
                    hitType: 'pageview',
                    page: window.location.pathname,
                    title: `${name}`,
                })
            }
            return true
        })
        .catch(error => Utils.consoleErr('*** ERROR IN COMPONENT LOADING ***', error))
    return !authToken && secured && !inSecureLogin ? <Redirect to="/login" /> : <Component {...outerProps} />
}

RouteLoader.displayName = 'RouteLoader'
RouteLoader.propTypes = {
    authToken: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    secured: PropTypes.bool,
}
RouteLoader.defaultProps = {
    secured: true,
}

export default withRouter(connect(({ userData: { authToken } }) => ({ authToken }))(RouteLoader))
