/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { Utils } from 'helpers'
import { AppConstant } from 'constants'
import { store } from 'redux/store'

const {
    App: { brandCode, brandName },
    genesysWidget: { termsAndConditionUrl },
} = Utils.getConfigs()

const widgetConfig = {
    main: {
        debug: false,
        theme: 'light',
        i18n: '',
        customStylesheetID: 'MTPGenesys',
        lang: 'en',
        mobileMode: 'auto',
        mobileModeBreakpoint: 600,
        preload: ['sidebar'],
    },
    webchat: {
        dataURL: AppConstant.GENESYS_DATA_URL, // https://gms.travcorp.com/genesys/2/chat/CE18_Digital_Chat/
        userData: {
            chatsource: 'MTP', // This is required custom field(Value:  MTP, Booking Engine, White Label)
            cus_type: 'Customer', // This is required custom field(Values : Consumer or Travel Agent)
            selling_co: '', // This is required custom field(Values : TTUSAS, CHSYDS, etc)
            cc_loc: '', // This is required custom field(Values : USA, UK etc)
            ServiceType: brandCode, // This is required custom field(Values :  TT, CH, AA, UW etc)
            chatscreening: `MTP Consumer ${brandName}`, // This is required custom field(Values : TTUSAS Customer, TTUSAS Travel Agent)
        },
        emojis: true,
        uploadsEnabled: false,
        confirmFormCloseEnabled: true,
        actionsMenu: true,
        maxMessageLength: 500,
        charCountEnabled: true,
        formValidation: true,
        autoInvite: {
            enabled: false,
            timeToInviteSeconds: 10,
            inviteTimeoutSeconds: 30,
        },
        async: {
            enabled: true,
            newMessageRestoreState: 'minimized',
            getSessionData(sessionData, Cookie, CookieOptions) {
                // Note: You don't have to use Cookies. You can, instead, store in a secured location like a database.
                // Cookie.set('customer-defined-session-cookie', JSON.stringify(sessionData), CookieOptions)
                return true
            },
            setSessionData(Open, Cookie, CookieOptions) {
                // Retrieve from your secured location.
                // return Cookie.get('customer-defined-session-cookie')
                return true
            },
        },
        form: {
            subject: 'Customer Satisfaction',
            wrapper: '<table></table>',
            inputs: [
                {
                    id: 'cx_webchat_form_firstname',
                    name: 'firstname',
                    maxlength: '100',
                    placeholder: '@i18n:webchat.ChatFormPlaceholderFirstName',
                    label: '@i18n:webchat.ChatFormFirstName',
                    validate(event, form, input, label, $, CXBus, Common) {
                        if (input && input.val()) {
                            // to validate some input exits in the firstname input field (required field)
                            return true // validation passed
                        }
                        return false // no input exists, validation failed
                    },
                },

                {
                    id: 'cx_webchat_form_lastname',
                    name: 'lastname',
                    maxlength: '100',
                    placeholder: '@i18n:webchat.ChatFormPlaceholderLastName',
                    label: '@i18n:webchat.ChatFormLastName',
                    validate(event, form, input, label, $, CXBus, Common) {
                        if (input && input.val()) {
                            return true // validation passed
                        }
                        return false // no input exists, validation failed
                    },
                },
                {
                    id: 'cx_webchat_form_email',
                    name: 'email',
                    maxlength: '100',
                    placeholder: 'Required',
                    label: '@i18n:webchat.ChatFormEmail',
                    validate(event, form, input, label, $, CXBus, Common) {
                        if (input && input.val()) {
                            const rule =
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            return rule.test(input.val())
                        }
                        return false
                    },
                },
                {
                    id: 'cx_webchat_form_subject',
                    name: 'subject',
                    maxlength: '100',
                    placeholder: '@i18n:webchat.ChatFormPlaceholderSubject',
                    label: '@i18n:webchat.ChatFormSubject',
                },
                {
                    id: 'cx_webchat_form_disclaimer',
                    name: 'disclaimer',
                    type: 'checkbox',
                    wrapper: '<tr><th>{input}</th><td>{label}</td></tr>',
                    label: `By clicking "Start Chat" you're agreeing to our <a href="${termsAndConditionUrl}" target="_blank" rel="noopener noreferrer">Terms of Use</a>`,
                },
            ],
        },
    },
    minimizeOnMobileRestore: false,
    markdown: false,
    ariaIdleAlertIntervals: [50, 25, 10],
    ariaCharRemainingIntervals: [75, 25, 10],
    sidebar: {
        showOnStartup: true,
        position: 'right',
        expandOnHover: true,
        channels: [
            {
                name: 'WebChat',
                displayName: 'Talk to the team',
                displayTitle: 'Get live help',
                icon: 'agent',
                onClick: async ($, CXBus, Common) => {
                    const isLoggedIn = Utils.getCookie('Token')
                    if (isLoggedIn) {
                        const {
                            userData: { firstname, lastname, email },
                        } = store.getState()
                        CXBus.command('WebChat.open', {
                            form: {
                                autoSubmit: true,
                                firstname,
                                lastname,
                                email,
                            },
                            markdown: false,
                        })
                            .done(e => {
                                // WebChat opened successfully
                            })
                            .fail(e => {
                                // WebChat isn't open or no active chat session
                            })
                    } else {
                        CXBus.command('WebChat.open')
                    }
                },
            },
        ],
    },
}

export default widgetConfig
